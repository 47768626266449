<template>
  <ion-row id="section-credit-score-id-form-header">
    <ion-col>
      <ion-text class="text-sm ion-text-brand block mb-3">
        Please provide the details of at least one of your identity documents below so we can verify your identity.
      </ion-text>
    </ion-col>
  </ion-row>
</template>

<script setup>
</script>