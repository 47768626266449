<template>
  <ion-card class="mx-1 my-4 p-0 rounded-xl bg-grey-3 border" :id="props.section" v-if="props.condition">
    <ion-card-header class="flex justify-between align-center mb-1">
      <ion-card-subtitle class="capitalize text-[14px] text-black">{{ props.title }}</ion-card-subtitle>
      <ion-icon src="/assets/credit-score/close.svg" class="p-1 float-right cursor-pointer" @click="toggle"></ion-icon>
    </ion-card-header>
    <ion-card-content class="p-4">
      <slot></slot>
    </ion-card-content>
  </ion-card>
</template>
<script setup>
const props = defineProps(['title', 'section', 'condition']);
const emit = defineEmits(['toggleSection']);
const toggle = () => {
  emit('toggleSection');
};
</script>