<template>
  <ion-card class="m-0 p-0 mb-6 rounded-xl drop-shadow-none fadein-600" :class="`bg-notice-${props.type}`">
    <ion-card-content class="p-1 px-3">
      <ion-grid>
        <ion-row>
          <ion-col size="1">
            <ion-icon :src="`/assets/general/notice-${props.type}.svg`" class="w-[18px] h-[18px] pt-1"></ion-icon>
          </ion-col>
          <ion-col>
            <ion-icon src="/assets/general/arrow-danger.svg" class="pt-1 -mr-1 float-right w-[25px] h-[25px]"
              @click="nextStep"></ion-icon>
            <ion-text class="font-bold text-sm m-2 mt-1 pr-4 block text-left" :class="`text-notice-${props.type}`">
              Please Pay overdue amount to access free credit score check.
            </ion-text>
            <ion-text class="text-xs m-2 mb-1 block text-left" :class="`text-notice-${props.type}`">
              To view your credit score, your must not be in arrears. 
              <span class="underline">Pay your existing amount to access again.</span>
            </ion-text>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-card-content>
  </ion-card>
</template>
<script setup>
const props = defineProps(['type']);
import router from '@/router';
const nextStep = () => {
  router.push('/tabs/loans');
}
</script>