<template>
  <ion-card class="bg-white m-0 p-0 rounded-xl">
    <ion-card-content class="px-4 pt-3 pb-2">
      <ion-grid>
        <ion-row>
          <ion-col class="py-0">
            <ion-icon src="/assets/general/close-grey.svg"
              class="w-[24px] h-[24px] absolute block right-0 m-1 cursor-pointer opacity-80 hover:opacity-100"
              @click="closeModal"></ion-icon>
            <ion-text class="ion-text-purple block font-bold mb-3">
              Still haven't received the<br /> verification code?
            </ion-text>
            <ion-text class="ion-text-purple block text-sm">
              If you've been waiting longer than<br />
              <span class="ion-text-primary">60 seconds,</span> try these things:
            </ion-text>
            <ul class="text-sm list-disc p-3">
              <li>Check the correct {{ props.configLabel }} has been entered</li>
              <li v-if="props.configLabel == 'phone number'">Check that you have mobile reception to receive the SMS.
              </li>
              <li v-if="props.configLabel == 'email'">Check your junk or spam folder, make sure your inbox is not full
                and that your device is connected to the internet</li>
            </ul>
            <button expand="block" color="secondary" class="button-purple button-shadow mt-3 mb-3"
              @click="sendCode(props.configUrl)" :disabled="loading">
              <span class="block text-center" v-if="loading">
                <ion-spinner name="crescent" class="absolute -ml-3 -mt-3"></ion-spinner>
              </span>
              <span class="block capitalize text-white text-[14px] font-semibold height-[60px]" v-else>
                Send Code Again
              </span>
            </button>
            <ion-note slot="helper" class="w-full text-xs px-1 mt-6 ion-text-primary block text-center"
              v-if="resendError">{{ resendError }}</ion-note>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            <ion-text class="ion-text-purple block font-bold mb-3">Support</ion-text>
            <ion-text class="ion-text-purple block text-sm">If you are still not receiving the code, please
              contact us so we can assist you.</ion-text>
            <button expand="block" color="secondary" class="button-purple button-icon button-icon-phone mt-6">
              <a :href="'tel:' + props.configPhone">
                <span class="block capitalize text-white text-[14px] font-semibold height-[60px] text-left pl-14">
                  Call Customer Service
                  <small class="block">{{ props.configPhone }}</small>
                </span>
              </a>
            </button>
            <button expand="block" color="secondary" class="button-purple button-icon button-icon-email mt-3">
              <a :href="'mailto:' + props.configEmail">
                <span class="block capitalize text-white text-[14px] font-semibold height-[60px] text-left pl-14">
                  Email Customer Service
                  <small class="block lowercase text-[10px] font-normal">{{ props.configEmail }}</small>
                </span>
              </a>
            </button>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-card-content>
  </ion-card>
</template>

<script setup>
import { ref } from 'vue';
import axios from 'axios';
import { useCustomerStore, useRegisterStore } from '@/stores';
import { storeToRefs } from 'pinia';
const customerStore = useCustomerStore();
const registerStore = useRegisterStore();
const { deviceID, sessionID, environment } = storeToRefs(customerStore);
const { checkExistingAccount } = customerStore;
const { clearPrefill } = registerStore;
const props = defineProps(['configPhone', 'configEmail', 'configLabel', 'configUrl']);
const emit = defineEmits(['closeModal']);
const loading = ref(false);
const resendError = ref('');
const sendCode = (url) => {
  resendError.value = '';
  loading.value = true;
  axios.post(url, {
    device_id: deviceID.value,
    session_id: sessionID.value,
    environment: environment.value
  })
    .then((res) => {
      loading.value = false;
      if (res.status == 200) {
        setTimeout(() => {
          closeModal();
          loading.value = false;
        }, 500);
      }
    })
    .catch((err) => {
      closeModal();
      loading.value = false;
      resendError.value = err;
      setTimeout(() => {
        checkExistingAccount(err.response.status, err.response.data.message, clearPrefill);
      }, 500);
    });
}
const closeModal = () => {
  emit('closeModal');
}
</script>