<template>
  <ion-text class="text-md font-bold ion-text-brand">Security</ion-text>
  <ion-card class="m-0 p-0 my-3 rounded-xl bg-white">
    <ion-card-content class="p-0">
      <ion-grid class="p-0 m-0">
        <ion-row class="pt-3 pb-2 px-6">
          <ion-col>
            <ion-text class="mb-1 block text-sm ion-text-brand font-bold">Username</ion-text>
            <ion-text class="text-sm mb-1 block ion-text-brand">{{ profile.email_address }}</ion-text>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-card-content>
  </ion-card>
  <hr class="my-6 hr-grey"/>
  <ion-text class="text-md font-bold ion-text-brand">Password</ion-text>
  <button expand="block" fill="outline" class="button-outline button-icon button-icon-security mt-6 mb-0" @click="$router.push('change-password')">
    <span class="block capitalize text-[14px] height-[60px]">Change Your Password</span>
  </button>
</template>

<script setup>
import { storeToRefs } from 'pinia';
import { useCustomerStore } from '@/stores';
const customerStore = useCustomerStore();
const { profile } = storeToRefs(customerStore);
</script>