<template>
  <ion-page class="bg-white">
    <ion-content>
      <div class="px-7 pt-6 max-w-xl mx-auto h-full flex items-center justify-center">
        <ion-row>
          <ion-col class="text-center">
            <ion-icon src="/assets/loc/drawn-success.svg" class="w-[200px] h-[140px] fadein-400 mb-2 -ml-[60px]"></ion-icon>
            <ion-text class="text-xl font-bold ion-text-brand my-6 block">Funds Drawn Successfully</ion-text>
            <ion-text class="text-sm ion-text-brand mb-6 block">{{ loanDrawdownMessage }}</ion-text>
            <button expand="block" color="secondary" class="button-orange button-shadow" @click="goBack()">
              <span class="block text-white text-[14px] font-semibold height-[60px]">Back to Account
                Screen</span>
            </button>
          </ion-col>
        </ion-row>
      </div>
    </ion-content>
  </ion-page>
</template>

<script setup>
import router from '@/router';
import { storeToRefs } from 'pinia';
import { useLoansStore } from '@/stores';
const loansStore = useLoansStore();
const { loanDrawdownMessage } = storeToRefs(loansStore);
const goBack = () => {
  router.push('tabs/home');
}
</script>