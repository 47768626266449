<template>
  <ion-page>
    <menu-content></menu-content>
    <ion-content id="main-content">
      <ion-tabs>
        <ion-router-outlet></ion-router-outlet>
        <ion-tab-bar slot="bottom" class="min-h-[80px]">
          <div class="ion-tab-border"></div>
          <ion-tab-button v-for="tab in menuTabs" :key="tab.key" :tab="tab.key" :href="tab.path ? tab.path : null"
            @click="handleTab(tab.path)">
            <ion-icon :icon="setIconActive(route.path, tab.path) ? tab.iconActive : tab.icon"
              class="max-h-[24px] max-w-[24px] mb-1"></ion-icon>
            <ion-label>{{ tab.label }}</ion-label>
          </ion-tab-button>
        </ion-tab-bar>
      </ion-tabs>
    </ion-content>
  </ion-page>
</template>

<script setup>
import { menuController } from '@ionic/vue';
import router from '@/router';
import { useRoute } from 'vue-router';
import { useCustomerStore, useRegisterStore } from '@/stores';
import MenuContent from '@/components/menu/MenuContent.vue';
const customerStore = useCustomerStore();
const registerStore = useRegisterStore();
const { resetIdCheckErrRetryCount } = customerStore;
const { clearPrefill } = registerStore;
const route = useRoute();
const menuTabs = [
  {
    label: 'Home',
    path: '/tabs/home',
    icon: '/assets/tabs/icon-home.svg',
    iconActive: '/assets/tabs/icon-home-active.svg',
    key: 'tab-home'
  },
  {
    label: 'Loans',
    path: '/tabs/loans',
    icon: '/assets/tabs/icon-loans.svg',
    iconActive: '/assets/tabs/icon-loans-active.svg',
    key: 'tab-loans'
  },
  {
    label: 'Notifications',
    path: '/tabs/notifications',
    icon: '/assets/tabs/icon-notifications.svg',
    iconActive: '/assets/tabs/icon-notifications-active.svg',
    key: 'tab-notifications'
  },
  {
    label: 'Menu',
    path: null,
    icon: '/assets/tabs/icon-menu.svg',
    iconActive: '/assets/tabs/icon-menu-active.svg',
    key: 'tab-menu'
  }
];
const getTabName = (tab) => String(tab).split('/')[2];
const setIconActive = (route, tab) => (route == tab || getTabName(route) == getTabName(tab)) ? true : false;
const handleTab = (path) => {
  clearPrefill();
  resetIdCheckErrRetryCount();
  if (path == '/tabs/loans') {
    router.push({ name: 'Loans' })
  }
  if (!path) {
    menuController.open()
  }
};
</script>

<style scoped>
ion-menu {
  --background: white;
  --width: 90%;
}

ion-menu::part(backdrop) {
  opacity: 0.1;
}

ion-menu::part(container) {
  border-radius: 0 25px 25px 0;
  max-width: 320px;
}

ion-tab-bar {
  border-top: 3px solid white;
}

ion-tab-button.tab-selected {
  border-top: 3px solid var(--ion-color-primary);
}
</style>