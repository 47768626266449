<template>
  <div class="text-center">
    <ion-icon src="/assets/home/icon-credit-check.svg" class="w-[120px] h-[80px] -mt-6"></ion-icon>
    <h2 class="text-[18px] font-bold pb-6 text-center leading-6 mt-6">
      Connecting to your credit file from Equifax...
    </h2>
    <ion-text class="text-xs ion-text-grey text-center block mx-auto mb-6">
      This should only take a minute
    </ion-text>
    <ion-spinner name="dots" v-if="loading"></ion-spinner>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import router from '@/router';
import axios from 'axios';
import { useBrowser } from '@/composables/browser';
import { useCustomerStore } from '@/stores';
import { storeToRefs } from 'pinia';
const customerStore = useCustomerStore();
const { deviceID, environment, authToken, creditScoreConsent, startTrackCreditScore, existingCustomer, getCustomerParams } = storeToRefs(customerStore);
const { setProfile, setCreditScoreErrMsg, setIdCheckErrTitle, setIdCheckErrMsg } = customerStore;
const { urls } = useBrowser();
const loading = ref(true);
const errMsg = ref('Please check your details are correct');
const errTitleEnrol = ref('Sorry, there was an issue<br />processing your request');
const errMsgEnrol = ref('Please try again later or contact us by phone or email.');
const payload = {
  device_id: deviceID.value,
  environment: environment.value,
  consent: creditScoreConsent.value
};
const headers = {
  headers: {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + authToken.value,
  }
};
const refreshCreditScore = () => {
  setTimeout(() => {
    axios.get(urls.apiCustomer + getCustomerParams.value, headers)
      .then((res) => {
        setProfile(res.data.customer);
      })
      .then(() => {
        loading.value = false;
        router.push('tabs/home');
      })
      .catch(err => {
        console.log(err)
      });
  }, 3000);
}
const creditCheckEnrol = () => {
  axios.post(urls.apiCreditCheckEnrolled, payload, headers)
    .then(res => {
      if (res.status == 200) {
        refreshCreditScore();
      } else {
        setCreditScoreErrMsg(errMsg.value);
        router.push({ name: 'CreditScoreConnectFail' });
      }
    }).catch(err => {
      loading.value = false;
      err.response.data.error ? setCreditScoreErrMsg(err.response.data.error) : setCreditScoreErrMsg(errMsg.value);
      router.push({ name: 'CreditScoreConnectFail' });

    });
}
const creditCheckEnrolNewCustomer = () => {
  axios.post(urls.apiCreditCheckEnrolledNewCustomer, payload, headers)
    .then((res) => {
      if (res.status == 200) {
        refreshCreditScore();
      } else {
        setIdCheckErrMsg(errMsgEnrol.value);
        setIdCheckErrTitle(errTitleEnrol.value);
        router.push({ name: 'CreditScoreIDVerifyFail' });
      }
    })
    .catch((err) => {
      loading.value = false;
      err.response.data.error ? setIdCheckErrMsg(err.response.data.error) : setIdCheckErrMsg(errMsgEnrol.value);
      setIdCheckErrTitle(errTitleEnrol.value);
      router.push({ name: 'CreditScoreIDVerifyFail' });
    });
}
onMounted(() => {
  setTimeout(() => {
    if (startTrackCreditScore.value && existingCustomer.value == null) {
      creditCheckEnrolNewCustomer();
    } else {
      if (existingCustomer.value) {
        creditCheckEnrol();
      } else {
        creditCheckEnrolNewCustomer();
      }
    }
  }, 3000);
});
</script>