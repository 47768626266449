import { defineStore } from "pinia";
import { useBrowser } from "@/composables/browser";
import { useCustomerStore } from "@/stores";
import { storeToRefs } from "pinia";

export const useConfigStore = defineStore("config", {
  state: () => ({
    appName: "BetterCredit",
    defaultPhone: "1300 189 823", // for register modal before config data is available
    defaultEmail: "hello@jacarandafinance.com.au", // for register modal before config data is available
    config: {},
    loading: true,
    started: true,
    locEnabled: false,
    creditScoreEnabled: false,
    financeFundamentalsEnabled: false,
    screenSizeSmall: false,
    minimumSupportedVersion: null,
  }),

  getters: {
    getConfig(state) {
      return state.config;
    },

    getLoading(state) {
      return state.loading;
    },

    getStarted(state) {
      return state.started;
    },

    getLocEnabled(state) {
      return state.locEnabled;
    },

    getFinanceFundamentalsEnabled(state) {
      return state.financeFundamentalsEnabled;
    },
  },

  actions: {
    setConfig(val) {
      this.config = val;
      this.setLocEnabled();
      this.setCreditScoreEnabled();
      this.setFinanceFundamentalsEnabled();
      this.setMinimumSupportedVersion();
    },

    setLoading(val) {
      this.loading = val;
    },

    setStarted(val) {
      this.started = val;
    },

    checkValue(obj, val) {
      return Object.keys(obj).includes(val) ? obj[val] : false;
    },

    setLocEnabled() {
      this.locEnabled = this.checkValue(this.config, "line_of_credit_enabled");
    },

    setCreditScoreEnabled() {
      this.creditScoreEnabled = this.checkValue(
        this.config,
        "credit_score_enabled"
      );
    },

    setFinanceFundamentalsEnabled() {
      this.financeFundamentalsEnabled = this.checkValue(
        this.config,
        "finance_fundamentals_enabled"
      );
    },

    setMinimumSupportedVersion() {
      this.minimumSupportedVersion = this.checkValue(
        this.config,
        "minimum_supported_version"
      );
    },

    checkScreenSize() {
      const { physicalScreenSize } = useBrowser();
      const { width, height } = physicalScreenSize();
      const customerStore = useCustomerStore();
      const { deviceMobileWeb } = storeToRefs(customerStore);
      if (Capacitor.isNativePlatform()) {
        this.screenSizeSmall = width < 1080 || height < 1600;
      } else {
        if (deviceMobileWeb.value) {
          this.screenSizeSmall = true;
        }
      }
    },

    clearConfig() {
      this.config = {};
    },
  },
});
