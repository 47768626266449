<template>
  <ion-refresher slot="fixed" @ionRefresh="refreshContent($event)">
    <ion-refresher-content></ion-refresher-content>
  </ion-refresher>
</template>

<script setup>
import axios from 'axios';
import { useBrowser } from '@/composables/browser';
import { useCustomerStore, useNotificationsStore, useLoansStore, useApplicationsStore } from '@/stores';
import { storeToRefs } from 'pinia';
import { addCustomerIdToSession } from "@/composables/adjust";
const customerStore = useCustomerStore();
const { environment, currentVersion, authToken, getCustomerParams } = storeToRefs(customerStore);
const { setProfile, updateNewCustomer, getFinanceFundamentals } = customerStore;
const notificationsStore = useNotificationsStore();
const { setNotifications } = notificationsStore;
const loansStore = useLoansStore();
const applicationsStore = useApplicationsStore();
const { setLoans } = loansStore;
const { setPendingApplication } = applicationsStore;
const { urls } = useBrowser();
const refreshContent = (e) => {
  setTimeout(() => {
    axios.get(urls.apiCustomer + getCustomerParams.value, {
      headers: {
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + authToken.value,
      }
    })
      .then((res) => {
        setProfile(res.data.customer);
        setNotifications(res.data.customer.notifications);
        setLoans(res.data.customer.loans);
        setPendingApplication(res.data.customer.pending_application);
        updateNewCustomer(false);
        addCustomerIdToSession(res.data.customer.id);
      })
      .then(() => {
        getFinanceFundamentals(urls.apiFinanceFundamentals + '?environment=' + environment.value + '&app_version=' + currentVersion.value, authToken.value)
      })
      .catch(err => console.log(err));
    e.target.complete();
  }, 2000);
}
</script>