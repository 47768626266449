<template>
  <ion-page class="bg-white">
    <ion-content>
      <div class="px-7 pt-6 max-w-xl mx-auto h-full flex items-center justify-center">
        <ion-row>
          <ion-col class="text-center">
            <ion-icon src="/assets/general/bin.svg" class="w-[100px] h-[120px] fadein-400 mb-2"></ion-icon>
            <ion-text class="text-xl font-bold ion-text-brand mt-6 block">Account Deleted</ion-text>
          </ion-col>
        </ion-row>
      </div>
    </ion-content>
  </ion-page>
</template>

<script setup>
import { onMounted } from 'vue';
import router from '@/router';
onMounted(() => {
  setTimeout(() => {
    router.push('home');
  }, 3000);
});
</script>