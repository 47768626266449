<template>
  <ion-page class="ion-page-white">
    <menu-header>
      <ion-text class="font-bold ion-text-brand block text-center">Get Started Now</ion-text>
    </menu-header>
    <ion-content>
      <div class="max-w-xl mx-auto h-full flex flex-col justify-between">
        <div>
          <div class="flex p-6 items-center justify-between">
            <ion-icon src="/assets/calculator/icon-review-1.svg" class="w-[68px] h-[20px]"></ion-icon>
            <ion-icon src="/assets/calculator/icon-review-2.svg" class="w-[66px] h-[20px]"></ion-icon>
            <ion-icon src="/assets/calculator/icon-review-3.svg" class="w-[64px] h-[24px]"></ion-icon>
            <ion-icon src="/assets/calculator/icon-review-4.svg" class="w-[68px] h-[24px]"
              @click="showDebugConsole"></ion-icon>
          </div>
          <div class="text-center mt-9">
            <!-- <ion-icon src="/assets/calculator/application-process.svg"
              class="w-[300px] h-[260px] fadein-400 mb-4 mx-auto block"></ion-icon> -->
            <img src="/assets/calculator/application-process.gif"
              class="w-[300px] h-[260px] fadein-400 mb-4 mx-auto block" />
            <ion-icon src="/assets/calculator/icon-tick.svg" class="w-[24px] h-[24px] fadein-800"></ion-icon>
            <ion-text class="text-sm ion-text-brand mt-2 block">Checking your loan eligibility<br /> <strong
                class="underline">will not impact</strong> your credit score!</ion-text>
          </div>
          <div class="pt-3 px-6 mx-1 max-w-xl mx-auto text-center">
            <button expand="block" color="secondary" class="button-green mt-6 mb-3 max-w-sm"
              @click="authenticated ? applyExisting(urls.apiCustomerApply) : applyNew(urls.createAccount)">
              <span class="block capitalize text-white text-[20px] font-semibold tracking-normal height-[60px]">
                <ion-spinner name="crescent" color="success" class="absolute -ml-3 -mt-3" v-if="loading"></ion-spinner>
                <div class="flex align-center justify-center" v-else>
                  Get Started Now <img src="/assets/general/arrow.svg" class="ml-2" />
                </div>
              </span>
            </button>
            <small class="text-center ion-text-grey text-xs mb-6 block">Application form will continue in external
              window.</small>
          </div>
        </div>
        <div v-if="!authenticated">
          <div class="text-center text-sm mb-3">
            <span class="ion-text-grey">New customer? </span>
            <button fill="clear" @click="$router.push('register-step-1')">
              <span class="ion-text-primary font-bold underline">Register</span>
            </button>
          </div>
          <div class="text-center text-sm mb-9">
            <span class="ion-text-grey">Already have an account? </span>
            <button fill="clear" @click="$router.push('login-email')">
              <span class="ion-text-primary font-bold underline">Log In</span>
            </button>
          </div>
        </div>
      </div>
      <debug-console :show="canShowDebugConsole"></debug-console>
    </ion-content>
  </ion-page>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue';
import MenuHeader from '@/components/menu/MenuHeader.vue';
import DebugConsole from "@/components/debug/DebugConsole.vue";
import { useBrowser } from '@/composables/browser';
import { useRegisterStore, useCustomerStore } from '@/stores';
import { storeToRefs } from 'pinia';
import axios from 'axios';
import { applyDeviceParamsToUrl } from "@/composables/adjust";
const customerStore = useCustomerStore();
const { authenticated, deviceID, environment, authToken } = storeToRefs(customerStore);
const { openBrowser, urls } = useBrowser();
const registerStore = useRegisterStore();
const { clearPrefill } = registerStore;
const loading = ref(false);
const debugCounter = ref(0);
const applyExisting = (url) => {
  loading.value = true;
  axios.post(url,
    {
      device_id: deviceID.value,
      environment: environment.value
    },
    {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + authToken.value
      }
    })
    .then(res => {
      if (res.status == 200) {
        const source = res.data.type == 'repeat' ? 'APPLYEXISTING' : 'APPLYNEW';
        openBrowser(applyDeviceParamsToUrl(res.data.apply_url, source));
        loading.value = false;
      } else {
        loading.value = false;
        openBrowser(applyDeviceParamsToUrl(urls.createAccount, 'APPLYNEW'));
      }
    }).catch(err => {
      if (err) {
        loading.value = false;
        openBrowser(applyDeviceParamsToUrl(urls.createAccount, 'APPLYNEW'));
      }
    });
}
const applyNew = (url) => {
  openBrowser(applyDeviceParamsToUrl(url, 'APPLYNEW'));
}
const showDebugConsole = () => {
  debugCounter.value++
}
const canShowDebugConsole = computed(() => {
  return debugCounter.value >= 5 && debugCounter.value % 5 == 0;
});
onMounted(() => {
  clearPrefill();
})
</script>