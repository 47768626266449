<template>
  <ion-page class="ion-page-white">
    <ion-content>
      <start-header></start-header>
      <div class="p-9 max-w-xl mx-auto">
        <h2 class="text-[30px] font-bold py-9">Log In</h2>
        <ion-item class="default" lines="none" :class="shake ? 'shake' : ''">
          <ion-input type="email" placeholder="Email Address"
            class="default border-2 rounded-xl text-base h-[50px] w-full" v-model="email"
            @input="resetMessage"></ion-input>
        </ion-item>
        <ion-note slot="helper" class="w-full text-xs px-1 mt-3 block">{{ emailError }}</ion-note>
        <ion-note slot="helper" class="w-full text-xs px-1 mt-3 block">{{ validateError }}</ion-note>

        <button class="button-purple mt-3 mb-8 px-6" @click="nextStep(urls.apiLoginVerify)" :disabled="!validated">
          <span class="block text-center" v-if="loading">
            <ion-spinner name="crescent" color="light" class="absolute -ml-3 -mt-3"></ion-spinner>
          </span>
          <span class="block capitalize text-white text-lg font-semibold" v-else>
            Next <img src="/assets/general/arrow-2.svg" class="float-right ml-2 mt-2" />
          </span>
        </button>

        <div class="text-center text-base mb-3">
          <span class="ion-text-grey mb-3 block text-sm block">
            You can use your previous customer portal account details to login here.
          </span>
          <button fill="clear" class="w-full text-center mt-3 mb-6" id="open-modal-forgot-username">
            <span class="ion-text-grey font-bold underline">Forgot Username?</span>
          </button>
          <span class="ion-text-grey text-sm">Don't have an account? </span>
          <button fill="clear" @click="getStarted">
            <span class="ion-text-green font-bold underline">Get Started</span>
          </button>
        </div>

        <ion-modal class="modal-center" ref="modal-forgot-username" trigger="open-modal-forgot-username"
          backdrop-dismiss="false">
          <ion-card class="bg-white m-0 p-0 rounded-xl">
            <ion-card-content class="p-4">
              <ion-grid>
                <ion-row>
                  <ion-col class="py-0">
                    <ion-icon src="/assets/general/close-grey.svg"
                      class="w-[24px] h-[24px] absolute block right-0 m-1 cursor-pointer opacity-80 hover:opacity-100"
                      @click="closeModal"></ion-icon>
                    <ion-text class="ion-text-purple block my-2 mr-8">
                      If your email address is not being recognised, you can contact our customer service team to
                      confirm the email address used for your account.
                    </ion-text>
                    <button expand="block" color="secondary" class="button-purple button-icon button-icon-phone my-3">
                      <a :href="'tel:' + configPhone">
                        <span
                          class="block capitalize text-white text-[14px] font-semibold height-[60px] text-left pl-14">
                          Call Customer Service
                          <small class="block">{{ configPhone }}</small>
                        </span>
                      </a>
                    </button>
                  </ion-col>
                </ion-row>
              </ion-grid>
            </ion-card-content>
          </ion-card>
        </ion-modal>
      </div>
    </ion-content>
  </ion-page>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue';
import router from '@/router';
import { useField } from 'vee-validate';
import StartHeader from '@/components/start/StartHeader.vue';
import { useCustomerStore, useConfigStore } from '@/stores';
import { storeToRefs } from 'pinia';
import axios from 'axios';
import { useBrowser } from '@/composables/browser';
import { AdjustEvents, trackEvent } from "@/composables/adjust";
import { modalController } from '@ionic/vue';
const { urls } = useBrowser();
const customerStore = useCustomerStore();
const configStore = useConfigStore();
const { startCheckIfQualify, startTrackCreditScore } = storeToRefs(customerStore);
const { setEmail, setStartCheckIfQualify, setStartTrackCreditScore } = customerStore;
const { config, defaultPhone } = storeToRefs(configStore);
const configPhone = computed(() => config.customer_service_phone ? config.customer_service_phone : defaultPhone.value);
const validated = ref(false);
const loading = ref(false);
const shake = ref(false);
const validateError = ref('');
const { value: email, errorMessage: emailError } = useField('email', (value) => {
  if (!value) {
    validated.value = false;
    return 'This field is required';
  }
  const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (!regex.test(String(value).toLowerCase())) {
    validated.value = false;
    return 'Please enter a valid email address';
  } else {
    validated.value = true;
    return true;
  }
});
const resetMessage = () => {
  validateError.value = '';
}
const nextStep = (url) => {
  loading.value = true;
  axios.post(url, { email: email.value })
    .then(res => {
      if (res.status == 200) {
        setEmail(email.value);
        router.push('login-password');
      }
    })
    .catch(err => {
      loading.value = false;
      validated.value = false;
      validateError.value = err.response.data.message;
      if (err) {
        shake.value = true;
      }
    });
}
const getStarted = () => {
  if (!startCheckIfQualify.value && !startTrackCreditScore.value) {
    setStartCheckIfQualify(true);
    setStartTrackCreditScore(false);
  }
  router.push('register-step-1');
}
const closeModal = () => {
  modalController.dismiss();
};
onMounted(() => {
  trackEvent(AdjustEvents.CLICK_LOGIN)
})
</script>