<template>
  <button expand="block" color="secondary" class="button-purple button-icon button-icon-phone">
    <a :href="'tel:' + config.customer_service_phone">
      <span class="block capitalize text-white text-[14px] font-semibold height-[60px]">
        Call Customer Service
        <small class="block">{{ config.customer_service_phone }}</small>
      </span>
    </a>
  </button>
  <button expand="block" color="secondary" class="button-purple button-icon button-icon-email mt-3">
    <a :href="'mailto:' + config.customer_service_email">
      <span class="block capitalize text-white text-[14px] font-semibold height-[60px]">
        Email Customer Service
        <small class="block lowercase text-[10px] font-normal">{{ config.customer_service_email }}</small>
      </span>
    </a>
  </button>
</template>
<script setup>
import { useConfigStore } from '@/stores';
import { storeToRefs } from 'pinia';
const configStore = useConfigStore();
const { config } = storeToRefs(configStore);
</script>