<template>
  <ion-card class="bg-white m-0 p-0 my-4 rounded-xl credit-score-card" v-if="creditScore.status">
    <ion-card-content class="bg-white-500 p-3 pb-0">
      <ion-grid>
        <ion-row class="mb-2" @click="toggleVisible">
          <ion-col class="py-0" size="1">
            <ion-icon src="/assets/credit-score/icon-calendar.svg" class="w-[16px] pt-1"></ion-icon>
          </ion-col>
          <ion-col class="py-0 text-center">
            <ion-text class="ion-text-purple block font-bold text-sm">Credit Score History</ion-text>
          </ion-col>
          <ion-col class="py-0 text-right" size="1">
            <ion-icon src="/assets/general/arrow-grey.svg" class="w-[15px] pt-1 button-toggle"
              :class="visible ? 'button-toggle' : 'button-toggle-up'"></ion-icon>
          </ion-col>
        </ion-row>
        <ion-row class="mb-2 fadein-300" v-if="visible">
          <div class="credit-score-history-top flex justify-between align-center w-full py-2"
            :class="screenSizeSmall ? 'ml-1' : 'mx-2'">
            <ion-text class="ion-text-grey-2 text-[11px]">
              <span class="bullet bullet-green"></span> Positive Change
            </ion-text>
            <ion-text class="ion-text-grey-2 text-[11px]">
              <span class="bullet bullet-red"></span> Negative Change
            </ion-text>
            <ion-text class="ion-text-grey-2 text-[11px]">
              <span class="bullet bullet-grey"></span> No Change
            </ion-text>
          </div>
          <ion-row class="credit-score-history-mid w-full mt-3 p-0">
            <ion-col class="max-w-[35px]">
              <div class="h-full flex flex-wrap">
                <ion-text class="ion-text-grey-2 text-right text-[11px] w-full min-h-[50%]">1200</ion-text>
                <ion-text class="ion-text-grey-2 text-right text-[11px] w-full min-h-[50%] -mt-1">600</ion-text>
                <ion-text class="ion-text-grey-2 text-right text-[11px] w-full -mt-1">0</ion-text>
              </div>
            </ion-col>
            <ion-col>
              <div
                class="credit-score-history-chart min-h-[122px] w-full mt-2 flex justify-end items-end flex-row-reverse space-x-reverse">
                <div v-for="(item, index) in creditScoreHistory" :key="index"
                  class="bar w-[calc((100%-96px)/12)] mx-[4px]" :class="`bar-${creditScoreHistory.length - index}`">
                  <div class="w-full h-full" :class="chartBarClass(index)" :style="chartBarStyle(item.score)"></div>
                </div>
              </div>
            </ion-col>
          </ion-row>
          <ion-row class="credit-score-history-btm w-full mt-2 p-0 mb-6">
            <ion-col class="max-w-[35px]"></ion-col>
            <ion-col>
              <div class="w-full flex justify-end align-right items-end flex-row-reverse space-x-reverse">
                <li v-for="(item, index) in creditScoreHistory" :key="index"
                  class="label ion-text-grey-2 text-[11px] w-[calc((100%-96px)/12)] mx-[4px]"
                  :class="chartBarLabel(item, index, creditScoreHistory.length) ? 'list-none' : 'list'">
                  {{ chartBarLabel(item, index, creditScoreHistory.length) }}
                </li>
              </div>
            </ion-col>
          </ion-row>
        </ion-row>
      </ion-grid>
    </ion-card-content>
  </ion-card>
  <credit-score-history-locked title="Credit Score History (Locked)" v-else></credit-score-history-locked>
</template>

<script setup>
import { ref } from 'vue';
import { storeToRefs } from 'pinia';
import { useCustomerStore, useConfigStore } from '@/stores';
import { useMixed } from '@/composables/mixed';
import CreditScoreHistoryLocked from '@/components/creditScore/CreditScoreHistoryLocked.vue';
const customerStore = useCustomerStore();
const configStore = useConfigStore();
const { formatMonthYear, formatAddMonthYear } = useMixed();
const { creditScore } = storeToRefs(customerStore);
const { screenSizeSmall } = storeToRefs(configStore);
const visible = ref(true);
const creditScoreHistory = creditScore.value.scores ? [Object.values(creditScore.value.scores)][0].sort((a, b) => a.month - b.month) : [];
const chartBarClass = (index) => {
  if (creditScoreHistory[index] && creditScoreHistory[index + 1]) {
    if (creditScoreHistory[index].score > creditScoreHistory[index + 1].score) {
      return 'bar-green';
    } else if (creditScoreHistory[index].score < creditScoreHistory[index + 1].score) {
      return 'bar-red';
    } else {
      return 'bar-grey';
    }
  } else {
    return 'bar-grey';
  }
}
const chartBarStyle = (item) => item > 0 ? `height: ${120 * (item / 1200)}px; margin-top: ${120 - 120 * (item / 1200)}px` : `height: 2px; margin-top: 118px`;
const chartBarLabel = (item, index, length) => {
  let labelMid = length % 2 ? (length + 1) / 2 - 1 : null;
  let labelShow = index == 0 || index == length - 1 || length > 4 && index == labelMid;
  let labelDatesAvailable = creditScoreHistory.filter(item => item.date);
  let labelDatesCalculated = labelDatesAvailable.length ? formatAddMonthYear(labelDatesAvailable[0].date, labelDatesAvailable[0].month - item.month) : null;
  let labelDatesAll = creditScoreHistory.map(item => item.date ? item.date : labelDatesCalculated);
  let labelDate = labelDatesAll[index] ? formatMonthYear(labelDatesAll[index]) : null
  return labelShow ? (item.date ? formatMonthYear(item.date) : labelDate) : null;
}
const toggleVisible = () => {
  visible.value = !visible.value;
}
</script>