<template>
  <ion-page class="ion-page-white">
    <ion-content>
      <register-header></register-header>
      <div class="p-9 max-w-xl mx-auto">
        <h2 class="text-[24px] font-bold pb-9 text-center">Email</h2>
        <register-steps step="3"></register-steps>
        <div class="ion-item-wrapper">
          <ion-item class="default" lines="none">
            <ion-label position="floating">Email<sup>*</sup></ion-label>
            <ion-input type="email" v-model="email" @input="handleInput"></ion-input>
          </ion-item>
        </div>
        <ion-note slot="helper" class="w-full text-xs px-1 my-3 ion-text-primary block" v-if="emailError">{{ emailError }}</ion-note>
        <ion-note slot="helper" class="w-full text-xs px-1 my-3 ion-text-primary block" v-if="emailExist">
            {{  emailExist }}
          <button fill="clear" @click="$router.push('login-email')" v-if="emailExist == 'The email has already been taken.'">
            <span class="ion-text-primary font-bold underline">Log In</span>
          </button>
        </ion-note>
        <ion-text class="text-sm ion-text-grey text-center block mt-3">Have your email handy for the verification
          code.</ion-text>
        <button class="button-purple button-register mt-4 px-6"
          @click="btnNext('register-step-3-verify', urls.apiSignUpStepEmail)" :disabled="!entered || !validated || loading">
          <span class="block text-center" v-if="loading">
            <ion-spinner name="crescent" class="absolute -ml-3 -mt-3"></ion-spinner>
          </span>
          <span class="block capitalize text-lg font-semibold text-white" v-else>
            Next <img :src="imgNext" class="float-right ml-2 mt-2" />
          </span>
        </button>
      </div>
    </ion-content>
  </ion-page>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue';
import router from '@/router';
import { useField } from 'vee-validate';
import RegisterHeader from '@/components/register/RegisterHeader.vue';
import RegisterSteps from '@/components/register/RegisterSteps.vue';
import { useRegisterStore, useCustomerStore } from '@/stores';
import { storeToRefs } from 'pinia';
import axios from 'axios';
import { useBrowser } from '@/composables/browser';
const { urls } = useBrowser();
const registerStore = useRegisterStore();
const { setEmail, setStepThreeCompleted } = registerStore;
const { stepTwoCompleted, title, firstName, surname, middleName, dob } = storeToRefs(registerStore);
const customerStore = useCustomerStore();
const { setSessionID, setEnvironment } = customerStore;
const { deviceID } = storeToRefs(customerStore);
const entered = computed(() => email.value !== '');
const imgNext = computed(() => entered.value ? '/assets/general/arrow-white.svg' : '/assets/general/arrow-3.svg');
const validated = ref(false);
const emailExist = ref('');
const loading = ref(false);
const handleInput = () => {
  emailExist.value = '';
}
const { value: email, errorMessage: emailError } = useField('email', (value) => {
  if (!value) {
    validated.value = false;
    return 'This field is required';
  }
  const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (!regex.test(String(value).toLowerCase())) {
    validated.value = false;
    return 'Please enter a valid email address';
  } else {
    validated.value = true;
    return true;
  }
});

const btnNext = (next, url) => {
  loading.value = true;
  setEmail(email.value);
  axios.post(url, {
    device_id: deviceID.value,
    title: title.value,
    email: email.value,
    first_name: firstName.value,
    middle_name: middleName.value,
    last_name: surname.value,
    date_of_birth: dob.value
  })
  .then((res) => {
    if (res.status == 200) {
      loading.value = false;
      setSessionID(res.data.session_id);
      setEnvironment(res.data.environment);
      setStepThreeCompleted(true);
      router.push(next);
    }
  })
  .catch((err) => {
    loading.value = false;
    validated.value = false;
    emailExist.value = err.response.data.message;
  });
}

onMounted(() => {
  if (!stepTwoCompleted.value) {
    router.push('register-step-1')
  }
});
</script>