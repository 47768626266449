<template>
   <ion-text class="text-sm ion-text-grey mb-2 text-center block">Step <span class="font-bold ion-text-primary">{{props.step}}/5</span></ion-text>
   <ul class="text-center mb-6">
    <li v-for="(item, index) in steps" :key="index" class="inline px-2">
      <ion-icon :src="item == props.step ? '/assets/general/dot-purple.svg' : '/assets/general/dot-grey.svg'" class="w-[10px] h-[10px]"></ion-icon>
    </li>
   </ul>
</template>

<script setup>
import { ref } from 'vue';
const props = defineProps(['step']);
const steps = ref(5)
</script>