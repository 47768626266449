import { Browser } from '@capacitor/browser';
import { App as CapacitorApp } from '@capacitor/app';
import { modalController } from '@ionic/vue';
import router from '@/router';
import { useRoute } from 'vue-router';
import { useLoansStore, useCustomerStore } from '@/stores';
import { storeToRefs } from 'pinia';

export function useBrowser() {
  const baseUrls = {
    general: 'https://www.jacarandafinance.com.au',
    apply: 'https://apply.jacarandafinance.com.au',
    mimoPortal: 'https://portal.mimotech.com.au',
    // mimoPortal: 'https://portal.mimotech.dev', // local testing
  }

  const urls = {
    // general
    createAccount: baseUrls.apply + '/jf/apply',
    faq: baseUrls.general + '/frequently-asked-questions/',
    privacy: baseUrls.general + '/about/privacy-policy/',
    creditScoreExplained: baseUrls.general + '/financial-tips/what-does-credit-score-mean/',
    creditScoreImprove: baseUrls.general + '/financial-tips/how-to-improve-your-credit-score/',
    ccr: baseUrls.general + '/comprehensive-credit-reporting/',
    equifax: 'https://www.equifax.com.au/',
    loc: baseUrls.general + '/general/what-is-a-line-of-credit/',
    mimoScript: baseUrls.mimoPortal + '/js/loan-calculator.js',
    checkoutScript: 'https://cdn.checkout.com/js/framesv2.min.js',
    googleMapPlaces:'https://maps.googleapis.com/maps/api/js?libraries=places&loading=async&key=AIzaSyA5VtnzoWOX1PGHS5-vN96DwGq4szWPUpY',

    // mobile api
    apiSignUpStepNameVerify: baseUrls.mimoPortal + '/mobile-api/signup/name-validation',
    apiSignUpStepDobVerify: baseUrls.mimoPortal + '/mobile-api/signup/dob-validation',
    apiSignUpStepEmail: baseUrls.mimoPortal + '/mobile-api/signup/personal-details',
    apiSignUpStepEmailVerify: baseUrls.mimoPortal + '/mobile-api/signup/verify-email',
    apiSignUpStepEmailResend: baseUrls.mimoPortal + '/mobile-api/signup/resend-email-code',
    apiSignUpStepMobile: baseUrls.mimoPortal + '/mobile-api/signup/mobile-details',
    apiSignUpStepMobileVerify: baseUrls.mimoPortal + '/mobile-api/signup/verify-mobile',
    apiSignUpStepMobileResend: baseUrls.mimoPortal + '/mobile-api/signup/resend-mobile-code',
    apiSignUpStepPassword: baseUrls.mimoPortal + '/mobile-api/signup/password-details',
    apiLogin: baseUrls.mimoPortal + '/mobile-api/login',
    apiLogOut: baseUrls.mimoPortal + '/mobile-api/logout',
    apiForgotPassword: baseUrls.mimoPortal + '/mobile-api/forgot-password',
    apiRecoverPassword: baseUrls.mimoPortal + '/mobile-api/recover-password',
    apiLoginVerify: baseUrls.mimoPortal + '/mobile-api/validate-username',
    apiCustomer: baseUrls.mimoPortal + '/mobile-api/customer',
    apiCustomerApply: baseUrls.mimoPortal + '/mobile-api/customer/apply',
    apiTerms: baseUrls.mimoPortal + '/mobile-api/customer/payment-terms',
    apiContact: baseUrls.mimoPortal + '/mobile-api/customer/contact',
    apiChangePassword: baseUrls.mimoPortal + '/mobile-api/customer/change-password',
    apiLoanStatement: baseUrls.mimoPortal + '/mobile-api/customer/loan-statement/',
    apiLoanContract: baseUrls.mimoPortal + '/mobile-api/customer/loan-contract/',
    apiLoanSchedule: baseUrls.mimoPortal + '/mobile-api/customer/loan-schedule/',
    apiLoanSettlement: baseUrls.mimoPortal + '/mobile-api/customer/loan-settlement/',
    apiLoanBankTransfer: baseUrls.mimoPortal + '/mobile-api/customer/banks',
    apiNotifications: baseUrls.mimoPortal + '/mobile-api/customer/read-notification',
    apiStoredCards: baseUrls.mimoPortal + '/mobile-api/customer/stored-cards',
    apiApplyLoc: baseUrls.mimoPortal + '/mobile-api/customer/apply-loc',
    apiDrawdown: baseUrls.mimoPortal + '/mobile-api/customer/drawdown/',
    apiCreditScoreConsents: baseUrls.mimoPortal + '/mobile-api/customer/credit-score-consents',
    apiCreditScoreConsentsPreview: baseUrls.mimoPortal + '/mobile-api/customer/credit-score-check-consent-preview',
    apiCreditCheckEnrolled: baseUrls.mimoPortal + '/mobile-api/customer/credit-check/enrol',
    apiCreditCheckEnrolledStart: baseUrls.mimoPortal + '/mobile-api/customer/credit-check/enrol/start',
    apiCreditCheckEnrolledNewCustomer: baseUrls.mimoPortal + '/mobile-api/customer/credit-check/enrol/new-customer',
    apiFinanceFundamentals: baseUrls.mimoPortal + '/mobile-api/courses/finance_fundamentals',
    apiFinanceFundamentalsEnrolment: baseUrls.mimoPortal + '/mobile-api/courses/finance_fundamentals/enrol',
    apiFinanceFundamentalsLesson: baseUrls.mimoPortal + '/mobile-api/courses/finance_fundamentals/lesson/',
    apiFinanceFundamentalsQuiz: baseUrls.mimoPortal + '/mobile-api/courses/finance_fundamentals/quiz/',
    apiIdentityVerificationConsent: baseUrls.mimoPortal + '/mobile-api/customer/identity-verification-consent',
    apiIdentityVerificationConsentPreview: baseUrls.mimoPortal + '/mobile-api/customer/identity-verification-consent-preview',
    apiIdentityAndCreditScoreConsentSign: baseUrls.mimoPortal + '/mobile-api/customer/identity-and-credit-score-check-consent-sign',
    apiAccommodationDetails: baseUrls.mimoPortal + '/mobile-api/customer/accommodation_details',
    apiIdentificationDetails: baseUrls.mimoPortal + '/mobile-api/customer/identification_details',
    apiIdentificationCheck: baseUrls.mimoPortal + '/mobile-api/customer/identity_check'
  }

  const openBrowser = (url, style) => {
    if (style) {
      Browser.open({ url, presentationStyle: 'popover' });
    } else {
      Browser.open({ url });
    }
  };

  const setAsyncTimeout = (cb, timeout = 0) => new Promise(resolve => {
    setTimeout(() => {
      cb();
      resolve();
    }, timeout);
  });

  const backButton = () => {
    CapacitorApp.addListener('backButton', ({ canGoBack }) => {
      modalController.dismiss();
      if (!canGoBack) {
        CapacitorApp.exitApp();
      } else {
        window.history.back();
      }
    });
  };

  const physicalScreenSize = () => {
    return {
      width: window.screen.width * window.devicePixelRatio,
      height: window.screen.height * window.devicePixelRatio
    }
  };

  const checkoutFrameURLChange = (checkoutFrame, callback) => {
    let lastDispatched = null;
    const dispatchChange = () => {
      try {
        let newHref = checkoutFrame.contentWindow.location.href;
        if (newHref !== lastDispatched) {
          callback(newHref);
          lastDispatched = newHref;
        }
      } catch (e) {
        return
      }
    };
    const unloadHandler = () => {
      setTimeout(dispatchChange, 0);
    };
    const attachUnload = () => {
      try{
        checkoutFrame.contentWindow.removeEventListener("unload", unloadHandler);
        checkoutFrame.contentWindow.addEventListener("unload", unloadHandler);
      } catch (e) {
        return
      }
    }
    checkoutFrame.addEventListener("load", () => {
      attachUnload();
      dispatchChange();
    });
    attachUnload();
  };

  const checkDeepLink = () => {
    const route = useRoute();
    const loansStore = useLoansStore();
    const customerStore = useCustomerStore();
    const { filterLoan, setModalPaymentOverdueOpen } = loansStore;
    const { loanSelected } = storeToRefs(loansStore);
    const { creditScoreEnrolled } = storeToRefs(customerStore);
    CapacitorApp.addListener('appUrlOpen',  (e) => {
      setModalPaymentOverdueOpen(false);
      setTimeout(() => {
        if (e.url) {
          const slug = e.url.split('app.jacarandafinance.com.au').pop();
          if (slug.includes('/loans')) {
            const loandId = Number(slug.split('/')[2]);
            try {
              filterLoan(loandId);
              const overdue = loanSelected.value ? loanSelected.value[0].overdue : null;
              router.push({ name: 'Loan', params: { id: loandId } });
              if(overdue) {
                setTimeout(() => {
                  if(route.params.id && route.params.id == loandId) {
                    setModalPaymentOverdueOpen(true);
                  }
                }, 3000);
              }
            } catch (err) {
              if(err) {
                router.push({ name: 'Home' });
              }
            }
          } else if(slug.includes('/finance-fundamentals')) {
            router.push({ name: 'FinanceFundamentalsIntro' });
          } else if(slug.includes('/credit-score-register')) {
            router.push({ name: creditScoreEnrolled.value ? 'CreditScore' : 'CreditScoreRegister' });
          } else if(slug.includes('/get-started-now')) {
            router.push({ name: 'GetStartedNow' });
          } else if(slug.includes('/profile')) {
            router.push({ name: 'Profile' });
          } else if(slug.includes('/change-password')) {
            router.push({ name: 'ChangePassword' });
          } else if(slug.includes('/contact')) {
            router.push({ name: 'Contact' });
          } else {
            router.push({ name: 'Home' });
          }
        }
      }, 1500);
    });
  };

  const browserCheckSafari = () => {
    return navigator.userAgent.indexOf('Safari') > -1 && navigator.userAgent.indexOf('Chrome') <= -1;
  }

  return { openBrowser, urls, setAsyncTimeout, backButton, physicalScreenSize, checkoutFrameURLChange, checkDeepLink, browserCheckSafari };
}