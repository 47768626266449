<template>
  <div class="px-6 pt-3 max-w-xl mx-auto" v-if="showConsole">
    <h4 class="text-center text-[14px] font-bold mb-3">Debug Console</h4>
    <div class="text-[10px]">Adjust Console: {{ registered }}</div>
    <div class="text-[10px]">Idfa: {{ idfa }}</div>
    <div class="text-[10px]">Adid: {{ adId }}</div>
    <div class="text-[10px]">Google Id: {{ googleId }}</div>
    <div class="text-[10px]">Rta Status: {{ rtaStatus }}</div>
    <div class="text-[10px]">Environment (VUE_APP_ENV): {{ environment }}</div>
    <div class="text-[10px]">Is Development: {{ isDevelopmentFlag }}</div>
    <div class="text-[10px]">Is Production: {{ isProductionFlag }}</div>
    <div class="text-[10px]">
        <pre>Process Info: {{ processDump }}</pre>
    </div>

  </div>
</template>

<script setup>
import {getAdId, getIdfa, getGoogleId, getRtaStatus, getRegistered} from "@/composables/adjust";
import {computed} from "vue";
import {isProduction,isDevelopment,appEnvConfig} from "@/composables/environment";

const props = defineProps(['show']);
const adId = computed(() => {
    return getAdId()
});
const idfa = computed(() => {
    return getIdfa()
});
const googleId = computed(() => {
    return getGoogleId()
});
const rtaStatus = computed(() => {
    return getRtaStatus()
});
const registered = computed(() => {
    return getRegistered()
});
const showConsole = computed(() => {
    return props.show
});
const processDump = computed(() => {
    return process.env
});
const environment = computed(() => {
    return appEnvConfig();
})
const isDevelopmentFlag = computed(() => {
    return isDevelopment();
})
const isProductionFlag = computed(() => {
    return isProduction();
})
</script>