import { createRouter, createWebHistory } from "@ionic/vue-router";
import { useCustomerStore } from "@/stores";
import { useStatusBar } from "@/composables/statusBar";
import StartIndex from "@/views/start/StartIndex";
import LoanCalculator from "@/views/calculator/LoanCalculator";
import LogInEmail from "@/views/start/LogInEmail";
import LogInPassword from "@/views/start/LogInPassword";
import ForgotPassword from "@/views/start/ForgotPassword";
import ResetPassword from "@/views/start/ResetPassword";
import SetPIN from "@/views/start/SetPIN";
import SetBio from "@/views/start/SetBio";
import LogInPIN from "@/views/start/LogInPIN";
import LogInBio from "@/views/start/LogInBio";
import MenuTabs from "@/components/menu/MenuTabs";
import Profile from "@/views/profile/ProfileIndex";
import CloseAccount from "@/views/profile/CloseAccount";
import CloseAccountWarning from "@/views/profile/CloseAccountWarning";
import CloseAccountSuccess from "@/views/profile/CloseAccountSuccess";
import Security from "@/views/security/SecurityIndex";
import PasswordStepOne from "@/views/security/PasswordStepOne";
import PasswordStepTwo from "@/views/security/PasswordStepTwo";
import PasswordSuccess from "@/views/security/PasswordSuccess";
import Contact from "@/views/contact/ContactIndex";
import RegisterStepOne from "@/views/register/RegisterStepOne";
import RegisterStepTwo from "@/views/register/RegisterStepTwo";
import RegisterStepThree from "@/views/register/RegisterStepThree";
import RegisterStepThreeVerify from "@/views/register/RegisterStepThreeVerify";
import RegisterStepFour from "@/views/register/RegisterStepFour";
import RegisterStepFourVerify from "@/views/register/RegisterStepFourVerify";
import RegisterStepFive from "@/views/register/RegisterStepFive";
import RegisterSuccess from "@/views/register/RegisterSuccess";
import RegisterExistingAccount from "@/views/register/RegisterExistingAccount";
import LocDrawnSuccess from "@/views/loc/LocDrawnSuccess";
import LocDrawnFail from "@/views/loc/LocDrawnFail";
import CreditScore from "@/views/creditScore/CreditScoreIndex";
import CreditScoreRegister from "@/views/creditScore/CreditScoreRegister";
import CreditScoreConnect from "@/views/creditScore/CreditScoreConnect";
import CreditScoreConnectFail from "@/views/creditScore/CreditScoreConnectFail";
import CreditScoreAccommodation from "@/views/creditScore/CreditScoreAccommodation";
import CreditScoreIDVerification from "@/views/creditScore/CreditScoreIDVerification.vue";
import CreditScoreIDVerifying from "@/views/creditScore/CreditScoreIDVerifying.vue";
import CreditScoreIDVerifyFail from "@/views/creditScore/CreditScoreIDVerifyFail.vue";
import CreditScoreAbout from "@/views/creditScore/CreditScoreAbout";

const routes = [
  {
    path: "/",
    redirect: "/start",
  },
  {
    path: "/start",
    name: "Start",
    component: StartIndex,
    meta: {
      requiresAuth: false,
      statusBar: "dark",
    },
  },
  {
    path: "/calculator",
    name: "Calculator",
    component: LoanCalculator,
    meta: {
      requiresAuth: false,
      statusBar: "light",
    },
  },
  {
    path: "/login-email",
    name: "LogInEmail",
    component: LogInEmail,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/login-password",
    name: "LogInPassword",
    component: LogInPassword,
    meta: {
      requiresAuth: false,
      statusBar: "light",
    },
  },
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    component: ForgotPassword,
    meta: {
      requiresAuth: false,
      statusBar: "light",
    },
  },
  {
    path: "/reset-password",
    name: "ResetPassword",
    component: ResetPassword,
    meta: {
      requiresAuth: false,
      statusBar: "light",
    },
  },
  {
    path: "/set-pin",
    name: "SetPIN",
    component: SetPIN,
    meta: {
      requiresAuth: true,
      statusBar: "light",
    },
  },
  {
    path: "/set-bio",
    name: "SetBio",
    component: SetBio,
    meta: {
      requiresAuth: true,
      statusBar: "light",
    },
  },
  {
    path: "/login-pin",
    name: "LogInPIN",
    component: LogInPIN,
    meta: {
      requiresAuth: false,
      statusBar: "light",
    },
  },
  {
    path: "/login-bio",
    name: "LogInBio",
    component: LogInBio,
    meta: {
      requiresAuth: false,
      statusBar: "light",
    },
  },
  {
    path: "/profile",
    name: "Profile",
    component: Profile,
    meta: {
      requiresAuth: true,
      statusBar: "light",
    },
  },
  {
    path: "/close-account",
    name: "CloseAccount",
    component: CloseAccount,
    meta: {
      requiresAuth: true,
      statusBar: "light",
    },
  },
  {
    path: "/close-account-warning",
    name: "CloseAccountWarning",
    component: CloseAccountWarning,
    meta: {
      requiresAuth: true,
      statusBar: "light",
    },
  },
  {
    path: "/close-account-success",
    name: "CloseAccountSuccess",
    component: CloseAccountSuccess,
    meta: {
      requiresAuth: true,
      statusBar: "light",
    },
  },
  {
    path: "/security",
    name: "Security",
    component: Security,
    meta: {
      requiresAuth: true,
      statusBar: "light",
    },
  },
  {
    path: "/change-password",
    name: "ChangePassword",
    component: PasswordStepOne,
    meta: {
      requiresAuth: true,
      statusBar: "light",
    },
  },
  {
    path: "/change-password-new",
    name: "ChangePasswordNew",
    component: PasswordStepTwo,
    meta: {
      requiresAuth: true,
      statusBar: "light",
    },
  },
  {
    path: "/change-password-success",
    name: "ChangePasswordSuccess",
    component: PasswordSuccess,
    meta: {
      requiresAuth: true,
      statusBar: "light",
    },
  },
  {
    path: "/contact",
    name: "Contact",
    component: Contact,
    meta: {
      requiresAuth: true,
      statusBar: "light",
    },
  },
  {
    path: "/register-step-1",
    name: "RegisterStepOne",
    component: RegisterStepOne,
    meta: {
      requiresAuth: false,
      statusBar: "light",
    },
  },
  {
    path: "/register-step-2",
    name: "RegisterStepTwo",
    component: RegisterStepTwo,
    meta: {
      requiresAuth: false,
      statusBar: "light",
    },
  },
  {
    path: "/register-step-3",
    name: "RegisterStepThree",
    component: RegisterStepThree,
    meta: {
      requiresAuth: false,
      statusBar: "light",
    },
  },
  {
    path: "/register-step-3-verify",
    name: "RegisterStepThreeVerify",
    component: RegisterStepThreeVerify,
    meta: {
      requiresAuth: false,
      statusBar: "light",
    },
  },
  {
    path: "/register-step-4",
    name: "RegisterStepFour",
    component: RegisterStepFour,
    meta: {
      requiresAuth: false,
      statusBar: "light",
    },
  },
  {
    path: "/register-step-4-verify",
    name: "RegisterStepFourVerify",
    component: RegisterStepFourVerify,
    meta: {
      requiresAuth: false,
      statusBar: "light",
    },
  },
  {
    path: "/register-step-5",
    name: "RegisterStepFive",
    component: RegisterStepFive,
    meta: {
      requiresAuth: false,
      statusBar: "light",
    },
  },
  {
    path: "/register-success",
    name: "RegisterSuccess",
    component: RegisterSuccess,
    meta: {
      requiresAuth: false,
      statusBar: "light",
    },
  },
  {
    path: "/register-existing-account",
    name: "RegisterExistingAccount",
    component: RegisterExistingAccount,
    meta: {
      requiresAuth: false,
      statusBar: "light",
    },
  },
  {
    path: "/loc-drawn-success",
    name: "LocDrawnSuccess",
    component: LocDrawnSuccess,
    meta: {
      requiresAuth: true,
      statusBar: "light",
    },
  },
  {
    path: "/loc-drawn-fail",
    name: "LocDrawnFail",
    component: LocDrawnFail,
    meta: {
      requiresAuth: true,
      statusBar: "light",
    },
  },
  {
    path: "/credit-score-connect",
    name: "CreditScoreConnect",
    component: CreditScoreConnect,
    meta: {
      requiresAuth: true,
      statusBar: "light",
    },
  },
  {
    path: "/credit-score-connect-fail",
    name: "CreditScoreConnectFail",
    component: CreditScoreConnectFail,
    meta: {
      requiresAuth: true,
      statusBar: "light",
    },
  },
  {
    path: "/credit-score-id-verifying",
    name: "CreditScoreIDVerifying",
    component: CreditScoreIDVerifying,
    meta: {
      requiresAuth: true,
      statusBar: "light",
    },
  },
  {
    path: "/credit-score-id-verify-fail",
    name: "CreditScoreIDVerifyFail",
    component: CreditScoreIDVerifyFail,
    meta: {
      requiresAuth: true,
      statusBar: "light",
    },
  },
  {
    path: "/home",
    redirect: "/tabs/home",
  },
  {
    path: "/tabs/",
    name: "MenuTabs",
    component: MenuTabs,
    meta: {
      requiresAuth: true,
      statusBar: "light",
    },
    children: [
      {
        path: "",
        name: "Home",
        redirect: "/tabs/home",
        meta: {
          requiresAuth: true,
          statusBar: "dark",
        },
      },
      {
        path: "home",
        name: "Home",
        component: () => import("@/views/home/HomeIndex.vue"),
        meta: {
          requiresAuth: true,
          statusBar: "dark",
        },
      },
      {
        path: "get-started-now",
        name: "GetStartedNow",
        component: () => import("@/views/home/HomeGetStartedNow.vue"),
        meta: {
          requiresAuth: true,
          statusBar: "dark",
        },
      },
      {
        path: "loans",
        name: "Loans",
        component: () => import("@/views/loans/LoansIndex.vue"),
        meta: {
          requiresAuth: true,
          statusBar: "light",
        },
      },
      {
        path: "loans/:id",
        name: "Loan",
        component: () => import("@/views/loans/LoanIndex.vue"),
        meta: {
          requiresAuth: true,
          statusBar: "light",
        },
      },
      {
        path: "notifications",
        name: "Notifications",
        component: () => import("@/views/notifications/NotificationsIndex.vue"),
        meta: {
          requiresAuth: true,
          statusBar: "light",
        },
      },
      {
        path: "credit-score-register",
        name: "CreditScoreRegister",
        component: CreditScoreRegister,
        meta: {
          requiresAuth: true,
          statusBar: "light",
        },
      },
      {
        path: "credit-score-register/:type",
        name: "CreditScoreRegisterNew",
        component: CreditScoreRegister,
        meta: {
          requiresAuth: true,
          statusBar: "light",
        },
      },
      {
        path: "credit-score",
        name: "CreditScore",
        component: CreditScore,
        meta: {
          requiresAuth: true,
          statusBar: "light",
        },
      },
      {
        path: "credit-score-about",
        name: "CreditScoreAbout",
        component: CreditScoreAbout,
        meta: {
          requiresAuth: true,
          statusBar: "light",
        },
      },
      {
        path: "credit-score-accommodation",
        name: "CreditScoreAccommodation",
        component: CreditScoreAccommodation,
        meta: {
          requiresAuth: true,
          statusBar: "light",
        },
      },
      {
        path: "credit-score-id-verification",
        name: "CreditScoreIDVerification",
        component: CreditScoreIDVerification,
        meta: {
          requiresAuth: true,
          statusBar: "light",
        },
      },
      {
        path: "finance-fundamentals",
        name: "FinanceFundamentals",
        component: () =>
          import("@/views/financeFundamentals/FinanceFundamentalsIndex.vue"),
        meta: {
          requiresAuth: true,
          statusBar: "light",
        },
      },
      {
        path: "finance-fundamentals-intro",
        name: "FinanceFundamentalsIntro",
        component: () =>
          import("@/views/financeFundamentals/FinanceFundamentalsIntro.vue"),
        meta: {
          requiresAuth: true,
          statusBar: "dark",
        },
      },
      {
        path: "finance-fundamentals/lesson/:lessonID",
        name: "FinanceFundamentalsLesson",
        component: () =>
          import("@/views/financeFundamentals/FinanceFundamentalsLesson.vue"),
        meta: {
          requiresAuth: true,
          statusBar: "light",
        },
      },
      {
        path: "finance-fundamentals/lesson/:lessonID/quiz/:quizID/question/:questionID",
        name: "FinanceFundamentalsQuiz",
        component: () =>
          import("@/views/financeFundamentals/FinanceFundamentalsQuiz.vue"),
        meta: {
          requiresAuth: true,
          statusBar: "light",
        },
      },
      {
        path: "finance-fundamentals/lesson/:lessonID/quiz/:quizID/complete",
        name: "FinanceFundamentalsQuizComplete",
        component: () =>
          import(
            "@/views/financeFundamentals/FinanceFundamentalsQuizComplete.vue"
          ),
        meta: {
          requiresAuth: true,
          statusBar: "light",
        },
      },
      {
        path: "finance-fundamentals/lesson/:lessonID/complete",
        name: "FinanceFundamentalsLessonComplete",
        component: () =>
          import(
            "@/views/financeFundamentals/FinanceFundamentalsLessonComplete.vue"
          ),
        meta: {
          requiresAuth: true,
          statusBar: "light",
        },
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const customerStore = useCustomerStore();
  const { checkAuth } = customerStore;
  if (to.meta.requiresAuth && !checkAuth) next({ name: "Start" });
  else next();
});

router.afterEach((to) => {
  const { setStatusBarStyleDark, setStatusBarStyleLight } = useStatusBar();
  if (Capacitor.isNativePlatform()) {
    to.meta.statusBar == "dark"
      ? setStatusBarStyleDark()
      : setStatusBarStyleLight();
  }
});

export default router;
