import { defineStore } from "pinia";

export const useRegisterStore = defineStore("register", {
  state: () => ({
    title: "",
    firstName: "",
    middleName: "",
    surname: "",
    dob: "",
    email: "",
    emailVerified: false,
    phone: "",
    phoneVerified: false,
    password: "",
    passwordConfirmed: false,
    stepOneCompleted: false,
    stepTwoCompleted: false,
    stepThreeCompleted: false,
    stepThreeVerified: false,
    stepFourCompleted: false,
    stepFourVerified: false,
    stepFiveCompleted: false,
    creditScoreAccommodation: {},
    creditScoreIdVerification: {},
  }),

  getters: {
    getTitle(state) {
      return state.title;
    },

    getFirstName(state) {
      return state.firstName;
    },

    getMiddleName(state) {
      return state.middleName;
    },

    getSurname(state) {
      return state.surname;
    },

    getDOB(state) {
      return state.dob;
    },

    getEmail(state) {
      return state.email;
    },

    getEmailVerified(state) {
      return state.emailVerified;
    },

    getPhone(state) {
      return state.phone;
    },

    getPhoneVerified(state) {
      return state.phoneVerified;
    },

    getPassword(state) {
      return state.password;
    },

    getPasswordConfirmed(state) {
      return state.passwordConfirmed;
    },
  },

  actions: {
    setTitle(val) {
      this.title = val;
    },

    setFirstName(val) {
      this.firstName = val;
    },

    setMiddleName(val) {
      this.middleName = val;
    },

    setSurname(val) {
      this.surname = val;
    },

    setDOB(val) {
      this.dob = val;
    },

    setEmail(val) {
      this.email = val;
    },

    setEmailVerified(val) {
      this.emailVerified = val;
    },

    setPhone(val) {
      this.phone = val;
    },

    setPhoneVerified(val) {
      this.phoneVerified = val;
    },

    setPassword(val) {
      this.password = val;
    },

    setPasswordConfirmed(val) {
      this.passwordConfirmed = val;
    },

    setStepOneCompleted(val) {
      this.stepOneCompleted = val;
    },

    setStepTwoCompleted(val) {
      this.stepTwoCompleted = val;
    },

    setStepThreeCompleted(val) {
      this.stepThreeCompleted = val;
    },

    setStepThreeVerified(val) {
      this.stepThreeVerified = val;
    },

    setStepFourCompleted(val) {
      this.stepFourCompleted = val;
    },

    setStepFourVerified(val) {
      this.stepFourVerified = val;
    },

    setStepFiveCompleted(val) {
      this.stepFiveCompleted = val;
    },

    setCreditScoreAccommodation(val) {
      this.creditScoreAccommodation = { ...val };
    },

    setCreditScoreIdVerification(val) {
      this.creditScoreIdVerification = { ...val };
    },

    resetCreditScoreAccommodation() {
      this.creditScoreAccommodation = {};
    },

    resetCreditScoreIdVerification() {
      this.creditScoreIdVerification = {};
    },

    clearPrefill() {
      this.title = "";
      this.firstName = "";
      this.middleName = "";
      this.surname = "";
      this.dob = "";
      this.email = "";
      this.emailVerified = false;
      this.phone = "";
      this.phoneVerified = false;
      this.password = "";
      this.passwordConfirmed = false;
      this.stepOneCompleted = false;
      this.stepTwoCompleted = false;
      this.stepThreeCompleted = false;
      this.stepThreeVerified = false;
      this.stepFourCompleted = false;
      this.stepFourVerified = false;
      this.stepFiveCompleted = false;
      this.creditScoreAccommodation = {};
      this.creditScoreIdVerification = {};
    },
  },
});
