<template>
  <ion-page class="ion-page-white">
    <ion-content>
      <register-header :hideBackButton="true"></register-header>
      <div class="p-9 max-w-xl mx-auto">
        <h2 class="text-[24px] font-bold pb-9 text-center">Phone</h2>
        <register-steps step="4"></register-steps>
        <div class="ion-item-wrapper">
          <ion-item class="default" lines="none">
            <ion-label position="floating">Phone No.</ion-label>
            <ion-input type="number" v-model="phone" @input="handleInput"></ion-input>
          </ion-item>
        </div>
        <ion-note slot="helper" class="w-full text-xs px-1 my-3 ion-text-primary block" v-if="mobileError">{{
        mobileError }}</ion-note>
        <ion-text class="text-sm ion-text-grey mt-4 text-center block">Have your phone handy for the verification
          code.</ion-text>
        <button class="button-purple button-register mt-4 px-6"
          @click="btnNext('register-step-4-verify', urls.apiSignUpStepMobile)" :disabled="!entered || loading">
          <span class="block text-center" v-if="loading">
            <ion-spinner name="crescent" class="absolute -mt-3 -ml-3"></ion-spinner>
          </span>
          <span class="block capitalize text-lg font-semibold text-white" v-else>
            Next <img :src="imgNext" class="float-right ml-2 mt-2" />
          </span>
        </button>
      </div>
    </ion-content>
  </ion-page>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue';
import router from '@/router';
import RegisterHeader from '@/components/register/RegisterHeader.vue';
import RegisterSteps from '@/components/register/RegisterSteps.vue';
import { storeToRefs } from 'pinia';
import { useRegisterStore, useCustomerStore } from '@/stores';
const registerStore = useRegisterStore();
import axios from 'axios';
import { useBrowser } from '@/composables/browser';
const { urls } = useBrowser();
const { stepThreeVerified } = storeToRefs(registerStore);
const { setPhone, setStepFourCompleted, clearPrefill } = registerStore;
const customerStore = useCustomerStore();
const { deviceID, sessionID, environment } = storeToRefs(customerStore);
const { checkExistingAccount } = customerStore;
const phone = ref('');
const mobileError = ref('')
const loading = ref(false);
const entered = computed(() => phone.value !== '');
const imgNext = computed(() => entered.value ? '/assets/general/arrow-white.svg' : '/assets/general/arrow-3.svg');
const handleInput = () => {
  mobileError.value = '';
}
const btnNext = (next, url) => {
  loading.value = true;
  axios.post(url, {
    device_id: deviceID.value,
    session_id: sessionID.value,
    mobile: phone.value,
    environment: environment.value
  })
    .then((res) => {
      loading.value = false;
      if (res.status == 200) {
        setPhone(phone.value);
        setStepFourCompleted(true);
        router.push(next);
      }
    })
    .catch((err) => {
      loading.value = false;
      mobileError.value = err.response.data.message;
      checkExistingAccount(err.response.status, err.response.data.message, clearPrefill);
    });
}
onMounted(() => {
  if (!stepThreeVerified.value) {
    router.push('register-step-1')
  }
});
</script>