<template>
  <ion-menu content-id="main-content" type="overlay">
    <div class="p-8 pt-20 pr-0">
      <div class="min-h-[60px] mb-8">
        <ion-img src="/assets/logos/logo-purple.svg" class="w-[170px] fadein-100"></ion-img>
      </div>

      <div v-for="item in menuItems" :key="item.key">
        <ion-button fill="clear" class="w-full -ml-2" :class="item.class" :routerLink="item.path"
          @click="closeMenu(item.key)" v-if="!creditScoreItems(item.key)">
          <div class="flex items-center justify-start w-full menu-item">
            <div class="w-[36px]">
              <img :src="item.icon" class="pl-1 max-w-[24px] menu-item-icon" />
              <img :src="item.iconActive" class="pl-1 max-w-[24px] menu-item-icon-active" />
            </div>
            <span class="font-bold text-[14px] capitalize block">{{ item.label }}</span>
          </div>
        </ion-button>
      </div>
      <div v-if="creditScoreEnabled && creditScoreEligible">
        <ion-button fill="clear" class="w-full -ml-2" :class="menutItemsFilter(creditScoreItem).class"
          :routerLink="menutItemsFilter(creditScoreItem).path"
          @click="closeMenu(menutItemsFilter(creditScoreItem).key)">
          <div class="flex items-center justify-start w-full menu-item">
            <div class="w-[36px]">
              <img :src="menutItemsFilter(creditScoreItem).icon" class="pl-1 max-w-[24px] menu-item-icon" />
              <img :src="menutItemsFilter(creditScoreItem).iconActive"
                class="pl-1 max-w-[24px] menu-item-icon-active" />
            </div>
            <span class="font-bold text-[14px] capitalize block">{{ menutItemsFilter(creditScoreItem).label }}</span>
            <img src="/assets/menu/label-new-purple.svg" class="ml-2" />
          </div>
        </ion-button>
      </div>
      <div v-if="financeFundamentalsEnabled && financeFundamentalsCheck">
        <ion-button fill="clear" class="w-full -ml-2" :class="menutItemsFilter('finance-fundamentals').class"
          :routerLink="menutItemsFilter('finance-fundamentals').path"
          @click="closeMenu(menutItemsFilter('finance-fundamentals').key)">
          <div class="flex items-center justify-start w-full menu-item">
            <div class="w-[36px]">
              <img :src="menutItemsFilter('finance-fundamentals').icon" class="pl-1 max-w-[24px] menu-item-icon" />
              <img :src="menutItemsFilter('finance-fundamentals').iconActive"
                class="pl-1 max-w-[24px] menu-item-icon-active" />
            </div>
            <span class="font-bold text-[14px] capitalize block">
              {{ menutItemsFilter('finance-fundamentals').label }}
            </span>
            <img src="/assets/menu/label-new-purple.svg" class="ml-2" />
          </div>
        </ion-button>
      </div>

      <hr class="w-full my-6 hr-grey" />

      <ion-button fill="clear" class="w-full -ml-2 mb-3 fadein-900" routerLink="/start" @click="logOut">
        <div class="flex items-center justify-start w-full menu-item">
          <div class="w-[36px]">
            <img src="/assets/menu/icon-signout.svg" class="pl-1 max-w-[24px] menu-item-icon" />
            <img src="/assets/menu/icon-signout-active.svg" class="pl-1 max-w-[24px] menu-item-icon-active" />
          </div>
          <span class="font-bold text-[14px] capitalize block">Sign Out</span>
        </div>
      </ion-button>

      <ion-text class="flex items-center justify-between text-xs absolute w-full max-w-[80%] bottom-6 p-4"
        v-if="isNative">
        <div class="ion-text-grey">App Version: <small>{{ currentVersion }}</small></div>
        <ion-button class="ion-text-primary font-bold underline" routerLink="/start" @click="checkUpdate"
          v-if="compareVersion(installedVersion, availableVersion)">
          <small>Update</small>
        </ion-button>
      </ion-text>

    </div>
  </ion-menu>
</template>

<script setup>
import { ref, computed } from 'vue';
import { menuController } from '@ionic/vue';
import { useCustomerStore, useConfigStore } from '@/stores';
import { useBrowser } from '@/composables/browser';
import { storeToRefs } from 'pinia';
import { useSecureStorage } from '@/composables/secureStorage';
import { useMixed } from '@/composables/mixed';
import { useUpdate } from '@/composables/update';
import axios from 'axios';
const { clearAllStorage } = useSecureStorage();
const { openBrowser, urls } = useBrowser();
const customerStore = useCustomerStore();
const { updateAuth } = customerStore;
const configStore = useConfigStore();
const { deviceID, deviceName, deviceModel, pushNotificationToken, environment, authToken, currentVersion, installedVersion, availableVersion, creditScoreEligible, creditScoreEnrolled, financeFundamentals, existingCustomer } = storeToRefs(customerStore);
const { creditScoreEnabled, financeFundamentalsEnabled } = storeToRefs(configStore);
const { compareVersion } = useMixed();
const { openAppStore } = useUpdate();
const status = ref('');
const isNative = computed(() => Capacitor.isNativePlatform());
const creditScoreItems = (item) => ['credit-score-check', 'credit-score-check-new', 'credit-score-view', 'finance-fundamentals'].includes(item);
const creditScoreItem = computed(() => creditScoreEnrolled.value ? `credit-score-view` : `credit-score-check${existingCustomer.value ? '' : '-new'}`);
const financeFundamentalsCheck = computed(() => Object.keys(financeFundamentals.value).length !== 0)
const menutItemsFilter = (key) => menuItems.filter(item => item.key == key)[0];
const menuItems = [
  {
    label: 'Dashboard',
    path: '/tabs/home',
    icon: '/assets/menu/icon-dashboard.svg',
    iconActive: '/assets/menu/icon-dashboard-active.svg',
    class: 'fadein-200',
    key: 'dashboard'
  },
  {
    label: 'Your Loans',
    path: '/tabs/loans',
    icon: '/assets/menu/icon-loans.svg',
    iconActive: '/assets/menu/icon-loans-active.svg',
    class: 'fadein-300',
    key: 'loans'
  },
  {
    label: 'Profile',
    path: '/profile',
    icon: '/assets/menu/icon-profile.svg',
    iconActive: '/assets/menu/icon-profile-active.svg',
    class: 'fadein-400',
    key: 'profile'
  },
  {
    label: 'Security',
    path: '/security',
    icon: '/assets/menu/icon-security.svg',
    iconActive: '/assets/menu/icon-security-active.svg',
    class: 'fadein-500',
    key: 'security'
  },
  {
    label: 'Contact Us',
    path: '/contact',
    icon: '/assets/menu/icon-contact.svg',
    iconActive: '/assets/menu/icon-contact-active.svg',
    class: 'fadein-600',
    key: 'contact'
  },
  {
    label: 'FAQs',
    path: '/tabs/home',
    icon: '/assets/menu/icon-faq.svg',
    iconActive: '/assets/menu/icon-faq-active.svg',
    class: 'fadein-700',
    key: 'faq'
  },
  {
    label: 'Credit Score Check',
    path: '/tabs/credit-score-register',
    icon: '/assets/menu/icon-credit-score.svg',
    iconActive: '/assets/menu/icon-credit-score-active.svg',
    class: 'fadein-800 label-new-purple',
    key: 'credit-score-check'
  },
  {
    label: 'Credit Score Check',
    path: '/tabs/credit-score-register/new',
    icon: '/assets/menu/icon-credit-score.svg',
    iconActive: '/assets/menu/icon-credit-score-active.svg',
    class: 'fadein-800 label-new-purple',
    key: 'credit-score-check-new'
  },
  {
    label: 'View Credit Score',
    path: '/tabs/credit-score',
    icon: '/assets/menu/icon-credit-score.svg',
    iconActive: '/assets/menu/icon-credit-score-active.svg',
    class: 'fadein-800 label-new-purple',
    key: 'credit-score-view'
  },
  {
    label: 'Finance Fundamentals',
    path: !Array.isArray(financeFundamentals.value.enrolment) ? '/tabs/finance-fundamentals' : '/tabs/finance-fundamentals-intro',
    icon: '/assets/menu/icon-finance-fundamentals.svg',
    iconActive: '/assets/menu/icon-finance-fundamentals-active.svg',
    class: 'fadein-800 label-new-purple',
    key: 'finance-fundamentals'
  },
];

const closeMenu = (key) => {
  menuController.close();
  if (key == 'faq') {
    openBrowser(urls.faq);
  }
};

const payload = computed(() => ({
  device_id: deviceID.value,
  device_name: deviceName.value,
  device_model: deviceModel.value,
  environment: environment.value,
  push_token: pushNotificationToken.value
}));

const headers = {
  headers: {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + authToken.value,
  }
};

const signOut = () => {
  updateAuth(false);
  clearAllStorage();
  closeMenu();
};

const logOut = () => {
  if (Capacitor.isNativePlatform()) {
    axios.post(urls.apiLogOut, payload.value, headers)
      .then(res => {
        if (res.status == 200) {
          status.value = 'logged out';
          signOut();
        }
      })
      .catch(err => {
        if (err) {
          status.value = 'error';
          signOut();
        }
      });
  } else {
    signOut();
  }
};

const checkUpdate = () => {
  signOut();
  openAppStore();
};

</script>

<style scoped>
ion-button {
  --background: none !important;
  --background-activated: none !important;
  --background-activated-opacity: 1;
  --background-focused: none !important;
  --background-focused-opacity: 1;
  --background-hover: none !important;
  --background-hover-opacity: 1;
  --color: var(--ion-color-text-purple);
  --color-activated: var(--ion-color-primary);
  --color-focused: var(--ion-color-primary);
  --color-hover: var(--ion-color-primary);
  --opacity: 1 !important;
}

ion-button:focus,
ion-button:hover,
ion-button:active,
ion-button.ion-activated {
  background: none !important;
  color: var(--ion-color-primary) !important;
  opacity: 1 !important;
}

.menu-item-icon-active {
  display: none;
}

ion-button:focus .menu-item-icon,
ion-button:hover .menu-item-icon,
ion-button:active .menu-item-icon,
ion-button.ion-activated .menu-item-icon {
  display: none;
}

ion-button:focus .menu-item-icon-active,
ion-button:hover .menu-item-icon-active,
ion-button:active .menu-item-icon-active,
ion-button.ion-activated .menu-item-icon-active {
  display: block;
}
</style>