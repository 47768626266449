<template>
  <ion-grid>
    <ion-row>
      <ion-col>
        <credit-score-notification type="danger" v-if="!creditScoreEligible"></credit-score-notification>
        <credit-score-notification-pending type="warning"
          v-if="creditScoreEligible && !creditScore.status"></credit-score-notification-pending>
        <div
          class="fadein-100 credit-score-details text-center overflow-hidden -mt-[20px] pt-6 max-w-xl mx-auto min-h-[200px]">
          <credit-score-progress-bar :creditScore="creditScore" size="full"></credit-score-progress-bar>
          <div class="credit-score-details-text text-center -mt-[80px]" v-if="hasCurrentCreditScore">
            <ion-text class="text-[60px] font-bold block leading-7 mb-3"
              :class="'ion-text-' + setCreditScoreChange(creditScore.score_change).style">
              {{ creditScore.current_score }}
            </ion-text>
            <ion-text class="text-md ion-text-brand">Out of 1200</ion-text>
          </div>
          <div class="text-center -mt-[110px]" v-else>
            <ion-icon src="/assets/credit-score/icon-lock.svg" class="w-[80px] h-[80px] block mx-auto mb-2"></ion-icon>
            <ion-text class="text-md ion-text-brand">Temporarily Locked</ion-text>
          </div>
        </div>
      </ion-col>
    </ion-row>
    <ion-row class="mb-1 max-w-[320px] mx-auto">
      <ion-col size="1" class="text-left text-xs ion-text-brand pl-4">0</ion-col>
      <ion-col class="text-right ion-text-brand pr-2">
        <ion-text class="text-xs ion-text-grey-2 mb-1 block">Score Change</ion-text>
        <ion-text class="font-bold ion-text-brand"
          :class="'ion-text-' + setCreditScoreChange(creditScore.score_change).style" v-if="creditScore.score_change">
          {{ setCreditScoreChange(creditScore.score_change).label }}
        </ion-text>
        <ion-text class="text-sm ion-text-grey-2 mt-1 block" v-else>---</ion-text>
      </ion-col>
      <ion-col class="text-left ion-text-brand pl-2">
        <ion-text class="text-xs ion-text-grey-2 mb-1 block">Last Updated</ion-text>
        <ion-text class="font-bold ion-text-brand" v-if="creditScore.current_score_updated_at">
          {{ formatCreditScoreDate(creditScore.current_score_updated_at) }}</ion-text>
        <ion-text class="text-sm ion-text-grey-2 mt-1 block" v-else>---</ion-text>
      </ion-col>
      <ion-col size="1" class="min-w-[40px] text-right text-xs ion-text-brand pr-1">1200</ion-col>
    </ion-row>
  </ion-grid>
</template>

<script setup>
import { storeToRefs } from 'pinia';
import { useCustomerStore } from '@/stores';
import { useMixed } from '@/composables/mixed';
import CreditScoreProgressBar from '@/components/creditScore/CreditScoreProgressBar.vue';
import CreditScoreNotification from '@/components/creditScore/CreditScoreNotification.vue';
import CreditScoreNotificationPending from '@/components/creditScore/CreditScoreNotificationPending.vue';
const { formatCreditScoreDate } = useMixed();
const customerStore = useCustomerStore();
const { creditScore, creditScoreEligible, hasCurrentCreditScore } = storeToRefs(customerStore);
const { setCreditScoreChange } = customerStore;
</script>