import { nextTick } from "vue";
import {
  format,
  intervalToDuration,
  parse,
  differenceInDays,
  addMonths,
  getYear,
} from "date-fns";
import { useBrowser } from "@/composables/browser";
import {
  useCustomerStore,
  useConfigStore,
  useNotificationsStore,
  useLoansStore,
  useApplicationsStore,
} from "@/stores";
import axios from "axios";
import { addCustomerIdToSession } from "@/composables/adjust";
import { asyncComputed } from "@vueuse/core";
import { useSecureStorage } from "@/composables/secureStorage";
import { storeToRefs } from "pinia";

export function useMixed() {
  const formatDate = (d) => {
    return format(new Date(d), "dd MMM yyyy");
  };

  const formatMonthDay = (d) => {
    return format(new Date(d), "MMM dd");
  };

  const formatMonthYear = (d) => {
    return format(new Date(d), "MMM yy");
  };

  const formatAddMonthYear = (d, i) => {
    return format(addMonths(new Date(d), i), "yyyy-MM-dd");
  };

  const formatToday = () => {
    return format(new Date(), "dd MMM yyyy");
  };

  const formatDOB = (d) => {
    return format(new Date(d), "yyyy-MM-dd");
  };

  const formatCustomDOB = (d) => {
    return format(new Date(d), "d MMMM yyyy");
  };

  const formatExpiryDate = (d) => {
    return format(new Date(d), "yyyy-MM");
  };

  const formatCustomExpiryDate = (d) => {
    return d ? format(new Date(d), "MMMM yyyy") : null;
  };

  const formatCreditScoreDate = (d) => {
    return format(new Date(d), "dd/MM/yy");
  };

  const getExpiryYears = () => {
    return [...Array(10)].map((_, i) => getYear(new Date()) + i).toString();
  };

  const calculateFullAge = (dob) => {
    const birthDate = parse(dob, "yyyy-MM-dd", new Date());
    const { years, months, days } = intervalToDuration({
      start: birthDate,
      end: new Date(),
    });
    return { years, months, days };
  };

  const calculateDateDistance = (d) => {
    return differenceInDays(new Date(), new Date(d)) < 7;
  };

  const formatCurrency = (n) => {
    return (
      "$" +
      Number(n)
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    );
  };

  const formatName = (str) => {
    let name = String(str).replace(/\s+/g, "").toLowerCase();
    return name.charAt(0).toUpperCase() + name.slice(1);
  };

  const formatStatusName = (prefix, name) => {
    return name ? prefix + name.replace(/\s+/g, "-").toLowerCase() : "";
  };

  const loadProgress = (item, total, amount = 10, time = 100) => {
    setTimeout(() => {
      const interval = setInterval(() => {
        item.value += amount;
        if (item.value > total) {
          clearInterval(interval);
        }
      }, time);
    }, 500);
  };

  const calculateProgress = (n) => Math.ceil(n * 180);

  const calculateDateDue = (d) =>
    intervalToDuration({
      start: new Date().setHours(0, 0, 0, 0),
      end: new Date(d).setHours(23, 59, 59, 999),
    });

  const isNumeric = (num) =>
    (typeof num === "number" ||
      (typeof num === "string" && num.trim() !== "")) &&
    !isNaN(num);

  const compareVersion = (oldVersion, newVersion) => {
    const oldParts = oldVersion.split(".");
    const newParts = newVersion.split(".");
    for (var i = 0; i < newParts.length; i++) {
      const a = ~~newParts[i];
      const b = ~~oldParts[i];
      if (a > b) {
        return true;
      }
      if (a < b) {
        return false;
      }
    }
    return false;
  };

  const truncateString = (str, num = 32) => {
    if (str.length > num) {
      return str.slice(0, num) + "...";
    } else {
      return str;
    }
  };

  const scrollToBottom = (id) => {
    nextTick(() => {
      setTimeout(() => {
        if (document.getElementById(id) !== null) {
          document.getElementById(id).scrollIntoView({ behavior: "smooth" });
        }
      }, 100);
    });
  };

  const checkVideoUrl = (url) => {
    const regex = new RegExp(
      /^.*(youtu\.be\/|vi?\/|u\/\w\/|embed\/|\?vi?=|\vi?=)([^#]*).*/
    );
    return {
      valid: regex.test(url),
      videoId: url.match(regex)[2],
    };
  };

  const checkVideoThumbnail = (id) => {
    const ytimg = `https://i.ytimg.com/vi/${id}/`;
    return {
      default: ytimg + 'default.jpg"',
      medium: ytimg + "mqdefault.jpg",
      high: ytimg + "hqdefault.jpg",
      standard: ytimg + "sddefault.jpg",
      max: ytimg + "maxresdefault.jpg",
    };
  };

  const refreshCustomer = () => {
    const { urls } = useBrowser();
    const customerStore = useCustomerStore();
    const configStore = useConfigStore();
    const loansStore = useLoansStore();
    const applicationsStore = useApplicationsStore();
    const notificationsStore = useNotificationsStore();
    const {
      setProfile,
      setName,
      setEnvironment,
      setAuthToken,
      getFinanceFundamentals,
    } = customerStore;
    const {
      currentVersion,
      authToken,
      pushNotificationToken,
      deviceID,
      deviceName,
      deviceModel,
    } = storeToRefs(customerStore);
    const { keys, getStorage } = useSecureStorage();
    const proStorage = asyncComputed(
      async () => await getStorage({ key: keys.pro }),
      null
    );
    const { setConfig } = configStore;
    const { setNotifications } = notificationsStore;
    const { setLoans } = loansStore;
    const { setPendingApplication } = applicationsStore;
    const headers = (token) => {
      return {
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
      };
    };
    setTimeout(() => {
      if (proStorage.value) {
        let urlParams =
          "?environment=" +
          { ...JSON.parse(proStorage.value.value) }.env +
          "&app_version=" +
          currentVersion.value +
          "&push_token=" +
          pushNotificationToken.value +
          "&device_id=" +
          deviceID.value +
          "&device_name=" +
          deviceName.value +
          "&device_model=" +
          deviceModel.value;

        axios
          .get(
            urls.apiCustomer + urlParams,
            headers({ ...JSON.parse(proStorage.value.value) }.token)
          )
          .then((res) => {
            setProfile(res.data.customer);
            setName(res.data.customer.first_name);
            setAuthToken({ ...JSON.parse(proStorage.value.value) }.token);
            setEnvironment({ ...JSON.parse(proStorage.value.value) }.env);
            setConfig(res.data.config);
            setNotifications(res.data.customer.notifications);
            setLoans(res.data.customer.loans);
            setPendingApplication(res.data.customer.pending_application);
            addCustomerIdToSession(res.data.customer.id);
          })
          .then(() => {
            getFinanceFundamentals(
              urls.apiFinanceFundamentals + urlParams,
              authToken.value
            );
          });
      }
    }, 1000);
  };

  return {
    formatCurrency,
    formatDate,
    formatMonthDay,
    formatMonthYear,
    formatAddMonthYear,
    formatToday,
    formatDOB,
    formatCustomDOB,
    formatExpiryDate,
    formatCreditScoreDate,
    formatCustomExpiryDate,
    getExpiryYears,
    formatName,
    formatStatusName,
    loadProgress,
    calculateProgress,
    calculateFullAge,
    calculateDateDistance,
    calculateDateDue,
    isNumeric,
    compareVersion,
    truncateString,
    scrollToBottom,
    checkVideoUrl,
    checkVideoThumbnail,
    refreshCustomer,
  };
}
