<template>
  <ion-card class="bg-white m-0 p-0 my-4 rounded-xl credit-score-card">
    <ion-card-content class="bg-white-500 p-3 pb-0">
      <ion-grid>
        <ion-row class="mb-2" @click="toggleVisible">
          <ion-col class="py-0" size="1">
            <ion-icon src="/assets/credit-score/icon-info.svg" class="w-[16px] h-[16px] pt-1"></ion-icon>
          </ion-col>
          <ion-col class="py-0 text-center">
            <ion-text class="ion-text-purple block font-bold text-sm">About Equifax & Credit Scores</ion-text>
          </ion-col>
          <ion-col class="py-0 text-right" size="1">
            <ion-icon src="/assets/general/arrow-grey.svg" class="w-[15px] pt-1 button-toggle"
              :class="visible ? 'button-toggle' : 'button-toggle-up'"></ion-icon>
          </ion-col>
        </ion-row>
        <ion-row class="mx-2 fadein-300" v-if="visible">
          <ion-col class="text-center">
            <ion-text class="text-xs block ion-text-grey-2">Your Credit score is provided by</ion-text>
            <ion-icon src="/assets/logos/logo-equifax.svg" class="w-[180px] h-[50px]"></ion-icon>
          </ion-col>
        </ion-row>
        <ion-row class="mx-2 mb-3 fadein-300" v-if="visible">
          <ion-col>
            <button expand="block" color="secondary" class="button-purple button-purple-lg button-shadow"
              @click="nextStep">
              <span class="block text-white text-[14px] font-semibold">About Your Credit Report</span>
            </button>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-card-content>
  </ion-card>
</template>

<script setup>
import { ref } from 'vue';
import router from '@/router';
const visible = ref(true);
const toggleVisible = () => {
  visible.value = !visible.value;
}
const nextStep = () => {
  router.push({ name: 'CreditScoreAbout' });
}
</script>