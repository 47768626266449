<template>
  <ion-page class="ion-page-white">
    <credit-score-header title="Your Credit Score" :subtitle="`By <strong>Equifax</strong>`"
      :status="creditScore.status"></credit-score-header>
    <ion-content>
      <refresh-content></refresh-content>
      <div class="px-7 py-6 max-w-xl mx-auto">
        <credit-score-details></credit-score-details>
        <credit-score-history></credit-score-history>
        <credit-score-section-improve class="mt-6"></credit-score-section-improve>
        <credit-score-section-about class="mt-6"></credit-score-section-about>
      </div>
    </ion-content>
  </ion-page>
</template>

<script setup>
import { storeToRefs } from 'pinia';
import { useCustomerStore } from '@/stores';
import RefreshContent from '@/components/menu/RefreshContent.vue';
import CreditScoreHeader from '@/components/creditScore/CreditScoreHeader.vue';
import CreditScoreDetails from '@/components/creditScore/CreditScoreDetails.vue';
import CreditScoreHistory from '@/components/creditScore/CreditScoreHistory.vue';
import CreditScoreSectionImprove from '@/components/creditScore/CreditScoreSectionImprove.vue';
import CreditScoreSectionAbout from '@/components/creditScore/CreditScoreSectionAbout.vue';
const customerStore = useCustomerStore();
const { creditScore } = storeToRefs(customerStore);
</script>