<template>
  <div v-if="!profile.id">
    <ion-text class="text-md font-bold ion-text-brand">Customer</ion-text>
    <ion-card class="m-0 p-0 my-3 rounded-xl bg-white">
      <ion-card-content class="p-0">
        <ion-grid class="p-0 m-0">
          <ion-row class="pt-3 pb-2 px-6 border-b">
            <ion-col>
              <ion-text class="mb-1 block text-sm ion-text-brand font-bold">Full Name</ion-text>
              <ion-text class="text-sm mb-1 block ion-text-grey-2">{{ firstName + ' ' + surname }}</ion-text>
            </ion-col>
          </ion-row>
          <ion-row class="pt-3 pb-2 px-6">
            <ion-col>
              <ion-text class="mb-1 block text-sm ion-text-brand font-bold">Date Of Birth</ion-text>
              <ion-text class="text-sm mb-1 block ion-text-grey-2">{{ dob }}</ion-text>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-card-content>
    </ion-card>
    <hr class="my-6 hr-grey" />
    <ion-text class="text-md font-bold ion-text-brand block">Contact Details</ion-text>
    <ion-card class="m-0 p-0 my-3 rounded-xl bg-white">
      <ion-card-content class="p-0">
        <ion-grid class="p-0 m-0">
          <ion-row class="pt-3 pb-2 px-6 border-b">
            <ion-col>
              <ion-text class="mb-1 block text-sm ion-text-brand font-bold">Email Address</ion-text>
              <ion-text class="text-sm mb-1 block ion-text-grey-2">{{ email }}</ion-text>
            </ion-col>
          </ion-row>
          <ion-row class="pt-3 pb-2 px-6 border-b">
            <ion-col>
              <ion-text class="mb-1 block text-sm ion-text-brand font-bold">Phone Number</ion-text>
              <ion-text class="text-sm mb-1 block ion-text-grey-2">{{ phone }}</ion-text>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-card-content>
    </ion-card>
  </div>
  <div v-else>
    <ion-text class="text-md font-bold ion-text-brand">Customer ID</ion-text>
    <ion-card class="m-0 p-0 my-3 rounded-xl bg-white">
      <ion-card-content class="p-0">
        <ion-grid class="p-0 m-0">
          <ion-row class="pt-3 pb-2 px-6 border-b">
            <ion-col>
              <ion-text class="mb-1 block text-sm ion-text-brand font-bold">Customer ID</ion-text>
              <ion-text class="text-sm mb-1 block ion-text-grey-2">#{{ profile.id }}</ion-text>
            </ion-col>
          </ion-row>
          <ion-row class="pt-3 pb-2 px-6 border-b">
            <ion-col>
              <ion-text class="mb-1 block text-sm ion-text-brand font-bold">Full Name</ion-text>
              <ion-text class="text-sm mb-1 block ion-text-grey-2">{{ profile.full_name }}</ion-text>
            </ion-col>
          </ion-row>
          <ion-row class="pt-3 pb-2 px-6">
            <ion-col>
              <ion-text class="mb-1 block text-sm ion-text-brand font-bold">Date Of Birth</ion-text>
              <ion-text class="text-sm mb-1 block ion-text-grey-2">{{ formatDate(profile.date_of_birth) }}</ion-text>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-card-content>
    </ion-card>
    <hr class="my-6 hr-grey" />
    <ion-text class="text-md font-bold ion-text-brand block">Contact Details</ion-text>
    <ion-card class="m-0 p-0 my-3 rounded-xl bg-white">
      <ion-card-content class="p-0">
        <ion-grid class="p-0 m-0">
          <ion-row class="pt-3 pb-2 px-6 border-b">
            <ion-col>
              <ion-text class="mb-1 block text-sm ion-text-brand font-bold">Email Address</ion-text>
              <ion-text class="text-sm mb-1 block ion-text-grey-2">{{ profile.email_address }}</ion-text>
            </ion-col>
          </ion-row>
          <ion-row class="pt-3 pb-2 px-6 border-b">
            <ion-col>
              <ion-text class="mb-1 block text-sm ion-text-brand font-bold">Phone Number</ion-text>
              <ion-text class="text-sm mb-1 block ion-text-grey-2">{{ profile.mobile_phone }}</ion-text>
            </ion-col>
          </ion-row>
          <ion-row class="pt-3 pb-2 px-6 border-b" v-if="profile.residential_address">
            <ion-col>
              <ion-text class="mb-1 block text-sm ion-text-brand font-bold">Residential Address</ion-text>
              <ion-text class="text-sm mb-1 block ion-text-grey-2">{{ profile.residential_address }}</ion-text>
            </ion-col>
          </ion-row>
          <ion-row class="pt-3 pb-2 px-6" v-if="profile.postal_address">
            <ion-col>
              <ion-text class="mb-1 block text-sm ion-text-brand font-bold">Postal Address</ion-text>
              <ion-text class="text-sm mb-1 block ion-text-grey-2">{{ profile.postal_address }}</ion-text>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-card-content>
    </ion-card>
  </div>
</template>

<script setup>
import { storeToRefs } from 'pinia';
import { useRegisterStore, useCustomerStore } from '@/stores';
import { useMixed } from '@/composables/mixed';
const customerStore = useCustomerStore();
const { profile } = storeToRefs(customerStore);
const registerStore = useRegisterStore();
const { firstName, surname, dob, email, phone } = storeToRefs(registerStore);
const { formatDate } = useMixed();
</script>