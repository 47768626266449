import { Adjust, AdjustConfig, AdjustEnvironment, AdjustLogLevel, AdjustEvent } from '@awesome-cordova-plugins/adjust';
import { isPlatform } from '@ionic/vue';
import { Capacitor } from "@capacitor/core";
import { isProduction } from "@/composables/environment";

const state = {
    registered: false,
    adId: null,
    idfa: null,
    googleId: null,
    rtaStatus: null,
};

export function getDeviceInfo()
{
    if (state.idfa) {
        return {
            'device_id': state.idfa,
            'device_type': 'idfa'
        }
    }

    if (state.googleId) {
        return {
            'device_id': state.googleId,
            'device_type': 'gps_adid'
        }
    }

    if (state.adId) {
        return {
            'device_id': state.adId,
            'device_type': 'adid',
        }
    }

    return {
        'device_id': null,
        'device_type': null
    }
}

export function applyDeviceParamsToUrl(url, source) {
    var deviceInfo = getDeviceInfo();

    url = url + (url.includes("?") ? '&' : '?') + "source=" + getSource(source)

    if (!deviceInfo.device_id) {
        return url;
    }

    return url + (url.includes("?") ? '&' : '?') + 'adjust_device_id=' + deviceInfo.device_id + '&adjust_device_type=' + deviceInfo.device_type;
}

function getSource(sourceSuffix)
{
    var source = 'APP-WEB'
    if(Capacitor.isNativePlatform()) {
        if (isPlatform('ios')) {
            source = 'APP-IOS'
        } else if (isPlatform('android')) {
            source = 'APP-AND'
        } else {
            source = 'APP-NAT'
        }
    }

    if (sourceSuffix) {
        return source +'-'+ sourceSuffix
    }

    return source
}

export function getRegistered()
{
    return state.registered
}

export function getAdId()
{
    return state.adId
}

export function getIdfa()
{
    return state.idfa
}

export function getGoogleId()
{
    return state.googleId
}

export function getRtaStatus()
{
    return state.rtaStatus
}

export function addCustomerIdToSession(customerId)
{
    addEventSessionParameter('customer_id', customerId)
}

export function addEventSessionParameter(key, value)
{
    if (state.registered) {
        Adjust.addSessionCallbackParameter(key, value.toString());
    }
}

export function registerAdjust() {
    console.log('*************************************')
    console.log('!!!!!!!! REGISTRATION CHECK !!!!!!!!!')
    console.log('*************************************')

    if(Capacitor.isNativePlatform()) {
        if (isPlatform('ios')) {
            console.log('*************************************')
            console.log('!!!!!!!!!!!!!! IS IOS !!!!!!!!!!!!!!')
            console.log('*************************************')
            Adjust.requestTrackingAuthorizationWithCompletionHandler(function(status) {
                state.rtaStatus = status
                switch (status) {
                    case 0:
                        console.log("The user hasn't been asked yet")
                        // ATTrackingManagerAuthorizationStatusNotDetermined case
                        break;
                    case 1:
                        console.log("The user device is restricted")
                        // ATTrackingManagerAuthorizationStatusRestricted case
                        break;
                    case 2:
                        console.log("The user denied access to IDFA")
                        // ATTrackingManagerAuthorizationStatusDenied case
                        break;
                    case 3:
                        console.log("The user authorized access to IDFA")
                        // ATTrackingManagerAuthorizationStatusAuthorized case
                        register()
                        break;
                    default:
                        console.log("The status is not available +"+status)
                        break;
                }
            });
            return
        }

        register()
    }

    // Must be web
}

function register()
{
    const adjustConfig = new AdjustConfig('ja9sjy4u0qgw', isProduction() ? AdjustEnvironment.Production : AdjustEnvironment.Sandbox);
    adjustConfig.setLogLevel(isProduction() ? AdjustLogLevel.Error : AdjustLogLevel.Verbose);
    adjustConfig.setSendInBackground(true);

    Adjust.create(adjustConfig);

    state.registered = true

    getAdids()
    trackEvent(AdjustEvents.APP_OPENED)
}

export function setPushToken(token)
{
    if (state.registered) {
        Adjust.setPushToken(token);
    }
}

export function getAdids()
{
    if (state.registered) {
        Adjust.getIdfa((idfa) => {
            state.idfa = idfa
        });
        Adjust.getAdid((adId) => {
            state.adId = adId
        });
        Adjust.getGoogleAdId((googleId) => {
            state.googleId = googleId
        });
    }
}

export function trackEvent(event) {
    if (state.registered) {
        var adjustEvent = new AdjustEvent(event);
        Adjust.trackEvent(adjustEvent);
    }
}

export const AdjustEvents = {
    APP_OPENED: 'wnkcah',
    CLICK_APPLY: '3c77qt',
    CLICK_LOGIN: '2tiaej',
    CLICK_GET_STARTED: '6tq2e1',
    LOGIN_FAILED: 'e63upy',
    LOGIN_SUCCESSFUL: '7b14j4',
    REGISTRATION_COMPLETED: 'kshewm',
    REGISTRATION_STARTED: '8a6sj6',
}