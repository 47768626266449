import { AppUpdate } from '@capawesome/capacitor-app-update';
import { App as CapacitorApp } from '@capacitor/app';

export function useUpdate() {
  const getCurrentAppVersion = async () => {
    const result = await CapacitorApp.getInfo();
    return result.version;
  };

  const getInstalledAppVersion = async () => {
    const result = await AppUpdate.getAppUpdateInfo({ country: 'au' });
    return result.currentVersion;
  };


  const getAvailableAppVersion = async () => {
    const result = await AppUpdate.getAppUpdateInfo({ country: 'au' });
    return result.availableVersion;
  };

  const openAppStore = async () => {
    await AppUpdate.openAppStore({ country: 'au' });
  };

  const performImmediateUpdate = async () => {
    const result = await AppUpdate.getAppUpdateInfo();
    if (result.updateAvailability !== AppUpdateAvailability.UPDATE_AVAILABLE) {
      return;
    }
    if (result.immediateUpdateAllowed) {
      await AppUpdate.performImmediateUpdate();
    }
  };

  const startFlexibleUpdate = async () => {
    const result = await AppUpdate.getAppUpdateInfo();
    if (result.updateAvailability !== AppUpdateAvailability.UPDATE_AVAILABLE) {
      return;
    }
    if (result.flexibleUpdateAllowed) {
      await AppUpdate.startFlexibleUpdate();
    }
  };

  const completeFlexibleUpdate = async () => {
    await AppUpdate.completeFlexibleUpdate();
  };

  return { getCurrentAppVersion, getInstalledAppVersion, getAvailableAppVersion, openAppStore, performImmediateUpdate, startFlexibleUpdate, completeFlexibleUpdate };
}

