import { defineStore } from "pinia";
import { useLoansStore, useCustomerStore } from "@/stores";
import { storeToRefs } from "pinia";
import router from "@/router";
import axios from "axios";
import { useBrowser } from "@/composables/browser";

export const useNotificationsStore = defineStore("notifications", {
  state: () => ({
    notifications: [],
    notificationsLoading: false,
    notificationsStatus: "",
    sortedBy: "read",
  }),

  getters: {
    getNotifications(state) {
      return state.notifications;
    },

    getNotificationsNew(state) {
      return state.notifications.filter((notification) => !notification.read);
    },

    countNotifications(state) {
      return state.notifications.filter((notification) => !notification.read)
        .length;
    },

    getSortedBy(state) {
      return state.sortedBy;
    },
  },

  actions: {
    setNotifications(data) {
      let dataFiltered = data.map((item) => ({
        ...item,
        show:
          item.loan_id && item.key == "overdue"
            ? this.filterNotificationsOverdue(data, item.loan_id, item.id)
            : true,
      }));
      let dataSanitized = dataFiltered.filter((item) => item.show);
      this.notifications = [...dataSanitized];
      this.sortNotification(this.sortedBy);
    },

    sortNotification(val) {
      if (val == "read") {
        this.notifications = [
          ...this.notifications.filter((item) => !item[val]),
          ...this.notifications.filter((item) => item[val]),
        ];
      } else if (val == "date") {
        this.notifications = this.notifications.sort(
          (a, b) => new Date(b[val]) - new Date(a[val])
        );
      } else {
        this.notifications = this.notifications.sort((a, b) => {
          if (a[val] < b[val]) {
            return -1;
          }
          if (a[val] > b[val]) {
            return 1;
          }
          return 0;
        });
      }
    },

    filterNotificationsByType(val) {
      return this.notifications.filter(
        (notification) => notification.type == val
      );
    },

    filterNotificationsByLoan(id) {
      return this.notifications.filter(
        (notification) => notification.loan_id == id
      );
    },

    filterNotificationsOverdue(data, loanId, id) {
      let final =
        data.filter(
          (item) => item.loan_id == loanId && item.key == "final_notice"
        ).length > 0;
      let overdue = data
        .filter((item) => item.loan_id == loanId && item.key == "overdue")
        .sort((a, b) => new Date(b.date) - new Date(a.date));
      return final ? false : overdue[0].id == id ? true : false;
    },

    setSortedBy(val) {
      this.sortedBy = val;
    },

    clearNotifications() {
      this.notifications = [];
    },

    updateNotification(notification) {
      const loansStore = useLoansStore();
      const { filterLoan, setModalPaymentOverdueOpen } = loansStore;
      const customerStore = useCustomerStore();
      const { openApplyUrl } = customerStore;
      this.readNotification(notification);
      if (notification.action == "direct_to_contact_us") {
        router.push({ name: "Contact" });
      }
      if (notification.action == "direct_to_apply") {
        openApplyUrl();
      }
      if (notification.action == "direct_to_loan") {
        filterLoan(notification.loan_id);
        router.push({ name: "Loan", params: { id: notification.loan_id } });
        if (["overdue", "final_notice"].includes(notification.key)) {
          setModalPaymentOverdueOpen(true);
        }
      }
    },

    readNotification(notification) {
      const customerStore = useCustomerStore();
      const { deviceID, environment, authToken } = storeToRefs(customerStore);
      const { urls } = useBrowser();
      const headers = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + authToken.value,
        },
      };
      const payload = {
        device_id: deviceID.value,
        environment: environment.value,
        notification_id: notification.id,
      };
      if (!notification.read) {
        this.notificationsLoading = true;
        axios
          .post(urls.apiNotifications, payload, headers)
          .then((response) => {
            if (response == 200) {
              this.notificationsStatus = "success";
            }
          })
          .then(() => {
            this.refreshNotifications();
          })
          .then(() => {
            this.notificationsLoading = false;
          })
          .catch((err) => {
            if (err) {
              this.notificationsStatus = "error";
              this.notificationsLoading = false;
              return;
            }
          });
      }
    },

    refreshNotifications() {
      const { urls } = useBrowser();
      const customerStore = useCustomerStore();
      const { getCustomerParams, authToken } = storeToRefs(customerStore);
      axios
        .get(urls.apiCustomer + getCustomerParams.value, {
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + authToken.value,
          },
        })
        .then((res) => {
          this.setNotifications(res.data.customer.notifications);
        })
        .catch((err) => {
          if (err) {
            this.notificationsLoading = false;
            return;
          }
        });
    },

    setNotificationLabel(notification, statusNew) {
      if (notification.action == "none") {
        if (statusNew) {
          return "View";
        } else {
          return "Read";
        }
      } else if (notification.action == "direct_to_contact_us") {
        return "Contact Us";
      } else if (notification.action == "direct_to_apply") {
        return "Apply Now";
      } else if (notification.action == "direct_to_loan") {
        if (["overdue", "final_notice"].includes(notification.key)) {
          return "Pay Now";
        } else {
          return "View";
        }
      } else {
        return notification.action;
      }
    },
  },
});
