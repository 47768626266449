<template>
  <ion-page class="ion-page-white">
    <ion-content>
      <start-header></start-header>
      <div class="p-9 max-w-xl mx-auto">
        <h2 class="text-[22px] font-bold pt-6 pb-4 text-center">Welcome Back <span class="ion-text-primary">{{ name
            }}</span>
        </h2>
        <p class="text-sm pb-9 text-center">Enter PIN Code</p>
        <div class="mb-9 otp-wrapper" :class="completed && !match ? 'shake' : ''">
          <v-otp-input
            input-classes="otp-input border-none rounded-full text-3xl text-center font-bold h-[70px] w-[70px] mx-1 bg-[#EEECF9]"
            :class="!match && count >= 3 ? 'opacity-30' : ''" ref="otpInput" separator="" :num-inputs="4"
            :should-auto-focus="true" :is-input-num="true" @on-change="otpChange" @on-complete="otpComplete"
            input-type="password" :is-disabled="!match && count >= 3 ? true : false" />
        </div>

        <div v-if="completed">
          <div v-if="!match && count >= 3">
            <div class="text-center text-base mb-6 square">
              <button fill="clear">
                <span class="ion-text-grey">Forgot Pin Code?</span>
              </button>
            </div>

            <div class="text-center text-base mb-3 square">
              <button fill="clear" @click="$router.push('login-email')">
                <span class="ion-text-primary font-bold underline">Login with Password</span>
              </button>
            </div>
          </div>

          <div class="text-center text-base mb-3 square" v-if="match">
            <button fill="clear" @click="logIn">
              <span class="ion-text-primary font-bold underline">Login</span>
            </button>
          </div>
        </div>

      </div>
    </ion-content>
  </ion-page>
</template>

<script setup>
import { ref, computed, onMounted, nextTick } from 'vue';
import router from '@/router';
import { asyncComputed } from '@vueuse/core';
import VOtpInput from 'vue3-otp-input';
import StartHeader from '@/components/start/StartHeader.vue';
import { useSecureStorage } from '@/composables/secureStorage';
import { storeToRefs } from 'pinia';
import { useCustomerStore } from '@/stores';
import { AdjustEvents, trackEvent } from "@/composables/adjust";
import { useMixed } from '@/composables/mixed';
const { refreshCustomer } = useMixed();
const customerStore = useCustomerStore();
const { updateAuth } = customerStore;
const { name } = storeToRefs(customerStore);
const { keys, getStorage } = useSecureStorage();
const otpInput = ref(null);
const otpEntered = ref(null);
const otpStorage = asyncComputed(async () => await getStorage({ key: keys.otp }), null);
const count = ref(0);
const completed = ref(false);
const match = computed(() => {
  if (otpStorage.value && { ...otpStorage.value }.value == otpEntered.value) {
    trackEvent(AdjustEvents.LOGIN_SUCCESSFUL);
    return true;
  }
  return false;
});
const otpChange = (v) => {
  completed.value = false;
  otpEntered.value = v;
};
const otpComplete = (v) => {
  completed.value = true;
  otpEntered.value = v;
  if (completed.value && !match.value) {
    count.value++;
  }
};
const logIn = () => {
  refreshCustomer();
  updateAuth(true);
  setTimeout(() => {
    router.push('home');
  }, 3000);
}
onMounted(() => {
  trackEvent(AdjustEvents.CLICK_LOGIN);
  nextTick(() => {
    setTimeout(() => {
      if (document.querySelector('.otp-input') !== null) {
        document.querySelector('.otp-input').focus();
      }
    }, 1000);
  });
})

</script>

<style scoped>
.otp-wrapper>div {
  justify-content: space-between !important;
}

.otp-input {
  visibility: hidden !important;
}
</style>