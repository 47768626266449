<template>
  <div class="px-7 py-6 max-w-xl mx-auto fadein-600">
    <credit-score-retry-msg v-if="idCheckErrRetryCount == 1 || errors"></credit-score-retry-msg>
    <div>
      <h4 class="text-sm font-semibold">What is the address where you currently live?</h4>
      <p class="text-xs mt-2">To qualify, you must have a fixed residential address.</p>
      <credit-score-address type="residential" v-model:unitNumber="address.residential.unitNumber"
        v-model:streetNumber="address.residential.streetNumber" v-model:streetName="address.residential.streetName"
        v-model:streetType="address.residential.streetType" v-model:suburb="address.residential.suburb"
        v-model:state="address.residential.state" v-model:postcode="address.residential.postcode"
        @updateAddress="updateAddress" :errors="errors"></credit-score-address>
    </div>
    <div class="pb-12">
      <h4 class="text-sm font-semibold mt-8">How long have you lived at this address?</h4>
      <div class="flex mt-4">
        <button fill="outline" class="mr-2"
          :class="addressPreOptOneActive ? 'button-purple button-purple-lg ion-text-white' : 'button-outline button-outline-lg'"
          @click="setCurrentResidentialDuration(residentialDurations.lessThanTwo)">
          <span class="font-bold text-[14px] capitalize block">{{ residentialDurations.lessThanTwo }}</span>
        </button>
        <button fill="outline" class="ml-2"
          :class="addressPreOptTwoActive ? 'button-purple button-purple-lg ion-text-white' : 'button-outline button-outline-lg'"
          @click="setCurrentResidentialDuration(residentialDurations.moreThanTwo)">
          <span class="font-bold text-[14px] capitalize block">{{ residentialDurations.moreThanTwo }}</span>
        </button>
      </div>
      <credit-score-address type="previous" v-model:unitNumber="address.previous.unitNumber"
        v-model:streetNumber="address.previous.streetNumber" v-model:streetName="address.previous.streetName"
        v-model:streetType="address.previous.streetType" v-model:suburb="address.previous.suburb"
        v-model:state="address.previous.state" v-model:postcode="address.previous.postcode"
        @updateAddress="updateAddress" :errors="errors" v-if="addressPreVisible"></credit-score-address>
    </div>
    <div id="ion-content-end">
      <button v-if="showButton" class="button-green button-shadow mb-6" @click="nextStep">
        <span class="block capitalize text-white text-[14px] font-semibold">Continue</span>
      </button>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue';
import router from '@/router';
import axios from 'axios';
import { storeToRefs } from 'pinia';
import { useCustomerStore, useRegisterStore } from '@/stores';
import { useBrowser } from '@/composables/browser';
import { useMixed } from '@/composables/mixed';
import CreditScoreAddress from '@/components/creditScore/CreditScoreAddress.vue';
import CreditScoreRetryMsg from '@/components/creditScore/CreditScoreRetryMsg.vue';
const customerStore = useCustomerStore();
const registerStore = useRegisterStore();
const { deviceID, environment, authToken, idCheckErrRetryCount } = storeToRefs(customerStore);
const { creditScoreAccommodation } = storeToRefs(registerStore);
const { setCreditScoreAccommodation } = registerStore;
const { urls } = useBrowser();
const { scrollToBottom } = useMixed();
const errors = ref(null);
const addressDefault = {
  unitNumber: "",
  streetNumber: "",
  streetName: "",
  streetType: "",
  suburb: "",
  state: "",
  postcode: ""
};

const residentialDurations = {
  lessThanTwo: "Less than 2 years",
  moreThanTwo: "More than 2 years"
}

const address = ref({
  residential: addressDefault,
  previous: addressDefault,
  currentResidentialDuration: ""
});

const addressRequired = (type, keys) => {
  let fields = Object.keys(addressDefault).filter(key => ![...keys].includes(key));
  return !(fields.map(field => type[field]).some(x => ['', "", null, undefined].includes(x)));
}

const addressPreOptOneActive = computed(() => address.value.currentResidentialDuration == residentialDurations.lessThanTwo);
const addressPreOptTwoActive = computed(() => address.value.currentResidentialDuration == residentialDurations.moreThanTwo);
const addressPreVisible = computed(() => addressPreOptOneActive.value);
const addressResidentialRequired = computed(() => addressRequired(address.value.residential, ['unitNumber']));
const addressPreviousRequired = computed(() => addressRequired(address.value.previous, ['unitNumber']));

const showButton = computed(() => {
  switch (address.value.currentResidentialDuration) {
    case residentialDurations.lessThanTwo:
      return addressResidentialRequired.value && addressPreviousRequired.value;
    case residentialDurations.moreThanTwo:
      return addressResidentialRequired.value;
    default:
      return false;
  }
});

const setCurrentResidentialDuration = (val) => {
  scrollToBottom('ion-content-end');
  address.value = { ...address.value, currentResidentialDuration: val };
  if (!addressPreVisible.value) {
    address.value = { ...address.value, previous: addressDefault }
  }
};

const updateAddress = ({ key, value }) => {
  address.value = { ...address.value, [key]: { ...value } };
  errors.value = null;
};

const payloadAddress = computed(() => ({
  unit_number: address.value.residential.unitNumber,
  street_number: address.value.residential.streetNumber,
  street_name: address.value.residential.streetName,
  street_type: address.value.residential.streetType,
  suburb: address.value.residential.suburb,
  state: address.value.residential.state,
  postcode: address.value.residential.postcode,
  current_residential_duration: address.value.currentResidentialDuration,
  previous_unit_number: address.value.previous.unitNumber,
  previous_street_number: address.value.previous.streetNumber,
  previous_street_name: address.value.previous.streetName,
  previous_street_type: address.value.previous.streetType,
  previous_suburb: address.value.previous.suburb,
  previous_state: address.value.previous.state,
  previous_postcode: address.value.previous.postcode,
}));

const nextStep = () => {
  axios.post(urls.apiAccommodationDetails, {
    ...payloadAddress.value,
    device_id: deviceID.value,
    environment: environment.value
  },
    {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + authToken.value,
      }
    })
    .then((res) => {
      if (res.status == 200) {
        setCreditScoreAccommodation({ ...payloadAddress.value });
        router.push({ name: 'CreditScoreIDVerification' });
      }
    })
    .catch((err) => {
      errors.value = err.response.data.errors;
    });
}
const prefillCheck = () => {
  if (creditScoreAccommodation.value) {
    address.value.currentResidentialDuration = creditScoreAccommodation.value.current_residential_duration;
  }
}
onMounted(() => {
  prefillCheck();
})
</script>