<template>
  <div class="px-7 max-w-xl mx-auto h-full flex flex-col justify-center items-center fadein-600">
    <div class="text-center">
      <ion-icon src="/assets/credit-score/register-fail.svg" class="w-[47px] h-[54px] -mt-6"></ion-icon>
      <h2 class="text-[18px] font-bold pb-6 text-center leading-7 mt-6" v-html="idCheckErrTitle"></h2>
      <ion-text class="text-xs ion-text-grey text-center block mx-auto mb-6" v-if="idCheckErrMsg">
        <span v-html="idCheckErrMsg"></span>
      </ion-text>
      <ion-spinner name="dots" v-if="tryAgain"></ion-spinner>
    </div>
    <button class="button-white px-6 flex justify-center items-center mb-3" @click="handleTryAgain" v-if="showTryAgain">
      <span class="block text-xs ion-text-grey">Try Again</span>
    </button>
    <button class="button-white px-6 flex justify-center items-center mb-3" @click="handleMaybeLater" v-if="!tryAgain">
      <span class="block text-xs ion-text-grey">Maybe Later</span>
    </button>
    <button class="button-white px-6 flex justify-center items-center" @click="nextStep('contact')"
      v-if="showContactUs">
      <span class="block text-xs ion-text-grey">Contact Us</span>
    </button>
    <ion-spinner name="dots" v-if="loading"></ion-spinner>
  </div>
</template>
<script setup>
import { ref, computed } from 'vue';
import router from '@/router';
import { useCustomerStore, useNotificationsStore, useLoansStore, useApplicationsStore, useRegisterStore } from '@/stores';
import { storeToRefs } from 'pinia';
import axios from 'axios';
import { useBrowser } from '@/composables/browser';
const { urls } = useBrowser();
const customerStore = useCustomerStore();
const registerStore = useRegisterStore();
const notificationsStore = useNotificationsStore();
const loansStore = useLoansStore();
const applicationsStore = useApplicationsStore();
const { authToken, environment, currentVersion, idCheckErrTitle, idCheckErrMsg, idCheckErrRetry, idCheckErrRetryCount, getCustomerParams } = storeToRefs(customerStore);
const { setProfile, getFinanceFundamentals, setIdCheckErrRetryCount, resetIdCheckErrRetryCount } = customerStore;
const { setNotifications } = notificationsStore;
const { setLoans } = loansStore;
const { setPendingApplication } = applicationsStore;
const { resetCreditScoreAccommodation, resetCreditScoreIdVerification } = registerStore;
const loading = ref(false);
const tryAgain = ref(false);
const showTryAgain = computed(() => !tryAgain.value && idCheckErrRetry.value && idCheckErrRetryCount.value < 1);
const showContactUs = computed(() => !tryAgain.value && !idCheckErrRetry.value);
const handleTryAgain = () => {
  setIdCheckErrRetryCount();
  router.push({ name: 'CreditScoreAccommodation' });
}
const handleMaybeLater = () => {
  loading.value = true;
  resetIdCheckErrRetryCount();
  resetCreditScoreAccommodation();
  resetCreditScoreIdVerification();
  refreshContent();
}
const refreshContent = () => {
  setTimeout(() => {
    axios.get(urls.apiCustomer + getCustomerParams.value, {
      headers: {
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + authToken.value,
      }
    })
      .then((res) => {
        setProfile(res.data.customer);
        setNotifications(res.data.customer.notifications);
        setLoans(res.data.customer.loans);
        setPendingApplication(res.data.customer.pending_application);
      })
      .then(() => {
        getFinanceFundamentals(urls.apiFinanceFundamentals + '?environment=' + environment.value + '&app_version=' + currentVersion.value, authToken.value)
      })
      .then(() => {
        loading.value = false;
        nextStep('tabs/home');
      })
      .catch(err => {
        loading.value = false;
        console.log(err);
      });
  }, 1500);
}
const nextStep = (url) => {
  tryAgain.value = true;
  router.push(url);
}
</script>