<template>
  <div id="section-error-msg">
    <ion-note slot="helper" class="w-full text-sm p-3 mb-6 rounded block text-notice-red bg-notice-red">
      <ion-icon src="/assets/general/notice-danger.svg" class="pt-1 mr-4 float-left w-[25px] h-[25px]"></ion-icon>
      <span class="pr-3">Please check the details you have provided below are correct before continuing.</span>
    </ion-note>
  </div>
</template>

<script setup>
</script>