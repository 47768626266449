<template>
  <ion-page class="ion-page-white">
    <credit-score-header :logo="true" :register="true"></credit-score-header>
    <ion-content>
      <div class="px-7 pt-6 max-w-xl mx-auto fadein-600">
        <credit-score-register-top :type="type" />
        <credit-score-register-new v-if="type == 'new'" />
        <credit-score-register v-else />
        <credit-score-register-bottom />
      </div>
    </ion-content>
  </ion-page>
</template>

<script setup>
import { useRoute } from 'vue-router';
import CreditScoreHeader from '@/components/creditScore/CreditScoreHeader.vue';
import CreditScoreRegisterTop from '@/components/creditScore/CreditScoreRegisterTop.vue';
import CreditScoreRegister from '@/components/creditScore/CreditScoreRegister.vue';
import CreditScoreRegisterNew from '@/components/creditScore/CreditScoreRegisterNew.vue';
import CreditScoreRegisterBottom from '@/components/creditScore/CreditScoreRegisterBottom.vue';
const route = useRoute();
const type = route.params.type ? route.params.type : null;
</script>