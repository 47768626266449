<template>
  <div class="h-full flex flex-row justify-center items-center">
    <ion-spinner name="crescent" class="absolute"></ion-spinner>
    <ion-img src="/assets/logos/branding.png" class="ion-img-branding absolute"></ion-img>
    <ion-img src="/assets/logos/icon.png" class="ion-img-icon"></ion-img>
  </div>
</template>

<style scoped>
ion-spinner {
  --color: var(--ion-color-text-purple);
  width: 300px;
  height: 300px;
  opacity: 0.4;
}

.ion-img-icon {
  width: 200px;
  height: 200px;
  margin: 0 auto;
}

.ion-img-branding {
  width: 200px;
  height: 80px;
  margin: 0 auto 2.15rem;
  bottom: 0;
}
</style>