<template>
  <ion-page class="ion-page-white">
    <ion-content>
      <start-header></start-header>
      <ion-img src="/assets/login/id-lock.svg" class="w-[140px] h-[140px] mx-auto mt-20"></ion-img>
      <ion-card class="fixed p-8 w-full bg-white ion-card-no-shadow button-start">
        <ion-card-content class="p-0">
          <div>
            <div class="text-[20px] font-bold">Enable {{ biometryName }} Login</div>
            <p class="py-3">Enable {{ biometryName }} to securely sign-in to the app.</p>
            <ion-img :src="'/assets/login/' + biometryImage + '.svg'" class="w-[100px] h-[100px] mx-auto mt-6 mb-12"></ion-img>
          </div>
          <button class="button-purple mb-4" @click="bioContinue">
            <span class="block capitalize text-white text-lg font-semibold">Enable {{ biometryName }}</span>
          </button>
          <div class="text-center text-lg mb-3 square">
            <button fill="clear" @click="bioSkip">
              <span class="ion-text-grey font-bold">Maybe Later</span>
            </button>
          </div>
        </ion-card-content>
      </ion-card>
    </ion-content>
  </ion-page>
</template>

<script setup>
import { ref } from 'vue';
import router from '@/router';
import { asyncComputed } from '@vueuse/core';
import StartHeader from '@/components/start/StartHeader.vue';
import { useSecureStorage } from '@/composables/secureStorage';
import { useBiometryAuth } from '@/composables/biometryAuth';
const { keys, setStorage, getStorage, removeStorage } = useSecureStorage();
const bioStorage = asyncComputed(async () => await getStorage({ key: keys.bio }), null);
const { biometryName, biometryImage} = useBiometryAuth();
const bioVal = ref(null);
const bioContinue = () => {
  bioVal.value = true;
  setStorage({ key: keys.bio, value: bioVal.value });
  router.push('home');
};
const bioSkip = () => {
  if(bioStorage.value) {
    removeStorage({ key: keys.bio });
  }
  router.push('home');
};
</script>

<style scoped>
.button-start{
  max-width: 428px;
  border-radius: 0;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  left: 50%;
  transform: translate(-50%, 0);
  margin: 0 auto;
  animation: buttonstart 0.3s forwards;
  animation-delay: 0.3s;
  bottom: -450px;
}
@keyframes buttonstart {
  from {bottom: -450px;}
  to {bottom: 0px;}
}
</style>