<template>
  <ion-modal class="modal-center" ref="modal-error" backdrop-dismiss="false" :is-open="props.isOpen">
    <ion-card class="bg-white m-0 p-0 rounded-xl">
      <ion-card-content class="px-4 pt-3 pb-2">
        <ion-grid>
          <ion-row>
            <ion-col class="py-0 text-center">
              <ion-text class="ion-text-purple block font-bold text-sm">Something Went Wrong</ion-text>
            </ion-col>
            <ion-col class="py-0 text-right" size="1"></ion-col>
          </ion-row>
          <ion-row>
            <ion-col class="text-xs ion-text-brand my-3 text-center w-full">
              There was an error processing your request. Please try again later or contact us by phone or email.
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col>
              <button expand="block" color="secondary" class="button-purple button-shadow mt-1 mb-3"
                @click="contactUs">
                <span class="block capitalize text-white text-[14px] font-semibold height-[60px]">Contact Us</span>
              </button>
              <button expand="block" color="secondary" class="button-grey button-shadow mt-1 mb-3"
                @click="dismissModal()">
                <span class="block capitalize text-brand text-[14px] font-semibold height-[60px]">Close</span>
              </button>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-card-content>
    </ion-card>
  </ion-modal>
</template>

<script setup>
import router from '@/router';
import { modalController } from '@ionic/vue';
const props = defineProps(['isOpen']);
const contactUs = () => {
  modalController.dismiss();
  router.push('contact')
}
const dismissModal = () => {
  modalController.dismiss();
};
</script>