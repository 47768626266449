<template>
  <div class="h-full flex flex-col justify-between items-center">
    <span class="block text-center" v-if="start">
      <ion-spinner name="crescent" class="mx-auto my-2"></ion-spinner>
    </span>
    <div v-else>
      <div class="my-6">
        <div class="mb-3">
          <ion-checkbox slot="start" class="mr-2 float-left" v-model="checkCreditScoreConsent"
            @ionChange="setCreditScoreConsent(checkCreditScoreConsent)"></ion-checkbox>
          <ion-text class="text-[12px] ion-text-brand block">
            I have read and understood the
            <span class="ion-text-primary underline cursor-pointer" @click="openBrowser(urls.apiCreditScoreConsents)">
              Access Seeker Acknowledgement and Consent
            </span>
            and make all consents and acknowledgements it contains.
          </ion-text>
        </div>
        <div>
          <ion-checkbox slot="start" class="mr-3 mt-1 float-left" v-model="checkIdConsent"
            @ionChange="setIdCheckConsent(checkIdConsent)"></ion-checkbox>
          <ion-text class="ion-text-brand text-[12px] leading-[18px]">
            <div class="mb-6">
              I declare that I am authorised to provide all personal details requested in this application and I
              consent
              to my information being checked as outlined in the
              <span class="ion-text-primary underline cursor-pointer pr-1"
                @click="openBrowser(urls.apiIdentityVerificationConsentPreview)">
                Identity Verification Acknowledgement and Consent.
              </span>
              For the purposes of verifying my identity, I expressly consent to:
            </div>
            <span class="block my-2" v-for="[key, value] in consentItems" :key="key">
              {{ key }}) {{ value }}
            </span>
          </ion-text>
        </div>
      </div>
      <button :disabled="!accepted" class="button-purple button-register px-6" @click="nextStep">
        <span class="block text-center" v-if="loading">
          <ion-spinner name="crescent" color="light" class="absolute -ml-3 -mt-3"></ion-spinner>
        </span>
        <span class="block text-lg font-semibold text-white" v-else>
          Track my credit score <img src="/assets/general/arrow-white.svg" class="float-right ml-2 mt-2" />
        </span>
      </button>
      <ion-modal class="modal-center" ref="modal-pay-fail" backdrop-dismiss="false" :is-open="errors">
        <ion-card class="bg-white m-0 p-0 rounded-xl fadein-500">
          <ion-card-content class="px-4 pt-3 pb-2">
            <ion-grid>
              <ion-row>
                <ion-col class="py-0 text-center">
                  <ion-text class="ion-text-red block font-bold text-sm flex items-center justify-center">
                    <ion-icon src="/assets/general/notice-danger.svg" class="w-[18px] h-[18px] mr-3"></ion-icon>
                    <span>Error</span>
                  </ion-text>
                </ion-col>
                <ion-col class="py-0 text-right" size="1"></ion-col>
              </ion-row>
              <ion-row>
                <ion-col class="text-xs ion-text-brand my-3 text-center w-full">
                  {{ errorMsg }}
                </ion-col>
              </ion-row>
              <ion-row>
                <ion-col>
                  <button expand="block" color="secondary" class="button-grey button-shadow mt-1 mb-3"
                    @click="closeModal">
                    <span class="block capitalize text-brand text-[14px] font-semibold height-[60px]">Close</span>
                  </button>
                </ion-col>
              </ion-row>
            </ion-grid>
          </ion-card-content>
        </ion-card>
      </ion-modal>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue';
import router from '@/router';
import axios from 'axios';
import { useCustomerStore } from '@/stores';
import { storeToRefs } from 'pinia';
import { useBrowser } from '@/composables/browser';
import { modalController } from '@ionic/vue';
const customerStore = useCustomerStore();
const { deviceID, environment, authToken } = storeToRefs(customerStore);
const { setCreditScoreConsent, setIdCheckConsent } = customerStore;
const { openBrowser, urls } = useBrowser();
const checkCreditScoreConsent = ref(false);
const checkIdConsent = ref(false);
const loading = ref(false);
const errors = ref(false);
const errorMsg = ref('Something is wrong. Please try again later.');
const start = ref(false);
const accepted = computed(() => checkCreditScoreConsent.value && checkIdConsent.value);
const payload = computed(() => ({
  device_id: deviceID.value,
  environment: environment.value,
  credit_check_consent: checkCreditScoreConsent.value,
  identity_check_consent: checkIdConsent.value
}));
const headers = {
  headers: {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + authToken.value,
  }
};
const consentItems = new Map([
  ['a', 'the verification of my personal information with credit bureau header files;'],
  ['b', 'verification against records held by official document issuers or official record holders via third party systems; and'],
  ['c', 'your verification agent(s) acting as a nominated intermediary in accordance with Australian Privacy Principles. I consent to the use by third parties of the results of any verification checks on my identity for the purposes of monitoring and improving their verification services.'],
]);

const nextStep = () => {
  loading.value = true;
  axios.post(urls.apiIdentityAndCreditScoreConsentSign, payload.value, headers)
    .then(res => {
      if (res.status == 200) {
        loading.value = false;
        router.push({ name: 'CreditScoreAccommodation' });
      } else {
        loading.value = false;
        errors.value = true;
      }
    }).catch(err => {
      loading.value = false;
      errors.value = true;
      errorMsg.value = err.response.data.error ? err.response.data.error.details.error : errorMsg.value;
    });
};
const closeModal = () => {
  errors.value = false;
  modalController.dismiss();
};
const creditCheckEnrolStart = () => {
  start.value = true;
  axios.post(urls.apiCreditCheckEnrolledStart,
    {
      device_id: deviceID.value,
      environment: environment.value,
    }, headers)
    .then((res) => {
      if (res.status == 200) {
        setTimeout(() => {
          start.value = false;
        }, 500);
      } else {
        start.value = false;
        errors.value = true;
      }
    })
    .catch((err) => {
      start.value = false;
      errors.value = true;
      errorMsg.value = err.response.data.error ? err.response.data.error : errorMsg.value;
    });
}
onMounted(() => {
  creditCheckEnrolStart();
})
</script>