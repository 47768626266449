<template>
  <ion-page class="ion-page-white">
    <ion-content>
      <start-header></start-header>
      <div class="p-9 max-w-xl mx-auto">
        <h2 class="text-[30px] font-bold py-9">Reset Password</h2>
        <p class="mb-9">Please enter the verification code that was sent to your phone or email address.</p>
        <div class="my-3 otp-wrapper">
          <v-otp-input
            input-classes="otp-input border-none rounded-full text-2xl text-center font-bold h-[45px] w-[45px] mx-1 bg-[#EEECF9] mb-9"
            ref="otpInput" separator="" :num-inputs="6" :should-auto-focus="true" :is-input-num="true"
            @on-change="otpChange" @on-complete="otpComplete" input-type="number" />
        </div>
        <div class="mt-9" :class="completed ? 'fadein-900' : ''" v-if="completed">
          <ion-item class="default my-6" lines="none">
            <ion-input class="default border-2 rounded-xl text-sm h-[50px] w-full" placeholder="New Password"
              type="password" v-model="password"></ion-input>
          </ion-item>
          <ion-item class="default my-6" lines="none">
            <ion-input class="default border-2 rounded-xl text-sm h-[50px] w-full" placeholder="Confirm Password"
              type="password" v-model="passwordConfirmed" :disabled="!validated"></ion-input>
          </ion-item>
          <ion-note slot="helper" class="w-full text-xs px-1 mt-6 ion-text-primary block">{{ passwordError }}</ion-note>
          <ion-note slot="helper" class="w-full text-xs px-1 mb-6 ion-text-primary block">{{ matchError }}</ion-note>
          <ion-note slot="helper" class="w-full text-xs px-1 mb-6 ion-text-primary block">
            <span v-html="sendError"></span>
          </ion-note>
        </div>

        <div class="mt-9" :class="completed ? 'fadein-900' : ''" v-if="confirmed && validated">
          <button class="button-purple mt-6 mb-8 px-6" @click="changePassword(urls.apiRecoverPassword)">
            <span class="block text-center" v-if="loading">
              <ion-spinner name="crescent" color="light" class="absolute -ml-3 -mt-3"></ion-spinner>
            </span>
            <span class="block capitalize text-white text-lg font-semibold" v-else>Change Password</span>
          </button>
        </div>
      </div>

      <ion-modal class="modal-center" ref="modal-reset-password" backdrop-dismiss="false" :is-open="showModal">
        <ion-card class="bg-white m-0 p-0 rounded-xl">
          <ion-card-content class="px-4 pt-3 pb-2">
            <ion-grid>
              <ion-row>
                <ion-col class="py-0 text-center">
                  <ion-text class="ion-text-green block font-bold text-lg mb-3">
                    Password Updated!
                  </ion-text>
                  <ion-text class="ion-text-purple block text-sm mb-3">
                    Your password has been changed successfully.
                  </ion-text>
                </ion-col>
              </ion-row>
              <ion-row>
                <ion-col>
                  <button expand="block" color="secondary" class="button-grey button-shadow mt-1 mb-3"
                    @click="dismissModal()">
                    <span class="block capitalize text-brand text-[14px] font-semibold height-[60px]">Close</span>
                  </button>
                </ion-col>
              </ion-row>
            </ion-grid>
          </ion-card-content>
        </ion-card>
      </ion-modal>
    </ion-content>
  </ion-page>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue';
import StartHeader from '@/components/start/StartHeader.vue';
import VOtpInput from 'vue3-otp-input';
import { useField } from 'vee-validate';
import { useBrowser } from '@/composables/browser';
import axios from 'axios';
import { storeToRefs } from 'pinia';
import router from '@/router';
import { modalController } from '@ionic/vue';
import { useCustomerStore, useNotificationsStore, useLoansStore, useConfigStore, useApplicationsStore } from '@/stores';
import { addCustomerIdToSession } from "@/composables/adjust";
const customerStore = useCustomerStore();
const { updateAuth, setAuthToken, setEnvironment, setProfile, updateNewCustomer, setName } = customerStore;
const notificationsStore = useNotificationsStore();
const { setNotifications } = notificationsStore;
const configStore = useConfigStore();
const { setConfig } = configStore;
const loansStore = useLoansStore();
const applicationsStore = useApplicationsStore();
const { setLoans } = loansStore;
const { setPendingApplication } = applicationsStore;
const { deviceID, emailForgotPassword, emailForgotPasswordCompleted, tokenForgotPassword, authToken, getCustomerParams } = storeToRefs(customerStore);
const { urls } = useBrowser();
const otpInput = ref(null);
const otpEntered = ref(null);
const completed = ref(false);
const loading = ref(false);
const showModal = ref(false);
const otpChange = (v) => {
  completed.value = false;
  otpEntered.value = v;
  password.value = '';
  passwordConfirmed.value = '';
  sendError.value = '';
};
const otpComplete = (v) => {
  completed.value = true;
  otpEntered.value = v;
};
const sendError = ref('');
const passwordConfirmed = ref('');
const entered = computed(() => password.value !== '' && passwordConfirmed.value !== '');
const confirmed = computed(() => password.value == passwordConfirmed.value);
const matchError = computed(() => entered.value && !confirmed.value ? 'please ensure the password and the confirmation are the same' : '');
const validated = ref(false);
const { value: password, errorMessage: passwordError } = useField('password', (value) => {
  if (!value) {
    validated.value = false;
    sendError.value = '';
    return 'This field is required';
  }
  const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*.-])(?=.{8,})*/;
  if (!regex.test(String(value)) || value.length < 6) {
    validated.value = false;
    return 'The password must be at least 6 characters, and must contain at least: 1 uppercase letter, 1 lowercase letter, 1 number, and one special character (E.g. , . _ & ? etc)';
  } else {
    validated.value = true;
    return true;
  }
});
const rendErrMsg = (err) => err.response.data.errors ? Object.values(err.response.data.errors).map(e => `<p>${e[0]}</p>`) : err.response.data.message;
const changePassword = (url) => {
  loading.value = true;
  axios.post(url, {
    email: emailForgotPassword.value,
    code: otpEntered.value,
    token: tokenForgotPassword.value,
    password: password.value,
    password_confirmation: passwordConfirmed.value,
    device_id: deviceID.value
  })
    .then(res => {
      if (res.status == 200) {
        setEnvironment(res.data.environment);
        setAuthToken(res.data.auth_token);
        updateAuth(true);
        setTimeout(() => {
          loading.value = false;
          validated.value = true;
          showModal.value = true;
        }, 1000);
      } else {
        loading.value = false;
        validated.value = false;
        sendError.value = rendErrMsg(err);
      }
    }).catch(err => {
      loading.value = false;
      validated.value = false;
      sendError.value = rendErrMsg(err);
    });
};

const dismissModal = () => {
  setTimeout(() => {
    axios.get(urls.apiCustomer + getCustomerParams.value, {
      headers: {
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + authToken.value,
      }
    })
      .then((res) => {
        setProfile(res.data.customer);
        setNotifications(res.data.customer.notifications);
        setLoans(res.data.customer.loans);
        setPendingApplication(res.data.customer.pending_application);
        setName(res.data.customer.first_name);
        setConfig(res.data.config);
        updateNewCustomer(false);
        addCustomerIdToSession(res.data.customer.id);
        modalController.dismiss();
        router.push('home');
      })
      .catch(err => console.log(err));
  }, 3000);
}

onMounted(() => {
  if (!emailForgotPasswordCompleted.value) {
    router.push('forgot-password');
  }
});

</script>

<style scoped>
.otp-wrapper>div {
  justify-content: space-between !important;
}

.otp-input {
  visibility: hidden !important;
  padding-left: 15px;
}
</style>