<template>
  <ion-text class="text-sm ion-text-grey mb-6">Step 2/2</ion-text>
  <ion-item class="default my-6" lines="none">
    <ion-input class="default border-2 rounded-xl text-sm h-[50px] w-full" placeholder="New Password" type="password"
      v-model="password"></ion-input>
  </ion-item>
  <ion-item class="default my-6" lines="none">
    <ion-input class="default border-2 rounded-xl text-sm h-[50px] w-full" placeholder="Confirm Password" type="password"
      v-model="passwordConfirmed" :disabled="!validated"></ion-input>
  </ion-item>
  <ion-note slot="helper" class="w-full text-xs px-1 mt-6 ion-text-primary block">{{ passwordError }}</ion-note>
  <ion-note slot="helper" class="w-full text-xs px-1 mb-6 ion-text-primary block">{{ matchError }}</ion-note>
  <button class="button-purple mt-6 px-6" @click="setNewPassword(urls.apiChangePassword)"
    :disabled="!entered || !confirmed">
    <span class="block capitalize text-white font-semibold">Set New Password</span>
  </button>
  <error-modal :isOpen="sendError"></error-modal>
</template>

<script setup>
import { ref, computed } from 'vue';
import router from '@/router';
import axios from 'axios';
import { useField } from 'vee-validate';
import ErrorModal from '@/components/error/ErrorModal.vue';
import { useBrowser } from '@/composables/browser';
import { useCustomerStore } from '@/stores';
import { storeToRefs } from 'pinia';
const customerStore = useCustomerStore();
const { deviceID, environment, authToken } = storeToRefs(customerStore);
const { urls } = useBrowser();
const passwordConfirmed = ref('');
const entered = computed(() => password.value !== '' && passwordConfirmed.value !== '');
const confirmed = computed(() => password.value == passwordConfirmed.value);
const matchError = computed(() => entered.value && !confirmed.value ? 'please ensure the password and the confirmation are the same' : '');
const validated = ref(false);
const sendError = ref(false);
const { value: password, errorMessage: passwordError } = useField('password', (value) => {
  if (!value) {
    validated.value = false;
    return 'This field is required';
  }
  const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})*/;
  if (!regex.test(String(value)) || value.length < 6) {
    validated.value = false;
    return 'The password must be at least 6 characters, and must contain at least: 1 uppercase letter, 1 lowercase letter, 1 number, and one special character (E.g. , . _ & ? etc)';
  } else {
    validated.value = true;
    return true;
  }
});
const headers = {
  headers: {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + authToken.value,
  }
};
const setNewPassword = (url) => {
  axios.post(url, {
    password: password.value,
    password_confirmation: passwordConfirmed.value,
    device_id: deviceID.value,
    environment: environment.value
  }, headers)
    .then((res) => {
      if (res.status == '200') {
        router.push('change-password-success')
      } else {
        setTimeout(() => {
          sendError.value = true;
        }, 1000);
      }
    })
    .catch((err) => {
      if (err) {
        setTimeout(() => {
          sendError.value = true;
        }, 1000);
      }
    });
}
</script>
