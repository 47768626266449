<template>
  <div :key="props.type">
    <ion-item class="default mt-6 border-2 border-purple rounded-xl" lines="none">
      <ion-icon src="/assets/general/map-marker.svg" class="w-[16px] h-[16px] block mx-auto ml-3 opacity-60"></ion-icon>
      <ion-input :placeholder="addressPlaceholder" class="text-base my-2 mx-3" v-model="addressEntered"
        :id="googlePlaceName" :name="googlePlaceName"></ion-input>
      <ion-icon src="/assets/general/close-grey.svg"
        class="w-[16px] h-[16px] block mx-auto mr-3 cursor-pointer opacity-60 hover:opacity-100" @click="clearAddress"
        v-if="addressEntered"></ion-icon>
    </ion-item>

    <div v-if="errorMessage" class="text-xs mt-3 text-center ion-text-red" v-html="errorMessage"></div>

    <p class="text-sm underline my-3 cursor-pointer" v-if="!addressVisible && !loading" @click="enterAddress">
      Enter address manually
    </p>

    <div class="min-h-[310px] flex items-center align-center" v-if="loading">
      <ion-spinner name="crescent" class="mx-auto my-2"></ion-spinner>
    </div>

    <div class="pb-3 fadein-900" v-if="addressVisible && !loading">
      <div class="flex items-top justify-between">
        <div class="grow mt-5 mr-4">
          <div class="ion-item-wrapper">
            <ion-item class="default" lines="none">
              <ion-label position="floating">Unit number</ion-label>
              <ion-input @input="updateField($event)" v-model="address.unitNumber"></ion-input>
            </ion-item>
          </div>
          <error-msg :error="validateInput('unit_number', props.type)" />
        </div>
        <div class="mt-5">
          <div class="ion-item-wrapper">
            <ion-item class="default" lines="none">
              <ion-label position="floating">Street number</ion-label>
              <ion-input @input="updateField($event)" v-model="address.streetNumber"></ion-input>
            </ion-item>
          </div>
          <error-msg :error="validateInput('street_number', props.type)" />
        </div>
      </div>

      <div class="flex items-top justify-between">
        <div class="grow mt-5 mr-4">
          <div class="ion-item-wrapper">
            <ion-item class="default" lines="none">
              <ion-label position="floating">Street name</ion-label>
              <ion-input @input="updateField($event)" v-model="address.streetName"></ion-input>
            </ion-item>
          </div>
          <error-msg :error="validateInput('street_name', props.type)" />
        </div>
        <div class="mt-5">
          <div class="ion-item-wrapper">
            <ion-item class="default" lines="none">
              <ion-label position="floating">Street type</ion-label>
              <ion-input @input="updateField($event)" v-model="address.streetType"></ion-input>
            </ion-item>
          </div>
          <error-msg :error="validateInput('street_type', props.type)" />
        </div>
      </div>

      <div class="flex items-top justify-between">
        <div class="grow mt-5">
          <div class="ion-item-wrapper">
            <ion-item class="default" lines="none">
              <ion-label position="floating">Suburb</ion-label>
              <ion-input @input="updateField($event)" v-model="address.suburb"></ion-input>
            </ion-item>
          </div>
          <error-msg :error="validateInput('suburb', props.type)" />
        </div>
      </div>

      <div class="flex items-top justify-between">
        <div class="grow mt-5 mr-4">
          <div class="ion-item-wrapper ion-item-wrapper-select">
            <ion-item class="default" lines="none">
              <ion-label position="floating">State</ion-label>
              <ion-select interface="action-sheet" class="ion-text-brand p-0 pr-4 min-h-[55px]"
                @click="updateField($event)" v-model="address.state">
                <ion-select-option v-for="state in addressStates" :key="state" :value="state">
                  {{ state }}
                </ion-select-option>
              </ion-select>
            </ion-item>
          </div>
          <error-msg :error="validateInput('state', props.type)" />
        </div>

        <div class="mt-5">
          <div class="ion-item-wrapper">
            <ion-item class="default" lines="none">
              <ion-label position="floating">Post code</ion-label>
              <ion-input type="number" @input="updateField($event)" v-model="address.postcode"></ion-input>
            </ion-item>
          </div>
          <error-msg :error="validateInput('postcode', props.type)" />
        </div>
      </div>
    </div>
    <div :id="addressSectionBottom"></div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, watch } from 'vue';
import { loadScript } from "vue-plugin-load-script";
import { storeToRefs } from 'pinia';
import { useRegisterStore } from '@/stores';
import { useBrowser } from '@/composables/browser';
import { useMixed } from '@/composables/mixed';
import ErrorMsg from '@/components/misc/ErrorMsg.vue';
const registerStore = useRegisterStore();
const { creditScoreAccommodation } = storeToRefs(registerStore);
const props = defineProps(['type', 'errors']);
const emit = defineEmits(['updateAddress', 'update:modelValue']);
const { urls } = useBrowser();
const { scrollToBottom } = useMixed();
const loading = ref(false);
const errorMessage = ref('');
const addressVisible = ref(false);
const addressEntered = ref('');
const address = ref({});
const addressSectionBottom = computed(() => `address-section-${props.type}-bottom`);
const addressPlaceholder = computed(() => `Start typing ${props.type} address...`);
const addressStates = ["ACT", "NSW", "NT", "QLD", "SA", "TAS", "VIC", "WA"];
const googlePlaceName = computed(() => `user_input_autocomplete_address_${props.type}`);
const googlePlacesInit = () => {
  loadScript(urls.googleMapPlaces)
    .then(() => {
      setTimeout(() => {
        initializeAutocomplete(googlePlaceName.value);
      }, 1000);
    }).catch(() => {
      setErrorMessage("There was an error processing your request.<br/>Please try again later.");
    })
}

const initializeAutocomplete = (id) => {
  let element = document.getElementsByName(id)[0];
  if (element) {
    let autocomplete = new google.maps.places.Autocomplete(element);
    autocomplete.setComponentRestrictions({
      country: ["au"],
    });
    google.maps.event.addListener(autocomplete, 'place_changed', () => {
      loading.value = true;
      errorMessage.value = "";
      let place = autocomplete.getPlace();
      let addressComponents = place.address_components || [];
      let route = addressComponents.find((component) => component.types.includes("route"))?.long_name;
      let unitNumber = addressComponents
        .find((component) => component.types.includes("subpremise"))
        ?.long_name.toLowerCase()
        ?.replace("unit", "")
        .trim();
      let streetNumber = addressComponents.find((component) => component.types.includes("street_number"))?.long_name;
      let streetName = route ? route.substr(0, route.lastIndexOf(" ")) : null;
      let streetType = route ? route.substr(route.lastIndexOf(" ") + 1) : null;
      let suburb = addressComponents.find((component) => component.types.includes("locality"))?.long_name;
      let state = addressComponents.find((component) => component.types.includes("administrative_area_level_1"))
        ?.short_name;
      let postcode = addressComponents.find((component) => component.types.includes("postal_code"))?.long_name;
      address.value = {
        "unitNumber": unitNumber,
        "streetNumber": streetNumber,
        "streetName": streetName,
        "streetType": streetType,
        "suburb": suburb,
        "state": state,
        "postcode": postcode,
      };
      enterAddress();
      refreshAddress();
      setTimeout(() => {
        loading.value = false;
        if (!place.address_components) {
          setErrorMessage("There was an error processing your request.<br/>Please enter the address mannually or try again later.");
        }
      }, 1000);
    });
  }
}

const enterAddress = () => {
  addressVisible.value = true;
  scrollToBottom(addressSectionBottom.value);
}

const refreshAddress = () => {
  emit('updateAddress', { key: props.type, value: address.value });
}

const updateField = (e) => {
  emit("update:modelValue", e.target.value);
}

const clearAddress = () => {
  addressEntered.value = "";
  addressVisible.value = false;
  address.value = {};
  refreshAddress();
}

const prefillAddress = () => {
  addressVisible.value = true;
  address.value = props.type == 'previous' ? {
    "unitNumber": creditScoreAccommodation.value.previous_unit_number,
    "streetNumber": creditScoreAccommodation.value.previous_street_number,
    "streetName": creditScoreAccommodation.value.previous_street_name,
    "streetType": creditScoreAccommodation.value.previous_street_type,
    "suburb": creditScoreAccommodation.value.previous_suburb,
    "state": creditScoreAccommodation.value.previous_state,
    "postcode": creditScoreAccommodation.value.previous_postcode,
  } : {
    "unitNumber": creditScoreAccommodation.value.unit_number,
    "streetNumber": creditScoreAccommodation.value.street_number,
    "streetName": creditScoreAccommodation.value.street_name,
    "streetType": creditScoreAccommodation.value.street_type,
    "suburb": creditScoreAccommodation.value.suburb,
    "state": creditScoreAccommodation.value.state,
    "postcode": creditScoreAccommodation.value.postcode,
  };
}

const validateInput = (key, type) => {
  let errors = props.errors;
  let keyName = type == 'previous' ? 'previous_' + key : key;
  return errors && Object.keys(errors).includes(keyName) ? errors[keyName][0] : false;
}

const setErrorMessage = (val) => {
  errorMessage.value = val;
}

onMounted(() => {
  googlePlacesInit();
  if (creditScoreAccommodation.value) {
    prefillAddress();
  } else {
    clearAddress();
  }

});

watch(() => address.value, () => {
  refreshAddress();
}, { deep: true });
</script>