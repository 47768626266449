<template>
  <ion-page class="ion-page-white">
    <ion-content>
      <div class="h-full flex flex-col justify-between items-center">
        <register-header></register-header>
        <div class="p-9 max-w-xl mx-auto text-center">
          <profile-icon></profile-icon>
          <h2 class="text-[24px] font-bold pb-9 text-center">Existing Account</h2>
          <ion-text class="ion-text-brand text-sm mt-6">
            {{ existingAccountMsg }}.
          </ion-text>
        </div>
        <div class="p-9 max-w-xl mx-auto text-center text-xs text-center mb-3 w-full">
          <button class="button-purple button-register mt-6 px-6" @click="$router.push('login-email')">
            <span class="block capitalize text-lg font-semibold text-white">Log In</span>
          </button>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script setup>
import { onMounted } from 'vue';
import router from '@/router';
import RegisterHeader from '@/components/register/RegisterHeader.vue';
import ProfileIcon from '@/components/profile/ProfileIcon.vue';
import { storeToRefs } from 'pinia';
import { useCustomerStore } from '@/stores';
const customerStore = useCustomerStore();
const { existingAccountMsg } = storeToRefs(customerStore);
onMounted(() => {
  if (!existingAccountMsg.value) {
    router.push('/start');
  }
})
</script>