import { computed, onBeforeMount, onBeforeUnmount, reactive, ref, toRaw } from 'vue';
import router from '@/router';
import { BiometricAuth, BiometryType, getBiometryName } from '@aparajita/capacitor-biometric-auth';
import { AdjustEvents, trackEvent } from "@/composables/adjust";
import { useCustomerStore } from '@/stores';
import { useMixed } from '@/composables/mixed';

export function useBiometryAuth() {

  const biometry = ref({
    isAvailable: false,
    biometryType: BiometryType.none,
    reason: ''
  });

  const options = reactive({
    reason: '',
    cancelTitle: '',
    iosFallbackTitle: '',
    androidTitle: '',
    androidSubtitle: '',
    allowDeviceCredential: false
  });

  const appListener = ref();

  const biometryName = computed(() => {
    return getBiometryName(biometry.value.biometryType) || 'No biometry'
  });

  const biometryAvailCheck = computed(() => {
    return biometry.value.biometryType !== BiometryType.none && biometry.value.isAvailable;
  });

  const biometryLabel = computed(() => {
    let biometryText = biometryName.value.replace('Authentication', '');
    return biometryAvailCheck.value ? `Log in with ${biometryText}` : '';
  });

  const biometryImage = computed(() => {
    if (biometryAvailCheck.value) {
      if (['Face ID', 'Face Authentication', 'Iris Authentication'].includes(biometryName.value)) {
        return 'id-face';
      } else if (['Touch ID', 'Fingerprint Authentication'].includes(biometryName.value)) {
        return 'id-finger-print';
      } else {
        return null;
      }
    } else {
      return null;
    }
  });

  const updateBiometryInfo = (info) => biometry.value = info;

  onBeforeMount(async () => {
    updateBiometryInfo(await BiometricAuth.checkBiometry())
    try {
      appListener.value = await BiometricAuth.addResumeListener(
        updateBiometryInfo
      )
    } catch (error) {
      if (error) {
        console.error(error.message)
      }
    }
  })

  onBeforeUnmount(async () => {
    await appListener.value?.remove()
  })

  const onAuthenticate = async () => {
    const customerStore = useCustomerStore();
    const { updateAuth } = customerStore;
    const { refreshCustomer } = useMixed();
    try {
      trackEvent(AdjustEvents.CLICK_LOGIN)
      await BiometricAuth.authenticate(toRaw(options))
      updateAuth(true);
      refreshCustomer();
      setTimeout(() => {
        router.push('home')
      }, 3000);
    } catch (err) {
      if (err) {
        await router.push('/login-bio');
      }
    }
  }

  return { onAuthenticate, biometryAvailCheck, biometryLabel, biometryName, biometryImage }
}