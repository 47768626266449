<template>
  <ion-header class="ion-no-border bg-white" collapse="fade">
    <ion-toolbar color="white">
      <ion-buttons slot="start">
        <ion-back-button class="pl-3" text="" icon="/assets/general/back.svg"></ion-back-button>
      </ion-buttons>
      <ion-title>
        <slot></slot>
      </ion-title>
      <span slot="end" class="w-[56px] invisible"></span>
    </ion-toolbar>
  </ion-header>
</template>