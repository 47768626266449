<template>
  <ion-page class="ion-page-white">
    <ion-content>
      <start-header></start-header>
      <div class="p-9 max-w-xl mx-auto">
        <h2 class="text-[30px] font-bold py-9">Log In</h2>
        <div :class="count >= 3 ? 'disabled' : ''">
          <ion-item class="default" lines="none" :class="shake ? 'shake' : ''">
            <ion-input type="password" placeholder="Password" ref="item"
              class="default border-2 rounded-xl text-base font-bold h-[50px] w-full" v-model="password"></ion-input>
            <ion-img src="/assets/login/eye.svg" class="absolute right-4 z-10"
              :class="showPassword ? 'opacity-100' : 'opacity-50'" @click="toggleShow"></ion-img>
          </ion-item>
          <p class="w-full text-xs px-1 mt-3 block ion-text-grey" :class="passwordError ? 'fadein-900' : ''"
            v-html="passwordError"></p>
        </div>

        <button class="button-purple mt-3 mb-8 px-6" @click="signIn(urls.apiLogin)" :disabled="!validated">
          <span class="block text-center" v-if="loading">
            <ion-spinner name="crescent" color="light" class="absolute -ml-3 -mt-3"></ion-spinner>
          </span>
          <span class="block capitalize text-white text-lg font-semibold" v-else>Login</span>
        </button>

        <div class="text-center text-base my-3 square fadein-900">
          <button fill="clear" @click="forgotPassword">
            <span class="ion-text-grey font-bold underline">Forgot password?</span>
          </button>
          <div class="pt-6">
            <span class="ion-text-grey">Don't have an account? </span>
            <button fill="clear" @click="getStarted">
              <span class="ion-text-green font-bold underline">Get Started</span>
            </button>
          </div>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue';
import router from '@/router';
import { useField } from 'vee-validate';
import StartHeader from '@/components/start/StartHeader.vue';
import { useCustomerStore, useConfigStore, useNotificationsStore, useLoansStore, useApplicationsStore } from '@/stores';
import { storeToRefs } from 'pinia';
import { useBrowser } from '@/composables/browser';
import { useSecureStorage } from '@/composables/secureStorage';
import axios from 'axios';
import { addCustomerIdToSession, AdjustEvents, trackEvent } from "@/composables/adjust";
const notificationsStore = useNotificationsStore();
const { setNotifications } = notificationsStore;
const configStore = useConfigStore();
const { setConfig } = configStore;
const { keys, setStorage, clearAllStorage } = useSecureStorage();
const { urls } = useBrowser();
const customerStore = useCustomerStore();
const { deviceID, deviceName, deviceModel, pushNotificationToken, email, authToken, environment, currentVersion, startCheckIfQualify, startTrackCreditScore, getCustomerParams } = storeToRefs(customerStore);
const { setProfile, updateAuth, setName, setAuthToken, setEnvironment, getFinanceFundamentals, setStartCheckIfQualify, setStartTrackCreditScore } = customerStore;
const loansStore = useLoansStore();
const applicationsStore = useApplicationsStore();
const { setLoans } = loansStore;
const { setPendingApplication } = applicationsStore;
const item = ref();
const validated = ref(false);
const showPassword = ref(false);
const count = ref(0);
const shake = ref(false);
const loading = ref(false);
const { value: password, errorMessage: passwordError } = useField('password', (value) => {
  if (!value && count.value == 0) {
    validated.value = false;
    return 'This field is required';
  } else if (!value && count.value > 0) {
    validated.value = false;
    switch (count.value) {
      case 1:
        return 'Wrong password. Please try again. <span class="block mt-1 ion-text-red">You have 2 attempts left.</span>';
      case 2:
        return 'Wrong password. Please try again. <span class="block mt-1 ion-text-red">You have 1 attempt left.</span>';
      case 3:
        return 'Wrong password. <span class="mt-1 ion-text-red">You have no more attempts available.</span>';
      default:
        return 'Wrong password. Please try again.';
    }
  } else {
    validated.value = true;
    return true;
  }
});

const toggleShow = () => {
  showPassword.value = !showPassword.value;
  item.value.$el.type = showPassword.value ? 'text' : 'password';
}

const payload = computed(() => ({
  email: email.value,
  password: password.value,
  device_id: deviceID.value,
  device_name: deviceName.value,
  device_model: deviceModel.value,
  push_token: pushNotificationToken.value
}));

const headers = (token) => {
  return {
    headers: {
      'Accept': 'application/json',
      'Authorization': 'Bearer ' + token,
    }
  }
}

const signIn = (url) => {
  if (count.value < 3) {
    loading.value = true;
    axios.post(url, payload.value)
      .then(
        res => {
          setAuthToken(res.data.auth_token);
          setEnvironment(res.data.environment)
          updateAuth(true);
        })
      .then(() => {
        axios.get(urls.apiCustomer + getCustomerParams.value, headers(authToken.value))
          .then((res) => {
            loading.value = false;
            setProfile(res.data.customer);
            setName(res.data.customer.first_name);
            setConfig(res.data.config);
            setNotifications(res.data.customer.notifications);
            setLoans(res.data.customer.loans);
            setPendingApplication(res.data.customer.pending_application);
            addCustomerIdToSession(res.data.customer.id)
            setStorage({ key: keys.pro, value: JSON.stringify({ token: authToken.value, env: environment.value }) });
            if (Capacitor.isNativePlatform()) {
              router.push('set-pin');
            } else {
              router.push('home');
            }
          })
      })
      .then(() => {
        getFinanceFundamentals(urls.apiFinanceFundamentals + '?environment=' + environment.value + '&app_version=' + currentVersion.value, authToken.value)
      })
      .catch(err => {
        loading.value = false;
        trackEvent(AdjustEvents.LOGIN_FAILED);
        if (err) {
          count.value++;
          if (count.value > 0 && count.value <= 3) {
            shake.value = true;
            setTimeout(() => {
              shake.value = false;
              password.value = '';
            }, 500);
          }
          if (count.value >= 3) {
            clearAllStorage();
          }
        }
      });
  }
}

const forgotPassword = () => {
  router.push('forgot-password');
}

const getStarted = () => {
  if (!startCheckIfQualify.value && !startTrackCreditScore.value) {
    setStartCheckIfQualify(true);
    setStartTrackCreditScore(false);
  }
  router.push('register-step-1');
}

onMounted(() => {
  if (!email.value) {
    router.push('login-email')
  }
});

</script>

<style scoped>
.disabled {
  opacity: 0.3;
  position: relative;
  z-index: -1;
}
</style>