<template>
  <ion-page class="ion-page-white">
    <ion-content>
      <start-header></start-header>
      <div class="p-9 max-w-xl mx-auto">
        <h2 class="text-[24px] font-bold pt-9 pb-2">Choose a 4 Digit Passcode</h2>
        <p class="text-sm pb-9">This will be used to login to your Jacaranda Finance account.</p>
        <div class="mb-9 otp-wrapper">
          <v-otp-input
            input-classes="otp-input border-none rounded-full text-3xl text-center font-bold h-[70px] w-[70px] mx-1 bg-[#EEECF9]"
            ref="otpInput" separator="" :num-inputs="4" :should-auto-focus="true" :is-input-num="true"
            @on-change="otpChange" @on-complete="otpComplete" />
        </div>

        <button class="button-purple mt-3 mb-8 px-6" @click="otpContinue(nextPage)" :disabled="!completed">
          <span class="block capitalize text-white text-lg font-semibold">Continue</span>
        </button>

        <div class="text-center text-base mb-3 square">
          <button fill="clear" @click="otpSkip(nextPage)">
            <span class="ion-text-primary font-bold underline">Skip</span>
          </button>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script setup>
import { ref, computed, onMounted, nextTick } from 'vue';
import router from '@/router';
import { asyncComputed } from '@vueuse/core';
import VOtpInput from 'vue3-otp-input';
import StartHeader from '@/components/start/StartHeader.vue';
import { useSecureStorage } from '@/composables/secureStorage';
import { useBiometryAuth } from '@/composables/biometryAuth';
const { keys, setStorage, getStorage, removeStorage } = useSecureStorage();
const otpStorage = asyncComputed(async () => await getStorage({ key: keys.otp }), null);
const { biometryAvailCheck } = useBiometryAuth();
const otpInput = ref(null);
const otpVal = ref(null);
const completed = ref(false);
const nextPage = computed(() => biometryAvailCheck.value ? 'set-bio' : 'home')
const otpChange = () => {
  completed.value = false;
};
const otpComplete = (v) => {
  completed.value = true;
  otpVal.value = v;
};
const otpContinue = (val) => {
  setStorage({ key: keys.otp, value: otpVal.value });
  otpInput.value.clearInput();
  router.push(val);
};
const otpSkip = (val) => {
  if (otpStorage.value) {
    removeStorage({ key: keys.otp });
  }
  otpInput.value.clearInput();
  router.push(val);
};
onMounted(() => {
  nextTick(() => {
    setTimeout(() => {
      if (document.querySelector('.otp-input') !== null) {
        document.querySelector('.otp-input').focus();
      }
    }, 1000);
  });
})
</script>

<style scoped>
.otp-wrapper>div {
  justify-content: space-between !important;
}
</style>