<template>
  <ion-page class="ion-page-grey-2">
    <ion-content>
      <start-header></start-header>
      <h2 class="text-[22px] font-bold pt-12 pb-4 text-center">Welcome Back <span class="ion-text-green">{{ name }}</span>
      </h2>
      <ion-card class="fixed p-8 w-full bg-white ion-card-no-shadow button-start">
        <ion-card-content class="p-0">
          <div class="text-[20px] font-bold mb-3">{{ biometryLabel }}</div>
          <p>Sorry, your {{ biometryName }} could not be recognised.</p>
          <p class="font-bold">Tap to try again.</p>
          <ion-img :src="'/assets/login/' + biometryImage + '.svg'" class="w-[100px] h-[100px] mx-auto mt-6 mb-12"
            @click="onAuthenticate"></ion-img>
          <button class="button-purple mb-4" @click="$router.push('login-pin')" v-if="otpStorage">
            <span class="block capitalize text-white text-lg font-semibold">Login with PIN</span>
          </button>
          <div class="text-center text-lg mb-3 square">
            <button fill="clear" @click="$router.push('login-email')">
              <span class="ion-text-primary font-bold underline">Login with Password</span>
            </button>
          </div>
        </ion-card-content>
      </ion-card>
    </ion-content>
  </ion-page>
</template>

<script setup>
import StartHeader from '@/components/start/StartHeader.vue';
import { asyncComputed } from '@vueuse/core';
import { useSecureStorage } from '@/composables/secureStorage';
import { useBiometryAuth } from '@/composables/biometryAuth';
import { storeToRefs } from 'pinia';
import { useCustomerStore } from '@/stores';
import {onMounted} from "vue";
import {AdjustEvents, trackEvent} from "@/composables/adjust";
const customerStore = useCustomerStore();
const { name } = storeToRefs(customerStore);
const { keys, getStorage } = useSecureStorage();
const otpStorage = asyncComputed(async () => await getStorage({ key: keys.otp }), null);
const { onAuthenticate, biometryLabel, biometryName, biometryImage } = useBiometryAuth();

onMounted(() => {
    trackEvent(AdjustEvents.LOGIN_SUCCESSFUL)
});
</script>

<style scoped>
.button-start {
  max-width: 428px;
  border-radius: 0;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  left: 50%;
  transform: translate(-50%, 0);
  margin: 0 auto;
  animation: buttonstart 0.3s forwards;
  animation-delay: 0.3s;
  bottom: -450px;
}

@keyframes buttonstart {
  from {
    bottom: -450px;
  }

  to {
    bottom: 0px;
  }
}</style>