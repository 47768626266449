<template>
  <ion-header class="ion-no-border bg-white" collapse="fade">
    <ion-toolbar color="white">
      <ion-buttons slot="start" :class="routeCreditScoreDetails ? 'w-[80px]' : ''">
        <ion-button routerLink="/home" v-if="props.register">
          <img src="/assets/general/back.svg" class="inline pl-1 h-[26px]" />
        </ion-button>
        <ion-back-button class="pl-3" text="" icon="/assets/general/back.svg" v-else></ion-back-button>
      </ion-buttons>
      <ion-title>
        <app-logo color="purple" v-if="props.logo"></app-logo>
        <ion-text class="ion-text-brand block text-center" v-else>
          <span class="font-bold" :class="routeCreditScoreAbout ? 'text-sm' : 'text-[16px]'">{{ props.title }}</span>
          <small v-if="props.subtitle" class="block text-[10px] ion-text-grey-2 -mt-[2px]" v-html="subtitle"></small>
        </ion-text>
      </ion-title>
      <ion-buttons slot="end">
        <span slot="end" class="credit-score-status mr-3 capitalize" :class="statusClass"
          v-if="routeCreditScoreDetails">
          {{ statusLabel }}
        </span>
        <span slot="end" class="w-[40px] mr-3" v-else></span>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>
</template>

<script setup>
import { computed } from 'vue';
import AppLogo from '@/components/start/AppLogo.vue';
import { useRoute } from 'vue-router';
const route = useRoute();
const routeCreditScoreDetails = computed(() => route.path == '/tabs/credit-score');
const routeCreditScoreAbout = computed(() => route.path == '/tabs/credit-score-about');
const props = defineProps(['title', 'logo', 'subtitle', 'status', 'register']);
const statusClass = props.status ? 'credit-score-status-active' : 'credit-score-status-on-hold';
const statusLabel = props.status ? 'Active' : 'On Hold';
</script>