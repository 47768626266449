<template>
  <ion-text class="text-sm ion-text-grey mb-6">Step 1/2</ion-text>
  <div :class="count >= 3 ? 'disabled' : ''">
    <ion-item class="default my-6" lines="none" :class="shake ? 'shake' : ''">
      <ion-input type="password" placeholder="Old Password" ref="item"
        class="default border-2 rounded-xl text-sm h-[50px] w-full" v-model="password"></ion-input>
      <ion-note slot="helper" class="w-full block">{{ passwordError }}</ion-note>
    </ion-item>
  </div>
  <ion-text class="text-sm ion-text-grey underline mt-8 mb-6 block" @click="router.push('forgot-password')"
    v-if="count >= 3">Forgot Password</ion-text>
  <button class="button-purple mt-3 mb-8 px-6" @click="signIn(urls.apiLogin)" :disabled="!validated || count >= 3">
    <span class="block capitalize text-white text-lg font-semibold">
      Next <img src="/assets/general/arrow-2.svg" class="float-right ml-2 mt-2" />
    </span>
  </button>
  <error-modal :isOpen="sendError"></error-modal>
</template>

<script setup>
import { ref } from 'vue';
import router from '@/router';
import { useField } from 'vee-validate';
import { useBrowser } from '@/composables/browser';
import { useCustomerStore } from '@/stores';
import { storeToRefs } from 'pinia';
import axios from 'axios';
import ErrorModal from '@/components/error/ErrorModal.vue';
const { urls } = useBrowser();
const customerStore = useCustomerStore();
const { profile, deviceID, environment, authToken } = storeToRefs(customerStore);
const item = ref();
const validated = ref(false);
const count = ref(0);
const shake = ref(false);
const sendError = ref(false);
const { value: password, errorMessage: passwordError } = useField('password', (value) => {
  if (!value) {
    validated.value = false;
    return 'This field is required';
  } else {
    validated.value = true;
    return true;
  }
});
const headers = {
  headers: {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + authToken.value,
    'Accept': 'application/json'
  }
};
const signIn = (url) => {
  axios.post(url, {
    email: profile.value.email_address,
    password: password.value,
    device_id: deviceID.value,
    environment: environment.value
  }, headers)
    .then((res) => {
      if (res.status == '200') {
        router.push('change-password-new');
      }
    })
    .catch((err) => {
      if (err) {
        count.value++;
        if (count.value > 0 && count.value < 3) {
          shake.value = true;
          setTimeout(() => {
            shake.value = false;
            password.value = '';
          }, 500);
        } 
      }
    });
}
</script>

