<template>
  <div class="px-7 max-w-xl mx-auto h-full flex flex-col justify-center items-center fadein-600">
    <div class="text-center">
      <profile-icon></profile-icon>
      <h2 class="text-[16px] font-bold pb-6 text-center leading-6 mt-6">Verifying your identity</h2>
      <ion-text class="text-xs ion-text-grey text-center block mx-auto mb-6">
        This should only take a minute
      </ion-text>
      <ion-spinner name="dots" v-if="loading"></ion-spinner>
    </div>
  </div>
</template>
<script setup>
import { ref, onMounted } from 'vue';
import ProfileIcon from '@/components/profile/ProfileIcon.vue';
import router from '@/router';
import axios from 'axios';
import { useBrowser } from '@/composables/browser';
import { useCustomerStore } from '@/stores';
import { storeToRefs } from 'pinia';
const loading = ref(true);
const { urls } = useBrowser();
const customerStore = useCustomerStore();
const { authToken, environment, currentVersion } = storeToRefs(customerStore);
const { setIdCheckErrTitle, setIdCheckErrMsg, setIdCheckErrRetry } = customerStore;
const errTitle = ref('Sorry, there was an issue<br />verifying your identity.');
const errMsgRetry = ref('Our online identity verification provider was not able to verify your details from the information you provided. <br/><br/>Please try again, making sure your information is exactly how it appears on your identity documents.');
const errMsgContact = ref('Our online identity verification provider was not able to verify your details from the information you provided. <br/><br/>Please contact us to verify your identity.');
const handleIdCheckErrors = (err = null) => {
  setIdCheckErrTitle(errTitle.value);
  setIdCheckErrMsg(err ? errMsgRetry.value : errMsgContact.value);
  setIdCheckErrRetry(true);
  router.push({ name: 'CreditScoreIDVerifyFail' });
}
const headers = {
  headers: {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + authToken.value,
  }
};
onMounted(() => {
  setTimeout(() => {
    axios.get(urls.apiIdentificationCheck + '?environment=' + environment.value + '&app_version=' + currentVersion.value, headers)
      .then(res => {
        loading.value = false;
        if (res.status == 200) {
          if (res.data.status == 'pass') {
            router.push({ name: 'CreditScoreConnect' })
          } else {
            handleIdCheckErrors();
          }
        } else {
          handleIdCheckErrors();
        }
      }).catch(err => {
        loading.value = false;
        handleIdCheckErrors(err);
      });
  }, 3000);
});
</script>