<template>
  <ion-card class="bg-white m-0 p-0 my-4 rounded-xl credit-score-card">
    <ion-card-content class="bg-white-500 p-3 pb-0">
      <ion-grid>
        <ion-row class="mb-2" @click="toggleVisible">
          <ion-col class="py-0" size="1">
            <ion-icon src="/assets/credit-score/icon-improve.svg" class="w-[15px] pt-1"></ion-icon>
          </ion-col>
          <ion-col class="py-0 text-center">
            <ion-text class="ion-text-purple block font-bold text-sm">Help to Improve your score</ion-text>
          </ion-col>
          <ion-col class="py-0 text-right" size="1">
            <ion-icon src="/assets/general/arrow-grey.svg" class="w-[15px] pt-1 button-toggle"
              :class="visible ? 'button-toggle' : 'button-toggle-up'"></ion-icon>
          </ion-col>
        </ion-row>
        <ion-row class="mx-2 fadein-300" v-if="visible">
          <ion-col class="max-w-[138px]">
            <ion-icon src="/assets/credit-score/icon-lightbulb.svg" class="h-[70px]" :class="screenSizeSmall ? 'w-[60px]' : 'w-[80px]'"></ion-icon>
          </ion-col>
          <ion-col size="8">
            <ion-text class="text-xs block leading-[18px]"><strong>Comprehensive Credit Reporting</strong> help increase
              your score faster, by notifying credit bureaus of positive activity.</ion-text>
          </ion-col>
        </ion-row>
        <ion-row class="mx-2 mb-2 fadein-300" v-if="visible">
          <ion-col>
            <button expand="block" color="secondary" class="button-purple button-purple-lg button-shadow my-1"
              @click="openBrowser(urls.ccr)">
              <span class="block text-white text-[14px] font-semibold">Find Out about CCR</span>
            </button>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-card-content>
  </ion-card>
</template>

<script setup>
import { ref } from 'vue';
import { storeToRefs } from 'pinia';
import { useConfigStore } from '@/stores';
const configStore = useConfigStore();
const { screenSizeSmall } = storeToRefs(configStore);
import { useBrowser } from '@/composables/browser';
const { openBrowser, urls } = useBrowser();
const visible = ref(true);
const toggleVisible = () => {
  visible.value = !visible.value;
}
</script>