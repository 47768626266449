<template>
  <div class="my-6 text-center">
    <ion-text class="text-sm ion-text-grey block w-[300px] mx-auto mb-3">Powered by</ion-text>
    <ion-icon src="/assets/logos/logo-equifax.svg" class="w-[188px] h-[36px] block mx-auto mb-3 cursor-pointer"
      @click="openBrowser(urls.equifax + 'personal/')"></ion-icon>
  </div>
</template>
<script setup>
import { useBrowser } from '@/composables/browser';
const { openBrowser, urls } = useBrowser();
</script>