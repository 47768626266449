import { StatusBar, Style } from '@capacitor/status-bar';

export function useStatusBar() {
  const initStatusBar = () => {
    StatusBar.setOverlaysWebView({ overlay: true }); // Android only
    window.addEventListener('statusTap', () => {
      console.log('statusbar tapped'); // iOS only
    });
  }

  const setStatusBarStyleDark = async () => {
    await StatusBar.setStyle({ style: Style.Dark });
  };

  const setStatusBarStyleLight = async () => {
    await StatusBar.setStyle({ style: Style.Light });
  };

  const setStatusBarBackgroundColor = async (color) => {
    await StatusBar.setBackgroundColor(color)
  };

  const hideStatusBar = async () => {
    await StatusBar.hide();
  };

  const showStatusBar = async () => {
    await StatusBar.show();
  };

  return { initStatusBar, setStatusBarStyleDark, setStatusBarStyleLight, setStatusBarBackgroundColor, hideStatusBar, showStatusBar };
}