<template>
  <ion-page class="bg-white">
    <ion-content>
      <div class="px-7 pt-6 max-w-xl mx-auto h-full flex items-center justify-center">
        <password-success-details></password-success-details>
      </div>
    </ion-content>
  </ion-page>
</template>

<script setup>
import { onMounted } from 'vue';
import router from '@/router';
import PasswordSuccessDetails from '@/components/security/PasswordSuccessDetails.vue';
onMounted(() => {
  setTimeout(() => {
    router.push('home')
  }, 3000);
});
</script>