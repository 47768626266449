<template>
  <ion-page class="bg-white">
    <ion-content>
      <div class="px-7 pt-6 max-w-xl mx-auto h-full flex items-center justify-center">
        <ion-row>
          <ion-col class="text-center" v-if="errors">
            <ion-icon src="/assets/credit-score/register-fail.svg" class="w-[47px] h-[54px] -mt-6" />
            <ion-text class="text-xl font-bold ion-text-brand my-6 block">{{ errorHeader }}</ion-text>
            <ion-text class="text-xs ion-text-grey text-center block mx-auto mb-6">{{ errorMsg }}</ion-text>
            <button class="button-white px-6 my-6 flex justify-center items-center" @click="tryAgain">
              <span class="block text-xs ion-text-grey">Try Again</span>
            </button>
          </ion-col>
          <ion-col class="text-center fadein-900" v-else>
            <ion-icon src="/assets/register/register-success.svg" class="w-[140px] h-[150px] fadein-400 mb-2" />
            <ion-text class="text-xl font-bold ion-text-brand mt-6 block">Account Created Successfully</ion-text>
          </ion-col>
        </ion-row>
      </div>
    </ion-content>
  </ion-page>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue';
import router from '@/router';
import axios from 'axios';
import { useRegisterStore, useCustomerStore, useConfigStore, useNotificationsStore, useLoansStore, useApplicationsStore } from '@/stores';
import { storeToRefs } from 'pinia';
import { useBrowser } from '@/composables/browser';
import { addCustomerIdToSession, AdjustEvents, trackEvent } from "@/composables/adjust";
const registerStore = useRegisterStore();
const customerStore = useCustomerStore();
const configStore = useConfigStore();
const notificationsStore = useNotificationsStore();
const loansStore = useLoansStore();
const applicationsStore = useApplicationsStore();
const { clearPrefill } = registerStore;
const { stepFiveCompleted } = storeToRefs(registerStore);
const { updateNewCustomer, setProfile, updateAuth, setName, getFinanceFundamentals } = customerStore;
const { authToken, environment, currentVersion, startCheckIfQualify, startTrackCreditScore, getCustomerParams } = storeToRefs(customerStore);
const { setConfig } = configStore;
const { setNotifications } = notificationsStore;
const { setLoans } = loansStore;
const { setPendingApplication } = applicationsStore;
const { urls } = useBrowser();
const errors = ref(false);
const errorHeader = ref('There was an error processing your request');
const errorMsg = ref('Please try again later or contact us by phone or email.');
const path = computed(() => {
  if (startTrackCreditScore.value) {
    return '/tabs/credit-score-register/new';
  } else if (startCheckIfQualify.value) {
    return '/tabs/get-started-now'
  } else {
    return 'home';
  }
})
const headers = {
  headers: {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + authToken.value,
  }
};
const tryAgain = () => {
  router.push('/start');
  clearPrefill();
};
onMounted(() => {
  if (!stepFiveCompleted.value) {
    router.push('register-step-1');
  } else {
    axios.get(urls.apiCustomer + getCustomerParams.value, headers)
      .then((res) => {
        setProfile(res.data.customer);
        setName(res.data.customer.first_name);
        setConfig(res.data.config);
        setNotifications(res.data.customer.notifications);
        setLoans(res.data.customer.loans);
        setPendingApplication(res.data.customer.pending_application);
        addCustomerIdToSession(res.data.customer.id);
        updateAuth(true);
        setTimeout(() => {
          router.push(path.value);
          updateNewCustomer(true);
          clearPrefill();
        }, 1500);
        trackEvent(AdjustEvents.REGISTRATION_COMPLETED);
      })
      .then(() => {
        getFinanceFundamentals(urls.apiFinanceFundamentals + '?environment=' + environment.value + '&app_version=' + currentVersion.value, authToken.value)
      })
      .catch(err => {
        errors.value = true;
        errorMsg.value = err.response.data.error ? err.response.data.error : errorMsg.value;
      });
  }
});
</script>