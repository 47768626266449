export function isProduction() {
    return process.env.VUE_APP_ENV == 'production'
}

export function isDevelopment() {
    return process.env.VUE_APP_ENV == 'development'
}

export function appEnvConfig() {
    return process.env.VUE_APP_ENV
}