<template>
  <ion-row class="my-3">
    <ion-col size="12" size-md="4" v-for="(btn, index) in props.buttons" :key="index">
      <button expand="block" class="mb-3" :class="btn.condition ? `button-purple button-shadow` : `button-outline`"
        :fill="btn.condition ? 'none' : 'outline'" @click="toggle(btn.section)">
        <span class="block capitalize text-[14px] font-semibold">{{ btn.label }}</span>
      </button>
    </ion-col>
  </ion-row>
</template>
<script setup>
const props = defineProps(['buttons']);
const emit = defineEmits(['toggleSection']);
const toggle = (section) => {
  emit('toggleSection', section);
};
</script>