<template>
  <ion-page class="ion-page-white">
    <ion-content>
      <start-header></start-header>
      <div class="p-9 max-w-xl mx-auto">
        <h2 class="text-[30px] font-bold py-9">Forgot Password</h2>
        <p class="mb-9">Enter your email address to receive a verification code via sms or email.</p>
        <ion-item class="default" lines="none">
          <ion-input type="email" placeholder="Email Address"
            class="default border-2 rounded-xl text-base h-[50px] w-full" v-model="emailForgotPassword"
            @input="resetMessage"></ion-input>
        </ion-item>
        <ion-note slot="helper" class="w-full text-xs px-1 mt-3 block">{{ emailError }}</ion-note>
        <ion-note slot="helper" class="w-full text-xs px-1 mt-3 block ion-text-red">{{ validateError }}</ion-note>
        <button class="button-purple mt-6 mb-8 px-6" @click="sendCode(urls.apiForgotPassword, 'email')">
          <span class="block text-center" v-if="loadingEmail">
            <ion-spinner name="crescent" color="light" class="absolute -ml-3 -mt-3"></ion-spinner>
          </span>
          <span class="block capitalize text-white text-lg font-semibold" v-else>Send Email Code</span>
        </button>
        <div class="text-center text-base square">
          <button fill="clear" @click="sendCode(urls.apiForgotPassword, 'sms')">
            <span class="block text-center" v-if="loadingSMS">
              <ion-spinner name="crescent" color="light" class="absolute -ml-3 -mt-3"></ion-spinner>
            </span>
            <span class="ion-text-primary font-bold underline" v-else>Send SMS Code</span>
          </button>
        </div>
      </div>
      <ion-modal class="modal-center" ref="modal-forgot-password" backdrop-dismiss="false" :is-open="showModal">
        <ion-card class="bg-white m-0 p-0 rounded-xl">
          <ion-card-content class="px-4 pt-3 pb-2">
            <ion-grid>
              <ion-row>
                <ion-col class="py-0 text-center">
                  <ion-text class="ion-text-purple block font-bold text-sm">
                    We've sent a reset code to <span class="block my-3 ion-text-green">{{ confirmedValue }}</span>
                  </ion-text>
                </ion-col>
              </ion-row>
              <ion-row>
                <ion-col>
                  <button expand="block" color="secondary" class="button-grey button-shadow mt-1 mb-3"
                    @click="dismissModal()">
                    <span class="block capitalize text-brand text-[14px] font-semibold height-[60px]">Close</span>
                  </button>
                </ion-col>
              </ion-row>
            </ion-grid>
          </ion-card-content>
        </ion-card>
      </ion-modal>
    </ion-content>
  </ion-page>
</template>

<script setup>
import { ref } from 'vue';
import { useField } from 'vee-validate';
import StartHeader from '@/components/start/StartHeader.vue';
import { useBrowser } from '@/composables/browser';
import { modalController } from '@ionic/vue';
import axios from 'axios';
import router from '@/router';
import { useCustomerStore } from '@/stores';
const customerStore = useCustomerStore();
const { setEmailForgotPassword, setEmailForgotPasswordCompleted, setTokenForgotPassword } = customerStore;
const { urls } = useBrowser();
const loadingSMS = ref(false);
const loadingEmail = ref(false);
const validated = ref(false);
const validateError = ref('');
const confirmedValue = ref('');
const showModal = ref(false);
const { value: emailForgotPassword, errorMessage: emailError } = useField('email', (value) => {
  if (!value) {
    validated.value = false;
    return 'This field is required';
  }
  const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (!regex.test(String(value).toLowerCase())) {
    validated.value = false;
    return 'Please enter a valid email address';
  } else {
    validated.value = true;
    return true;
  }
});
const resetMessage = () => {
  validateError.value = '';
}
const resetLoading = (type, val) => {
  if (type == 'sms') loadingSMS.value == val;
  if (type == 'email') loadingEmail.value = val;
}
const sendCode = (url, type) => {
  resetLoading(type, true);
  axios.post(url, { email: emailForgotPassword.value, type })
    .then(res => {
      if (res.status == 200 && res.data.value) {
        setEmailForgotPassword(emailForgotPassword);
        setEmailForgotPasswordCompleted(true);
        setTokenForgotPassword(res.data.token);
        confirmedValue.value = res.data.value;
        setTimeout(() => {
          showModal.value = true;
          resetLoading(type, false);
          validated.value = true;
        }, 1000);
      } else {
        resetLoading(type, false);
        validateError.value = 'Something is wrong. Please try again later.'
      }
    }).catch(err => {
      resetLoading(type, false);
      validated.value = false;
      validateError.value = err.response.data.message;
    });
}

const dismissModal = async () => {
  await modalController.dismiss();
  await router.push('reset-password');
};
</script>

<style scoped>
.otp-wrapper>div {
  justify-content: space-between !important;
}

.otp-input {
  visibility: hidden !important;
  padding-left: 15px;
}
</style>