<template>
  <ion-text class="text-md font-bold ion-text-brand">Warning</ion-text>
  <ion-text class="text-sm mb-1 block ion-text-purple my-6">Sorry, we are unable to close your account as you have, or
    have in the past, had an application or loan product with us. We are required to keep the details of any applications
    or loan contracts on file for a period of 7 years.</ion-text>
  <ion-text class="text-sm mb-1 block ion-text-purple my-6">Should you have any questions or would like us to remove your
    access to your client portal, please contact our customer support team and we will do our best to assist you. Thank
    you.</ion-text>
  <button expand="block" fill="outline" class="button-outline button-icon button-icon-email-solid mt-6 text-center">
    <a :href="'mailto:' + config.customer_service_email">
      <span class="block capitalize text-purple text-[14px] font-semibold height-[60px]">
        Email Customer Service
        <small class="block lowercase text-[10px] font-normal">{{ config.customer_service_email }}</small>
      </span>
    </a>
  </button>
</template>
<script setup>
import { storeToRefs } from 'pinia';
import { useConfigStore } from '@/stores';
const configStore = useConfigStore();
const { config } = storeToRefs(configStore);
</script>