<template>
  <div>
    <div class="text-center">
      <ion-icon src="/assets/credit-score/register-fail.svg" class="w-[47px] h-[54px] -mt-6"></ion-icon>
      <h2 class="text-[18px] font-bold pb-6 text-center leading-7 mt-6">
        Sorry, there was an issue<br />finding your credit file.
      </h2>
      <ion-text class="text-xs ion-text-grey text-center block mx-auto mb-6" v-if="creditScoreErrMsg">
        {{ creditScoreErrMsg }}
      </ion-text>
      <ion-spinner name="dots" v-if="tryAgain"></ion-spinner>
    </div>
    <!-- Try Again hidden for now -->
    <button class="button-white px-6 flex justify-center items-center mb-3 hidden"
      @click="nextStep('/credit-score-connect')" v-if="!tryAgain">
      <span class="block text-xs ion-text-grey">Try Again</span>
    </button>
    <button class="button-white px-6 flex justify-center items-center" @click="nextStep('tabs/home')" v-if="!tryAgain">
      <span class="block text-xs ion-text-grey">Maybe Later</span>
    </button>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import router from '@/router';
import { useCustomerStore } from '@/stores';
import { storeToRefs } from 'pinia';
const customerStore = useCustomerStore();
const { creditScoreErrMsg } = storeToRefs(customerStore);
const tryAgain = ref(false);
const nextStep = (url) => {
  tryAgain.value = true;
  router.push(url);
}
</script>