<template>
  <div class="px-2">
    <button class="mb-6 px-2 button-purple">
      <div class="flex items-center justify-between text-left text-xs pl-4 text-white">
        <div class="leading-4">
          I have read & accept the
          <span class="underline" @click="openBrowser(urls.apiCreditScoreConsents)">
            access seeker acknowledgement and consent.
          </span>
        </div>
        <img :src="imgCheck" class="float-right" @click="updateAccepted" />
      </div>
    </button>
    <button class="button-purple button-register px-6" :disabled="!accepted" @click="nextStep">
      <span class="block text-center" v-if="loading">
        <ion-spinner name="crescent" color="light" class="absolute -ml-3 -mt-3"></ion-spinner>
      </span>
      <span class="block text-lg font-semibold text-white" v-else>
        Track my credit score <img :src="imgNext" class="float-right ml-2 mt-2" />
      </span>
    </button>
    <ion-modal class="modal-center" ref="modal-pay-fail" backdrop-dismiss="false" :is-open="errors">
      <ion-card class="bg-white m-0 p-0 rounded-xl fadein-500">
        <ion-card-content class="px-4 pt-3 pb-2">
          <ion-grid>
            <ion-row>
              <ion-col class="py-0 text-center">
                <ion-text class="ion-text-red block font-bold text-sm flex items-center justify-center">
                  <ion-icon src="/assets/general/notice-danger.svg" class="w-[18px] h-[18px] mr-3"></ion-icon>
                  <span>Error</span>
                </ion-text>
              </ion-col>
              <ion-col class="py-0 text-right" size="1"></ion-col>
            </ion-row>
            <ion-row>
              <ion-col class="text-xs ion-text-brand my-3 text-center w-full">
                {{ errorMsg }}
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col>
                <button expand="block" color="secondary" class="button-grey button-shadow mt-1 mb-3"
                  @click="closeModal">
                  <span class="block capitalize text-brand text-[14px] font-semibold height-[60px]">Close</span>
                </button>
              </ion-col>
            </ion-row>
          </ion-grid>
        </ion-card-content>
      </ion-card>
    </ion-modal>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue';
import router from '@/router';
import axios from 'axios';
import { useBrowser } from '@/composables/browser';
import { useCustomerStore } from '@/stores';
import { storeToRefs } from 'pinia';
import { modalController } from '@ionic/vue';
const customerStore = useCustomerStore();
const { deviceID, environment, authToken } = storeToRefs(customerStore);
const { setCreditScoreConsent } = customerStore;
const { openBrowser, urls } = useBrowser();
const loading = ref(false);
const accepted = ref(false);
const errors = ref(false);
const errorMsg = ref('Something is wrong. Please try again later.');
const imgCheck = computed(() => !accepted.value ? '/assets/register/register-check.svg' : '/assets/register/register-check-active.svg');
const imgNext = computed(() => accepted.value ? '/assets/general/arrow-white.svg' : '/assets/general/arrow-3.svg');
const payload = computed(() => ({
  device_id: deviceID.value,
  environment: environment.value,
  credit_check_consent: accepted.value,
}));
const headers = {
  headers: {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + authToken.value,
  }
};
const updateAccepted = () => {
  accepted.value = !accepted.value;
  setCreditScoreConsent(accepted.value);
};
const nextStep = () => {
  loading.value = true;
  axios.post(urls.apiCreditScoreConsents, payload.value, headers)
    .then(res => {
      if (res.status == 200) {
        loading.value = false;
        router.push({ name: 'CreditScoreConnect' });
      } else {
        loading.value = false;
        errors.value = true;
      }
    }).catch(err => {
      loading.value = false;
      errors.value = true;
      errorMsg.value = err.response.data.error ? err.response.data.error : errorMsg.value;
    });
};
const closeModal = () => {
  errors.value = false;
  modalController.dismiss();
};
</script>