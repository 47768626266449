import { defineStore } from "pinia";
import { useLoansStore } from "./loans";
import { useMixed } from '@/composables/mixed';

export const useApplicationsStore = defineStore('applications', {
	state: () => ({
		pendingApplication: {}
	}),

	getters: {
		getPendingApplication(state) {
			return state.pendingApplication;
		},

		pendingApplicationAvailable(state) {
			return state.pendingApplication && state.pendingApplication.status !== 'none' ? true : false;
		},

		pendingApplicationVisible(state) {
			const { calculateDateDistance } = useMixed();
			const loans = useLoansStore();
			let checkFailedLoans = false;
			let checkLatestLoan = false;
			if (state.pendingApplication.progress) {
				if (loans.getFailedLoans.length >= 1) {
					checkFailedLoans = true;
				}
				if (loans.getLatestLoans.length >= 1) {
					checkLatestLoan = calculateDateDistance(loans.getLatestLoans[0].lending_date);
				}
			}
			return this.pendingApplicationAvailable && !checkFailedLoans && !checkLatestLoan;
		},

		pendingApplicationContent(state) {
			let content = [
				{
					"status": "incomplete_application",
					"label": "Application Incomplete",
					"header": "Continue Application",
					"subheader": "You're nearly submitted!",
					"content": "Your application is <strong class='ion-text-primary'>XX%</strong> complete",
					"button_label": "Complete Application",
				},
				{
					"status": "under_assessment",
					"label": "Being Assessed",
					"header": "In Assessment",
					"subheader": "Nothing required for now!",
					"content": "We will contact you once your assessment is complete or if we need any further information.",
					"button_label": "",
				},
				{
					"status": "information_requested",
					"label": "Information Requested",
					"header": "Provide Information",
					"subheader": "New information request!",
					"content": "We require some additional supporting information.",
					"button_label": "Provide Information",
				},
				{
					"status": "conditional_approval",
					"label": "Information Requested",
					"header": "Provide Final Information",
					"subheader": "Conditionally approved!",
					"content": "Before we can offer unconditional approval, we require some final information.",
					"button_label": "Provide Information",
				},
				{
					"status": "contract_signing",
					"label": "Contract Signing",
					"header": "Sign Contract",
					"subheader": "You're approved!",
					"content": "Review and sign your contract and the funds will be on their way!",
					"button_label": "Sign Contract",
				},
			];
			return this.pendingApplicationAvailable ? content.filter(item => item.status == state.pendingApplication.status)[0] : null;
		},
		
		pendingApplicationPercentage(state) {
			let completed = state.pendingApplication.progress ? state.pendingApplication.progress / 100 : null;
			switch (true) {
				case (completed <= 0.2):
					return 'w-1/5';
				case (completed > 0.2 && completed <= 0.4):
					return 'w-2/5';
				case (completed > 0.4 && completed <= 0.6):
					return 'w-3/5';
				case (completed > 0.6 && completed <= 0.8):
					return 'w-4/5';
				case (completed > 0.8 && completed < 1):
					return 'w-11/12';
				case (completed == 1):
					return 'w-full';
				default:
					return null;
			}
		}
	},

	actions: {
		setPendingApplication(val) {
			this.pendingApplication = val;
		},

	},
})