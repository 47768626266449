<template>
  <div class="credit-score-meter-wrapper ion-text-grey">
    <div class="credit-score-meter-circle-out mx-auto"
      :class="props.size == 'full' && 'credit-score-meter-circle-lg-out'">
      <div v-if="creditScoreActive">
        <div class="credit-score-meter-circle credit-score-meter-circle-change" :class="creditScoreMeterChangeClass"
          :style="creditScoreMeterChangeStyle">
          <span class="fadein-900" :class="creditScoreMeterPointer" v-if="creditScoreMeterPointerShow"></span>
        </div>
        <div class="credit-score-meter-circle credit-score-meter-circle-score" :class="creditScoreMeterScoreClass"
          :style="creditScoreMeterScoreStyle">
        </div>
      </div>
    </div>
  </div>
  <div class="credit-score-meter-footer flex justify-between" :class="props.size == 'full' && 'credit-score-meter-footer-lg'">
    <div class="credit-score-meter-footer-left" :class="creditScoreActive && creditScoreMeterFooterLeft"></div>
    <div class="credit-score-meter-footer-right" :class="creditScoreActive && creditScoreMeterFooterRight"></div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue';
import { useMixed } from '@/composables/mixed';
const props = defineProps(['creditScore', 'size']);
const { loadProgress, calculateProgress } = useMixed();
const creditScoreMeterScore = ref(180);
const creditScoreMeterChange = ref(180);
const creditScoreMeterFooterLeft = ref('');
const creditScoreMeterFooterRight = ref('');
const creditScoreMeterPointer = ref('');
const creditScoreMeterPointerShow = computed(() => props.creditScore.score_change !== 0 && props.creditScore.current_score < 1200);
const creditScoreMeterPointerStart = computed(() => props.creditScore.score_change < 0 ? 'credit-score-meter-circle-change-pointer credit-score-meter-circle-change-pointer-negative' : 'credit-score-meter-circle-change-pointer');
const creditScoreMeterPointerCompleted = computed(() => props.creditScore.score_change < 0 ? 'credit-score-meter-circle-change-pointer-completed credit-score-meter-circle-change-pointer-completed-negative' : 'credit-score-meter-circle-change-pointer-completed')
const creditScoreMeterPointerTimer = (score) => 1500 + Math.floor(score / 300) * 250;
const creditScoreActive = computed(() => props.creditScore.status && props.creditScore.current_score > 0);
const creditScoreMeterScoreStyle = computed(() => ({ transform: 'rotate(' + Math.min(creditScoreMeterScore.value, 360) + 'deg)' }));
const creditScoreMeterChangeStyle = computed(() => ({ transform: 'rotate(' + Math.min(creditScoreMeterChange.value, 360) + 'deg)' }));
const creditScoreMeterScoreClass = computed(() => props.size == 'full' ? 'credit-score-meter-circle-lg credit-score-meter-circle-lg-score' : '');
const creditScoreMeterChangeClass = computed(() => props.size == 'full' ? `credit-score-meter-circle-lg credit-score-meter-circle-lg-change${props.creditScore.score_change < 0 ? '-negative' : ''}` : `credit-score-meter-circle-change${props.creditScore.score_change < 0 ? '-negative' : ''}`);
const barScore = calculateProgress((props.creditScore.current_score - Math.abs(props.creditScore.score_change)) / 1200) + 180;
const barChange = props.creditScore.score_change !== 0 ? calculateProgress(Math.abs(props.creditScore.score_change) / 1200) + barScore : 0;
const initProgress = (score, change) => {
  loadProgress(creditScoreMeterScore, score, 1, 10);
  if (change !== 0) {
    setTimeout(() => {
      loadProgress(creditScoreMeterChange, change, 1, 10);
      creditScoreMeterFooterLeft.value = 'credit-score-meter-footer-left-score';
    }, 100);
    setTimeout(() => {
      creditScoreMeterPointer.value = creditScoreMeterPointerStart.value;
    }, creditScoreMeterPointerTimer(props.creditScore.current_score));
    setTimeout(() => {
      creditScoreMeterPointer.value = creditScoreMeterPointerCompleted.value;
    }, creditScoreMeterPointerTimer(props.creditScore.current_score) + 500);
  }
  setTimeout(() => {
    creditScoreMeterFooterLeft.value = 'credit-score-meter-footer-left-score';
  }, 100);
  if (props.creditScore.current_score == 1200) {
    setTimeout(() => {
      switch (true) {
        case props.creditScore.score_change > 0:
          creditScoreMeterFooterRight.value = 'credit-score-meter-footer-right-score-positive';
          break;
        case props.creditScore.score_change < 0:
          creditScoreMeterFooterRight.value = 'credit-score-meter-footer-right-score-negative';
          break;
        default:
          creditScoreMeterFooterRight.value = 'credit-score-meter-footer-right-score';
      }
    }, 3400);
  }
}
onMounted(() => {
  initProgress(barScore, barChange);
});
</script>