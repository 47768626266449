<template>
  <div class="px-7 py-6 max-w-xl mx-auto">
    <ion-icon src="/assets/credit-score/about.svg" class="w-[136px] h-[121px] mb-6 mx-auto block"></ion-icon>
    <div>
      <ion-text class="text-md font-bold ion-text-brand">Equifax</ion-text>
      <ion-text class="text-xs ion-text-purple my-2 block">Equifax Pty Ltd (Equifax)</ion-text>
      <ion-text class="text-xs ion-text-purple my-2 block">Website:
        <button class="underline"
          @click="openBrowser(urls.equifax + 'personal/')"><strong>www.equifax.com.au</strong></button>
      </ion-text>
      <ion-text class="text-xs ion-text-purple my-2 block">Privacy Policy:
        <button class="underline"
          @click="openBrowser(urls.equifax + 'privacy/')"><strong>www.equifax.com.au/privacy</strong></button>
      </ion-text>
      <ion-text class="text-xs ion-text-purple my-2 block">Telephone:
        <button class="underline"><a href="tel:138332"><strong>13 8332</strong></a></button>
      </ion-text>
      <hr class="my-5 hr-grey" />
    </div>

    <div>
      <ion-text class="text-md font-bold ion-text-brand">Your credit score</ion-text>
      <ul class="text-xs ion-text-purple list-disc pl-6">
        <li class="my-2">Your Equifax credit score is out of 1,200.</li>
        <li class="my-2">It is calculated based on your credit report's numerous factors and insights.</li>
        <li class="my-2">The higher the score, the better you look to banks and other lenders</li>
      </ul>
      <button @click="openBrowser(urls.creditScoreExplained)">
        <small class="text-[11px] ion-text-primary underline">Credit Scores Explained</small>
      </button>
      <hr class="my-5 hr-grey" />
    </div>

    <div>
      <ion-text class="text-md font-bold ion-text-brand">How can I increase my credit score?</ion-text>
      <ul class="text-xs ion-text-purple list-disc pl-6">
        <li class="my-2 mb-4">What lenders look at when deciding whether to lend to you (with other checks)</li>
      </ul>
      <div class="flex mb-3">
        <div class="min-w-[60px]">
          <ion-icon src="/assets/credit-score/icon-meter.svg" class="w-[45px] h-[45px] block mr-2"></ion-icon>
        </div>
        <div>
          <ion-text class="text-[12px] font-bold ion-text-primary">Comprehensive Credit Reporting</ion-text>
          <ion-text class="text-xs ion-text-purple my-1 block">Thanks to
            <button @click="openBrowser(urls.ccr)">
              <span class="ion-text-primary underline">Comprehensive Credit Reporting (CCR),</span>
            </button>
            we report positive credit behaviour to Equifax to help improve your score faster.</ion-text>
        </div>
      </div>
      <div class="flex mb-3">
        <div class="min-w-[60px]">
          <ion-icon src="/assets/credit-score/icon-improve.svg" class="w-[40px] h-[30px] block mr-2"></ion-icon>
        </div>
        <div>
          <ion-text class="text-[12px] font-bold ion-text-primary">Read More</ion-text>
          <ion-text class="text-xs ion-text-purple my-1 block">
            <button @click="openBrowser(urls.creditScoreImprove)">
              <span class="ion-text-primary underline">9 Ways to Improve Your Credit Score.</span>
            </button>
          </ion-text>
        </div>
      </div>
      <div class="flex mb-3 hidden">
        <div class="min-w-[60px]">
          <ion-icon src="/assets/credit-score/icon-lightbulb.svg" class="w-[45px] h-[45px] block mr-2"></ion-icon>
        </div>
        <div>
          <ion-text class="text-[12px] font-bold ion-text-primary">Finance Fundamentals Course</ion-text>
          <ion-text class="text-xs ion-text-purple my-1 block">Complete our Finance Fundamentals course to test your
            knowledge of all credit scores and to receive more insights on improving it.</ion-text>
        </div>
      </div>
      <button expand="block" color="secondary" class="button-purple button-purple-lg button-shadow my-2 hidden">
        <span class="block text-white text-[14px] font-semibold">Finance Fundamentals Online Course</span>
      </button>
      <hr class="my-5 hr-grey" />
    </div>

    <div>
      <ion-text class="text-md font-bold ion-text-brand">Your credit report</ion-text>
      <ul class="text-xs ion-text-purple list-disc pl-6">
        <li class="my-2">What lenders look at when deciding whether to lend to you (with other checks)</li>
        <li class="my-2">Updated monthly</li>
        <li class="my-2">Information comes from our partner credit agency:
          <button @click="openBrowser(urls.equifax + 'personal/')">
            <small class="text-[11px] ion-text-primary underline">Equifax</small>
          </button>
        </li>
      </ul>
      <hr class="my-5 hr-grey" />
    </div>

    <div>
      <ion-text class="text-md font-bold ion-text-brand">Information on your report</ion-text>
      <ul class="text-xs ion-text-purple list-disc pl-6">
        <li class="my-2">What lenders look at when deciding whether to lend to you (with other checks)</li>
      </ul>
      <ion-text class="text-[12px] font-bold ion-text-purple">Your Credit Score</ion-text>
      <ul class="text-xs ion-text-purple list-disc pl-6">
        <li class="my-2">Will range from 0 - 1,200 with Equifax.</li>
      </ul>
      <ion-text class="text-[12px] font-bold ion-text-purple">Personal Information</ion-text>
      <ul class="text-xs ion-text-purple list-disc pl-6">
        <li class="my-2">Personal info like your name, DOB, current and previous addresses, as well as your employment
          history and current employment status, will be shown.</li>
      </ul>
      <ion-text class="text-[12px] font-bold ion-text-purple">Repayment history</ion-text>
      <ul class="text-xs ion-text-purple list-disc pl-6">
        <li class="my-2">A significant influence on your credit score is your repayment history. Your report shows
          your recent on-time and missed repayments from your credit accounts.</li>
      </ul>
      <ion-text class="text-[12px] font-bold ion-text-purple"> Credit enquiries (applications)</ion-text>
      <ul class="text-xs ion-text-purple list-disc pl-6">
        <li class="my-2">Every time you apply for credit or a loan, the lender records this application (known as a
          'credit enquiry') and appears on your credit report.</li>
      </ul>
      <ion-text class="text-[12px] font-bold ion-text-purple">Defaults & infringements</ion-text>
      <ul class="text-xs ion-text-purple list-disc pl-6">
        <li class="my-2">Defaults appear on your credit report when a payment of $150 or more is overdue by 60 days or
          more. Other serious infringements can show up, too.</li>
      </ul>
      <ion-text class="text-[12px] font-bold ion-text-purple">Bankruptcies & financial hardships</ion-text>
      <ul class="text-xs ion-text-purple list-disc pl-6">
        <li class="my-2">You can view bankruptcies, court readings, and personal insolvencies you may have filed for
          on your report. Financial hardship applications will also appear.</li>
      </ul>
      <hr class="mt-5 hr-grey" />
    </div>
  </div>
</template>

<script setup>
import { useBrowser } from '@/composables/browser';
const { openBrowser, urls } = useBrowser();
</script>