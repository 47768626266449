<template>
  <div class="mt-6">
    <button expand="block" fill="outline" class="button-outline mb-3" id="open-modal">
      <span class="block font-semibold">Yes, Close my Account</span>
    </button>
    <button expand="block" color="secondary" class="button-purple mt-4 mb-6" @click="$router.push('profile')">
      <span class="block capitalize text-white font-semibold height-[60px]">Back</span>
    </button>

    <ion-modal class="modal-center" ref="open-modal" trigger="open-modal" cssClass="modal-close-account">
      <div class="bg-white p-7">
        <ion-text class="text-md font-bold ion-text-brand">Are you sure?</ion-text>
        <ion-text class="text-sm mb-1 block ion-text-purple mt-3">By permanently closing your account, you can no longer easily apply for personal finance through us.</ion-text>
        <button expand="block" color="secondary" class="button-purple mt-4 mb-6" @click="closeLater">
          <span class="block capitalize text-white font-semibold height-[60px]">Close Later</span>
        </button>
        <button expand="block" fill="outline" class="button-outline mb-6" @click="closeNow">
          <span class="block font-semibold">Close It Now</span>
        </button>
      </div>
    </ion-modal>

  </div>
</template>

<script setup>
import { modalController } from '@ionic/vue';
import router from '@/router';
const closeLater = () => {
  modalController.dismiss();
  router.push('profile')
};
const closeNow = () => {
  modalController.dismiss();
  router.push('close-account-warning')
};
</script>

<style scoped>
.modal-close-account {
  align-items: end;
  padding: 0;
  margin-bottom: -10px;
}
</style>